import React, {useState} from 'react';
import MediaPlayer from '../media/MediaPlayer';
import {Flex, Absolute} from '../../../layout';
import {IconFullscreen, IconUnfullscreen} from '../../../icon';
import useFullscreen from '../../util/useFullscreen';
import Image from '../Image';
import Creative from '../../media/Creative';

type Props = {
    autoPlay?: boolean;
    minHeight?: string;
    trackPlaypause?: Function;
    trackMute?: Function;
    trackFullscreen?: Function;
    creativeItem: Creative;
    style?: Record<string, any>;
    alt?: string;
    poster?: 'large' | 'small' | 'none'; // Use none as an explicit option as large is a common default
};

export default function CreativePreview(props: Props) {
    const {creativeItem} = props;
    const {creativeMedium} = creativeItem;
    const [fullSize, setFullSize] = useState(false);
    const {fullscreen, node, toggleFullscreen} = useFullscreen();
    const childProps = {
        autoPlay: props.autoPlay,
        minHeight: props.minHeight,
        poster:
            props.poster === 'none'
                ? undefined
                : props.poster === 'large'
                ? creativeItem.thumbnailLarge
                : creativeItem.thumbnail,
        src: creativeItem.filepath,
        style: props.style,
        trackFullscreen: () => props.trackFullscreen?.(creativeItem),
        trackMute: () => props.trackMute?.(creativeItem),
        trackPlaypause: () => props.trackPlaypause?.(creativeItem)
    };

    if (creativeMedium === 'video' || creativeMedium === 'audio') {
        return (
            <MediaPlayer
                key={childProps.src}
                type={creativeMedium}
                modifier={creativeMedium}
                {...childProps}
            />
        );
    }

    return (
        <Flex
            alignItems={fullSize ? 'flex-start' : 'center'}
            justifyContent="center"
            overflow="auto"
            flexGrow={1}
            p={3}
            width="100%"
            height="100%"
            ref={node}
            onClick={() => setFullSize(!fullSize)}
        >
            <Image
                src={creativeItem.filepath}
                alt={props.alt || ''}
                title={props.alt}
                style={{
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '100%',
                    maxHeight: fullSize ? undefined : '100%'
                }}
            />
            <Absolute
                bottom="1rem"
                right="1rem"
                style={{cursor: 'pointer'}}
                onClick={() => toggleFullscreen()}
                children={
                    fullscreen ? (
                        <IconUnfullscreen size="1.5rem" />
                    ) : (
                        <IconFullscreen size="1.5rem" />
                    )
                }
            />
        </Flex>
    );
}
