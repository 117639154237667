
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import MdxLayout from '../affordance/MdxLayout';
import SeoTemplate from '../affordance/SeoTemplate';
import {Box} from 'bigdatr-style/layout';
export const frontmatter = {
  "title": "About Us",
  "description": "Australian owned and based in Melbourne, BigDatr is a performance-focused enterprise powered by specialist researchers and professional problem solvers.",
  "slug": "about-us",
  "date": "2020-12-08"
};

const layoutProps = {
  frontmatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <MdxLayout mdxType="MdxLayout">
      <SeoTemplate title={frontmatter.title} description={frontmatter.description} slug={frontmatter.slug} mdxType="SeoTemplate" />
      <h1>{`Who We Are`}</h1>
      <h3>{`Australian owned and based in Melbourne, BigDatr is a performance-focused enterprise powered by specialist developers, researchers, data scientists, designers and professional problem solvers.`}</h3>
      <p>{`Using our extensive sources of live industry data, we transform information into insight to help you more effectively drive marketing, advertising, media buying and strategic planning.`}</p>
      <h4>{`Tap into BigDatr and your marketing capability just got a whole lot more powerful. Our team are original thinkers who put their rational creativity and lateral thinking into every bit of data, every product feature to help increase your sales.`}</h4>
      <p>{`BigDatr lives and breathes the advertising industry – we are a fresh-perspective company who love what we do and are constantly striving to do more.`}</p>
      <h2>{`Our Values`}</h2>
      <p>{`They guide what we do, why we create, and who we hire.`}</p>
      <Box display={{
        md: 'flex'
      }} textAlign="center" mdxType="Box">
        <Box flexBasis="33.333%" mdxType="Box">
          <p><img parentName="p" {...{
              "src": "https://media.bigdatr.com/static/app/about-us/conversation.png",
              "alt": null
            }}></img></p>
          <h3>{`Open Communication`}</h3>
        </Box>
        <Box flexBasis="33.333%" mdxType="Box">
          <p><img parentName="p" {...{
              "src": "https://media.bigdatr.com/static/app/about-us/group.png",
              "alt": null
            }}></img></p>
          <h3>{`Great teams, not individuals`}</h3>
        </Box>
        <Box flexBasis="33.333%" mdxType="Box">
          <p><img parentName="p" {...{
              "src": "https://media.bigdatr.com/static/app/about-us/repair-tools.png",
              "alt": null
            }}></img></p>
          <h3>{`Create with purpose and intent`}</h3>
        </Box>
      </Box>
      <Box display={{
        md: 'flex'
      }} textAlign="center" mdxType="Box">
        <Box flexBasis="33.333%" mdxType="Box">
          <p><img parentName="p" {...{
              "src": "https://media.bigdatr.com/static/app/about-us/love.png",
              "alt": null
            }}></img></p>
          <h3>{`Love what you do and have fun!`}</h3>
        </Box>
        <Box flexBasis="33.333%" mdxType="Box">
          <p><img parentName="p" {...{
              "src": "https://media.bigdatr.com/static/app/about-us/handshake.png",
              "alt": null
            }}></img></p>
          <h3>{`Be candid. Be honest. Be constructive.`}</h3>
        </Box>
        <Box flexBasis="33.333%" mdxType="Box">
          <p><img parentName="p" {...{
              "src": "https://media.bigdatr.com/static/app/about-us/question.png",
              "alt": null
            }}></img></p>
          <h3>{`Like what you see? Join Us!`}</h3>
        </Box>
      </Box>
      <p><strong parentName="p">{`"We recognise it's the people behind the company, their passion and what they do with it that makes the difference - we love what we do."`}</strong></p>
      <p>{`We're a team that brings together smart, talented people from a diverse range of backgrounds to accomplish one goal.`}</p>
    </MdxLayout>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;