import {Ref} from 'react';
import {useRef, useState, useCallback, useEffect} from 'react';
import Fullscreen from '../affordance/media/Fullscreen';

type Returns = {
    fullscreen: boolean;
    toggleFullscreen: () => void;
    node: Ref<any>;
};

export default function useFullscreen(): Returns {
    const node = useRef<Element | null>(null);
    const [fullscreen, setFullscreen] = useState(!!Fullscreen.active());

    useEffect(() => {
        const updateFullscreenState = () => setFullscreen(!!Fullscreen.active());
        document.addEventListener('fullscreenchange', updateFullscreenState);
        return () => {
            document.removeEventListener('fullscreenchange', updateFullscreenState);
        };
    }, []);

    const toggleFullscreen = useCallback(() => {
        if (fullscreen) {
            Fullscreen.exit();
        } else {
            Fullscreen.request(node.current ?? {});
        }

        setFullscreen(!fullscreen);
    }, [fullscreen, node]);

    return {
        fullscreen,
        toggleFullscreen,
        node
    };
}
