import React from 'react';
import {Svg} from 'bigdatr-style/layout';

export default function Brands() {
    return (
        <Svg viewBox="0 0 826 690">
            <path
                d="M513.34 347.532c32.811-32.811 32.811-86.009 0-118.821-32.812-32.811-86.01-32.811-118.822 0-32.811 32.812-32.811 86.01 0 118.821 32.812 32.812 86.01 32.812 118.822 0Z"
                fill="#fff"
            />
            <path
                d="M525.55 257.48c12.36 30.19 5.44 65.12-18.5 87.59-23.84 22.37-60.5 27.24-89.36 11.95-28.71-15.21-45.77-47.85-40.82-80.17 4.99-32.58 30.06-58.75 62.38-65.2 31.36-6.25 64.49 8.56 81.08 35.79 2.25 3.69 4.18 7.54 5.86 11.52 1.3 3.07 5.84 3.75 8.45 2.22 3.18-1.86 3.52-5.37 2.22-8.45-14.42-34.1-49.8-56.45-86.82-54.73-37.82 1.76-71.13 27.53-82.32 63.68-11.29 36.47 2.52 76.68 33.04 99.27 29.98 22.18 72.37 23 103.29 2.2 30.95-20.81 46.23-59.41 37.71-95.76-1.06-4.51-2.51-8.9-4.26-13.19-1.26-3.09-4.11-5.28-7.6-4.32-2.95.8-5.62 4.49-4.35 7.6Z"
                fill="#170B24"
            />
            <path
                d="M217.78 674.19c-21.56 8.97-46.31-1.23-55.28-22.79l-56.46-135.66c-8.97-21.56 1.23-46.31 22.79-55.28 21.56-8.97 46.31 1.23 55.28 22.79l56.46 135.66c8.98 21.56-1.23 46.31-22.79 55.28Z"
                fill="#fff"
            />
            <path
                d="M216.14 668.23c-12.01 5-25.74 3.67-36.26-4.14-5.2-3.86-8.99-9.06-11.66-14.91.64 1.41-.37-.89-.51-1.23-4.92-11.81-9.83-23.62-14.75-35.44-13.1-31.48-26.2-62.95-39.3-94.43-1.89-4.55-3.82-9.07-4.46-14.02-1.59-12.48 3.74-25.41 13.69-33.12 2.29-1.78 6.21-3.92 7.87-4.65 1.95-.84 6.59-2.28 9.75-2.68 6.12-.78 12.49.1 18.2 2.41 5.74 2.31 10.9 6.22 14.74 11.07 2.02 2.55 3.6 5.43 4.94 8.38-.54-1.19-.13-.31-.01-.03 12 28.16 23.53 56.53 35.29 84.8 6.02 14.47 12.04 28.94 18.07 43.41 1.08 2.6 2.22 5.18 3.24 7.81 4.68 12.06 3.04 25.83-5.05 36.09-4.02 5.1-9.34 8.77-15.27 11.31-3.07 1.32-3.75 5.83-2.22 8.45 1.85 3.17 5.38 3.54 8.45 2.22 24.32-10.45 35.18-39.19 25.17-63.36-4.92-11.87-9.87-23.72-14.81-35.58l-40.23-96.66c-3.17-7.61-7.06-14.62-13.28-20.22-11.3-10.17-26.51-14.43-41.45-11.86-29.66 5.1-47.41 37.94-36.18 65.63 11.73 28.93 23.99 57.65 35.99 86.47 6.12 14.71 12.24 29.42 18.37 44.13 1.87 4.49 3.59 9.1 6.24 13.19 8.39 12.98 22.47 21.15 37.86 22.25 7.01.5 14.36-.67 20.85-3.37 3.08-1.28 5.28-4.09 4.32-7.6-.8-2.91-4.5-5.61-7.6-4.32Z"
                fill="#170B24"
            />
            <path
                d="m14.54 472.86.64 1.42c18.08 40.25 62.95 60.88 105.41 48.85 18.94-5.37 38.64-10.36 59.09-14.86 101.27-22.28 194.94-26.71 275.92-22.61 55.81 2.82 95.04-54.22 72.91-105.53-32.98-76.46-65.96-152.92-98.95-229.38-22.11-51.25-90.05-61.59-126.7-19.49-55.1 63.29-121.96 129.08-202.66 191.67a1422.884 1422.884 0 0 1-55.76 41.11c-34.8 24.37-47.31 70.07-29.9 108.82Z"
                fill="#fff"
            />
            <path
                d="M9.21 475.98c8.83 19.65 23.8 36.12 43.04 46.02 19.73 10.15 42.49 13.17 64.19 8.53 13.6-2.91 26.95-7.29 40.46-10.61 14.73-3.62 29.53-6.91 44.4-9.87 28.51-5.68 57.27-10.14 86.17-13.33 52.58-5.79 105.59-7.52 158.44-5.33 11.27.47 22.18.89 33.24-1.82 9.6-2.36 18.89-6.6 26.93-12.36 16.18-11.6 28.07-28.8 32.66-48.24 2.41-10.2 2.76-20.64 1.25-31-1.77-12.14-7.04-23.04-11.86-34.2-12.23-28.36-24.46-56.71-36.7-85.07-12.23-28.36-24.46-56.71-36.7-85.07-6.16-14.28-12.32-28.57-18.48-42.85-4.12-9.54-9.18-18.28-16.28-25.96-26.08-28.23-70.77-33.98-103.25-13.51-9.17 5.78-16.25 13.34-23.35 21.41-8.34 9.48-16.8 18.84-25.38 28.1a1448.343 1448.343 0 0 1-55.95 57.09c-41.72 40.26-85.88 78.04-132.23 112.87-11.98 9-24.11 17.82-36.37 26.44-9.64 6.78-18.05 14.42-25.02 23.97C5.49 398.91-.89 421.56.83 443.42c.89 11.35 3.77 22.16 8.38 32.56 1.35 3.05 5.8 3.77 8.45 2.22 3.15-1.84 3.57-5.39 2.22-8.45-8.3-18.75-9.08-40.2-2.62-59.61 3.19-9.58 8.25-18.7 14.77-26.42 7.65-9.05 17.14-15.41 26.71-22.25 46.49-33.23 91.01-69.22 133.21-107.74 37.92-34.62 73.96-71.29 107.97-109.76 6.63-7.5 12.91-15.22 21.3-20.84a70 70 0 0 1 23.58-10.17c16.07-3.65 33.5-1.35 48.18 6.5 7.6 4.07 14.29 9.44 19.93 15.97 6.79 7.87 10.78 17.19 14.86 26.64 11.87 27.52 23.74 55.03 35.61 82.55 11.78 27.31 23.56 54.61 35.34 81.92 5.89 13.65 11.78 27.31 17.67 40.96 4.62 10.71 9.84 21.24 11.57 32.88 5.47 36.81-20.92 72.2-57.34 78.37-10.61 1.8-21.63.37-32.31 0-12.09-.42-24.19-.63-36.28-.63-25.61-.01-51.22.91-76.76 2.78-56.52 4.14-112.74 12.86-167.83 26.18-13.38 3.23-26.61 6.96-39.92 10.49-10 2.66-20.36 3.39-30.65 2.31-19.75-2.08-38.44-11.82-51.82-26.41-6.42-7.01-11.28-15.08-15.16-23.72-1.37-3.04-5.79-3.77-8.45-2.22-3.15 1.83-3.61 5.39-2.23 8.45Z"
                fill="#170B24"
            />
            <path
                d="M508.33 184.83c10.2-8.95 28.68-27.73 37.3-56.89 10.56-35.72 5.11-82.93-12.97-88.81-12.04-3.92-31.64 9.87-32.41 23.82-.79 14.29 18.2 28.52 33.22 29.1 24.24.94 50.08-33.17 64.11-86.01"
                fill="#fff"
            />
            <path
                d="M512.7 189.2c20.53-18.08 35.59-42.11 41.25-69.01 3.06-14.56 4.08-29.77 2.51-44.59-1.28-12.1-3.83-25.22-11.64-34.95-17.64-21.98-56.06 4.19-50.04 28.86 3.36 13.76 17.71 24.2 30.83 27.55 10.62 2.71 21.28.15 30.24-5.94 11.6-7.88 20.31-19.55 27.2-31.6 9.24-16.16 15.69-33.91 20.5-51.84 2.06-7.69-9.86-10.97-11.92-3.29-6.57 24.51-16.19 51.1-34.39 69.53-5.92 6-12.21 10.58-20.31 11.86 1.26-.2-1.1.07-1.47.09-.95.04-1.9.03-2.85-.05-2.8-.23-5.64-1.08-8.23-2.15-5.25-2.16-10.27-5.7-13.85-10.12-2.91-3.58-4.92-8.11-3.72-12.51 1.3-4.76 5-8.81 8.91-11.64 3.83-2.77 8.67-5.12 13.36-4.75.77.06 1.36.2 2.43.6-1.2-.44.45.29.86.52.84.45 2.02 1.51 3.33 3.15-.61-.77.2.29.33.49.3.45.6.89.88 1.35.53.87 1.02 1.76 1.48 2.67 1.06 2.11 1.68 3.81 2.43 6.18 1.64 5.19 2.71 10.59 3.28 16 1.33 12.52.88 25.37-1.37 37.75-4.81 26.44-18.71 49.44-38.77 67.11-5.98 5.26 2.79 13.97 8.74 8.73Z"
                fill="#170B24"
            />
            <path
                d="M558.39 236.4c120.7-52.73 150.81-81.1 147.13-97.21-2.51-10.96-22.14-22.71-34.16-17.68-9.52 3.98-14.97 18.75-11.17 29.95 4.45 13.14 20.27 17.34 24.58 18.48 41.26 10.94 91.47-55.24 96.46-61.96"
                fill="#fff"
            />
            <path
                d="M561.51 241.74c37.32-16.32 75.05-33.27 109.5-55.17 9.39-5.97 18.7-12.41 26.83-20.05 5.19-4.88 10.06-10.48 12.68-17.19 3.19-8.2.58-16.21-5.33-22.6-6.54-7.08-16.28-11.88-25.89-12.54-10.11-.69-18.71 5.42-22.96 14.37-4.4 9.26-5 20.61.18 29.7 4.5 7.9 12.54 12.79 20.85 15.86 14.38 5.31 28.95 3.14 42.65-3.11 14.91-6.8 27.98-17.69 39.62-29.07 8.63-8.43 16.65-17.5 24.04-27.03.98-1.26 1.95-2.52 2.9-3.79 1.99-2.68.44-6.9-2.22-8.45-3.15-1.84-6.46-.47-8.45 2.22-.26.34-.51.69-.77 1.03-.44.58-1.51 1.95-.39.51-1.15 1.47-2.31 2.94-3.49 4.39-3.52 4.34-7.17 8.57-10.95 12.68-9.7 10.56-20.23 20.63-32.14 28.67-11.19 7.55-23.92 13.84-37.2 12.69-4.37-.38-10.53-2.4-14.62-4.6-6.45-3.47-11.16-8.81-11.17-16.46-.01-6.39 3.1-15.48 10.09-17.04 6.68-1.49 14.84 2.67 19.7 7.22 2.35 2.2 3.8 4.48 4.66 7.04.5 1.49.05-1.01-.01-.26-.04.4.03.85.05 1.25.01.42-.13.89-.08 1.3-.19-1.54.25-1.2-.07 0-.12.44-.22.87-.36 1.3-.12.38-.79 2.09-.31.92.49-1.19-.21.41-.39.77-.29.57-.6 1.13-.93 1.67-.68 1.14-1.46 2.2-2.23 3.28.99-1.4-.07.08-.61.72-.51.61-1.04 1.2-1.57 1.79a78.069 78.069 0 0 1-3.84 3.88c-1.46 1.38-2.96 2.7-4.49 3.99-.78.65-1.57 1.3-2.36 1.94-.45.36-.89.71-1.34 1.06-.2.16-.92.71.06-.04-.27.21-.54.42-.82.62-4.33 3.25-8.77 6.33-13.32 9.27-29.82 19.32-62.4 34.52-94.73 49.07-5.64 2.54-11.3 5.04-16.97 7.52-3.06 1.34-3.76 5.81-2.22 8.45 1.81 3.16 5.35 3.56 8.42 2.22Z"
                fill="#170B24"
            />
            <path
                d="M588.58 323.1c118.54-32.32 153.59-26.67 160.72-12.91 3.68 7.1 2.16 20.68-5.26 27.97-9.05 8.89-26.69 8.26-36.61.71-16.19-12.32-13.91-44.67-.58-62.51 16.03-21.46 52.44-27.76 87.56-11.17"
                fill="#fff"
            />
            <path
                d="M590.22 329.06c31.85-8.67 65.19-17.12 97.36-21.43 9.64-1.29 19.38-2.22 29.11-2.2 3.54.01 7.09.15 10.62.53.47.05 2.7.36.64.06.72.1 1.43.21 2.14.33 1.4.24 2.8.54 4.18.9 1.04.27 2.05.6 3.06.94.55.18 2.36 1.05.26.06.52.25 1.06.47 1.57.74.75.38 1.44.84 2.16 1.27 1.34.79-.13-.15-.06-.09.33.32.71.6 1.05.92.25.24.48.51.73.75.64.61.5 1.34-.21-.33.21.49 1.09 1.61 1.1 1.63 2.6 5.53.91 13.45-2.34 18.31-7.03 10.52-25.47 8.69-33.13-.05-8.13-9.27-7.23-24.89-3.99-36.11 4.4-15.27 15.33-26.07 30.54-30.63 18.58-5.57 38.96-2.19 56.28 5.87 3.02 1.41 6.66.85 8.45-2.22 1.56-2.67.82-7.04-2.22-8.45-18.59-8.65-39.32-12.68-59.64-8.64-16.51 3.28-31.88 12.74-40.03 27.78-7.58 13.98-10.6 32.16-5.7 47.53 2.41 7.57 6.85 14.28 13.56 18.67 7.03 4.6 16.21 6.39 24.48 5.4 8.99-1.07 17.07-5.15 22.08-12.89 4.71-7.29 6.35-17.21 4.28-25.63-1.43-5.78-5.18-10.38-10.31-13.29-12.92-7.34-30.34-6.2-44.54-5.03-32.97 2.71-65.59 10.55-97.53 18.82-5.75 1.49-11.49 3.02-17.22 4.58-7.68 2.06-4.42 13.99 3.27 11.9Z"
                fill="#170B24"
            />
            <path d="M587.08 166.97c3.85-4.07 7.7-8.13 11.55-12.2Z" fill="#fff" />
            <path
                d="M591.45 171.34c3.85-4.07 7.7-8.13 11.55-12.2 1.11-1.17 1.81-2.74 1.81-4.37 0-1.52-.67-3.33-1.81-4.37-1.18-1.08-2.72-1.88-4.37-1.81-1.61.07-3.23.61-4.37 1.81-3.85 4.07-7.7 8.13-11.55 12.2-1.11 1.17-1.81 2.74-1.81 4.37 0 1.52.67 3.33 1.81 4.37 1.18 1.08 2.72 1.88 4.37 1.81 1.61-.07 3.23-.61 4.37-1.81Z"
                fill="#170B24"
            />
            <path d="M624.3 266.09c5.33-1.73 10.65-3.45 15.98-5.18Z" fill="#fff" />
            <path
                d="M625.94 272.05c5.33-1.73 10.65-3.45 15.98-5.18 1.52-.49 2.87-1.44 3.69-2.84.78-1.32 1.1-3.28.62-4.76-.99-3.07-4.31-5.38-7.6-4.32-5.33 1.73-10.65 3.45-15.98 5.18-1.52.49-2.87 1.44-3.69 2.84-.78 1.32-1.1 3.28-.62 4.76.99 3.07 4.31 5.39 7.6 4.32Z"
                fill="#170B24"
            />
        </Svg>
    );
}
