import React from 'react';

import {Paper, Text} from 'bigdatr-style';
import {Flex} from 'bigdatr-style/layout';

import ResponsiveLayout from '../affordance/ResponsiveLayout';
import CollectionFeaturedBrandListItem from './CollectionFeaturedBrandListItem';
import {ClientWebBrand} from '../ClientWebBrand';

type ListLayoutProps = {children: React.ReactNode; mobile?: boolean};
const ListLayout = ({children, mobile}: ListLayoutProps) => (
    <Paper outlined={!mobile} p={mobile ? 0 : 3}>
        <Flex ml={1} mb={2}>
            <Text textStyle="heading3">Featured brands</Text>
        </Flex>
        <Flex flexWrap="wrap" alignItems="center" justifyContent="space-around">
            {children}
        </Flex>
    </Paper>
);

const ListItemSkeletons = ({limit, mobile}: {limit: number; mobile?: boolean}) => (
    <ListLayout {...{mobile}}>
        {[...new Array(limit)].map((_, key) => (
            <CollectionFeaturedBrandListItem key={key} mobile={mobile} skeleton />
        ))}
    </ListLayout>
);

const ListItems = ({brands, mobile}: {brands: Array<ClientWebBrand>; mobile?: boolean}) => (
    <ListLayout {...{mobile}}>
        {brands.map((brand) => (
            <CollectionFeaturedBrandListItem key={brand.key} {...{brand, mobile}} />
        ))}
    </ListLayout>
);

type Props = {brands: Array<ClientWebBrand>; skeleton?: false} | {skeleton: true};
const CollectionFeaturedBrandList = (props: Props) => {
    if ('skeleton' in props && props.skeleton) {
        return (
            <ResponsiveLayout
                breakpoint="md"
                before={<ListItemSkeletons limit={5} mobile />}
                after={<ListItemSkeletons limit={5} />}
            />
        );
    }

    const {brands} = props;

    return (
        <ResponsiveLayout
            breakpoint="md"
            before={<ListItems {...{brands}} mobile />}
            after={<ListItems {...{brands}} />}
        />
    );
};

export default CollectionFeaturedBrandList;
