import React from 'react';
import {BuildTagsParams} from './buildTagsTypes';

/*
    This file is a trimmed copy of next-seo's approach of an seo api based on what we require
    if you need any more meta conditionals, they can be accessed from next-seo/src/meta/buildTags.tsx
*/

const defaults = {
    templateTitle: '',
    noindex: false,
    nofollow: false
};

const buildTags = (config: BuildTagsParams) => {
    const tagsToRender = new Array<JSX.Element>();

    if (config.titleTemplate) {
        defaults.templateTitle = config.titleTemplate;
    }

    let updatedTitle = '';
    if (config.title) {
        updatedTitle = config.title;
        if (defaults.templateTitle) {
            updatedTitle = defaults.templateTitle.replace(/%s/g, () => updatedTitle);
        }
        tagsToRender.push(<title key="title">{updatedTitle}</title>);
    }

    const noindex = config.noindex || defaults.noindex;
    const nofollow = config.nofollow || defaults.nofollow;

    tagsToRender.push(
        <meta
            key="robots"
            name="robots"
            content={`${noindex ? 'noindex' : 'index'},${nofollow ? 'nofollow' : 'follow'}`}
        />
    );
    tagsToRender.push(
        <meta
            key="googlebot"
            name="googlebot"
            content={`${noindex ? 'noindex' : 'index'},${nofollow ? 'nofollow' : 'follow'}`}
        />
    );

    if (config.description) {
        tagsToRender.push(
            <meta key="description" name="description" content={config.description} />
        );
    }

    if (config.twitter) {
        if (config.twitter.cardType) {
            tagsToRender.push(
                <meta key="twitter:card" name="twitter:card" content={config.twitter.cardType} />
            );
        }
    }

    if (config.openGraph) {
        if (config.openGraph.url || config.canonical) {
            tagsToRender.push(
                <meta
                    key="og:url"
                    property="og:url"
                    content={config.openGraph.url || config.canonical}
                />
            );
        }

        if (config.openGraph.type) {
            const type = config.openGraph.type.toLowerCase();

            tagsToRender.push(<meta key="og:type" property="og:type" content={type} />);
        }

        if (config.openGraph.title || config.title) {
            tagsToRender.push(
                <meta
                    key="og:title"
                    property="og:title"
                    content={config.openGraph.title || updatedTitle}
                />
            );
        }

        if (config.openGraph.description || config.description) {
            tagsToRender.push(
                <meta
                    key="og:description"
                    property="og:description"
                    content={config.openGraph.description || config.description}
                />
            );
        }

        if (config.openGraph.images && config.openGraph.images.length) {
            config.openGraph.images.forEach((image, index) => {
                tagsToRender.push(
                    <meta key={`og:image:0${index}`} property="og:image" content={image.url} />
                );

                if (image.alt) {
                    tagsToRender.push(
                        <meta
                            key={`og:image:alt0${index}`}
                            property="og:image:alt"
                            content={image.alt}
                        />
                    );
                }

                if (image.width) {
                    tagsToRender.push(
                        <meta
                            key={`og:image:width0${index}`}
                            property="og:image:width"
                            content={image.width.toString()}
                        />
                    );
                }

                if (image.height) {
                    tagsToRender.push(
                        <meta
                            key={`og:image:height${index}`}
                            property="og:image:height"
                            content={image.height.toString()}
                        />
                    );
                }
            });
        }

        if (config.openGraph.locale) {
            tagsToRender.push(
                <meta key="og:locale" property="og:locale" content={config.openGraph.locale} />
            );
        }

        if (config.openGraph.site_name) {
            tagsToRender.push(
                <meta
                    key="og:site_name"
                    property="og:site_name"
                    content={config.openGraph.site_name}
                />
            );
        }
    }

    if (config.canonical) {
        tagsToRender.push(<link rel="canonical" href={config.canonical} key="canonical" />);
    }

    return tagsToRender;
};

export default buildTags;
