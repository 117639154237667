import {PreloadConfig} from 'bigdatr-style/src/util/usePreload';
import {usePageDataApi} from './usePageData';

export type PageRouteConfig = {
    to: string;
    preload: PreloadConfig;
};

const usePageRoute = (route?: string): PageRouteConfig | undefined => {
    const pageDataApi = usePageDataApi();

    if (!route) return undefined;

    return {
        to: route,
        preload: {
            request: pageDataApi,
            responseKey: route,
            payload: route
        }
    };
};

export default usePageRoute;
