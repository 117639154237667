import React from 'react';
import {Skeleton, Text} from 'bigdatr-style';
import {AspectRatio, Box, Flex, StyledProps} from 'bigdatr-style/layout';
import CreativePlayer from '../affordance/CreativePlayer';
import ResponsiveLayout from '../affordance/ResponsiveLayout';
import {APP_PADDING} from '../App';
import ClientWebCreative from '../ClientWebCreative';

type LayoutProps = ({creative: ClientWebCreative} | {skeleton: true}) & {
    left: React.ReactNode;
    right: React.ReactNode;
} & Pick<StyledProps, 'width'>;

const Layout = ({left, right, ...props}: LayoutProps) => {
    return (
        <Box width={props.width}>
            <Box mx={{_: -APP_PADDING, md: 0}}>
                {'creative' in props ? (
                    <ResponsiveLayout
                        breakpoint="md"
                        before={<CreativePlayer creative={props.creative} mobile />}
                        after={<CreativePlayer creative={props.creative} />}
                    />
                ) : (
                    <AspectRatio ratio={16 / 9}>
                        <Skeleton width="100%" height="100%" />
                    </AspectRatio>
                )}
            </Box>
            <Flex pt={3} pb={4} flexWrap="wrap" alignItems="center">
                <Box mr="auto" width={{_: 1, lg: 3 / 4}}>
                    {left}
                </Box>
                {right}
            </Flex>
        </Box>
    );
};

type Props = {creative: ClientWebCreative; skeleton?: false} | {skeleton: true};
const BrandCreativeHero = (props: Props) => {
    if ('skeleton' in props && props.skeleton) {
        return (
            <Layout
                left={
                    <>
                        <Skeleton width={{_: '100%', lg: '30rem'}} height="2rem" />
                        <Skeleton width={{_: '8rem', lg: '10rem'}} height="1rem" mt={1} />
                    </>
                }
                right={
                    <Skeleton width={{_: '5rem', lg: '10rem'}} height="1rem" mt={{_: 1, lg: 0}} />
                }
                skeleton
            />
        );
    }

    const {creative} = props;
    return (
        <Layout
            creative={creative}
            left={<Text dateFormat="MMM dd yyyy">{creative.firstAppeared}</Text>}
            right={
                <Box textAlign={{_: 'left', lg: 'right'}} width={{_: 1, lg: 1 / 4}}>
                    {creative.publicationList && <Box>{creative.publicationList[0]?.name}</Box>}
                </Box>
            }
        />
    );
};

export default BrandCreativeHero;
