// copied from here
// https://github.com/streamich/react-use/blob/master/src/factory/createHTMLMediaHook.ts

import React, {useEffect, useState, useRef, useCallback} from 'react';
import {useAnalytics} from '../core/analytics/AnalyticsContext';

export interface HTMLMediaState {
    duration: number;
    paused: boolean;
    muted: boolean;
    firstPlay: boolean;
    time: number;
    volume: number;
    buffered: number;
}

type Props = (React.VideoHTMLAttributes<any> | React.AudioHTMLAttributes<any>) & {
    tag: 'audio' | 'video';
};

export default function useMedia<T extends HTMLVideoElement | HTMLAudioElement>(props: Props) {
    const {tag, ...mediaProps} = props;
    const analytics = useAnalytics();

    const [mediaState, setMediaState] = useState<HTMLMediaState>({
        time: 0,
        duration: 0,
        paused: true,
        muted: false,
        firstPlay: !props.autoPlay,
        volume: 1,
        buffered: 0
    });

    const mediaRef = useRef<T | null>(null);
    const nextAction = useRef<(() => void) | null>(null);
    const lockPlayRef = useRef<boolean>(false);

    const onPlay = () => setMediaState({...mediaState, firstPlay: false, paused: false});
    const onPause = () => setMediaState({...mediaState, paused: true});

    const onVolumeChange = () => {
        if (!mediaRef.current) return;
        setMediaState({
            ...mediaState,
            muted: mediaRef.current.muted,
            volume: mediaRef.current.volume
        });
    };
    const onDurationChange = () => {
        if (!mediaRef.current) return;

        setMediaState({...mediaState, duration: mediaRef.current.duration});
    };

    const onTimeUpdate = () => {
        if (!mediaRef.current) return;
        setMediaState({...mediaState, time: mediaRef.current.currentTime});
    };

    const media = React.createElement(tag, {
        controls: false,
        ...mediaProps,
        ref: mediaRef,
        onPlay,
        onPause,
        onVolumeChange,
        onDurationChange,
        onTimeUpdate
    });

    const mediaControlOnPlay = useCallback(() => {
        const el = mediaRef.current;
        if (!el) {
            return undefined;
        }

        if (!lockPlayRef.current) {
            // some older browsers don't return a promise for playing media, thats why we wrap it in a Promise.resolve
            const promise = Promise.resolve(el.play())
                .then(() => {
                    analytics.addBreadcrumb('videoPlays', `media url ${props.src}`);
                })
                .catch((e) => {
                    // NotAllowedError can happen if autoPlay is enabled, but the video is not
                    // allowed to auto play because the user hasn't interacted with the DOM yet
                    if (e.name === 'NotAllowedError') {
                        return;
                    }
                    // Attempt at fixing a bug where some videos don't work for some people, but then
                    // when we try to replicate the bug with the same video it suddenly works.
                    // We are catching the error in case it fixes it, but still tracking it in case it doesn't
                    if (e.name === 'NotSupportedError') {
                        analytics.trackError(e, {caught: true});
                        return;
                    }
                    throw e;
                });

            lockPlayRef.current = true;

            const resetLock = () => {
                lockPlayRef.current = false;
            };

            promise
                .then(() => {
                    if (nextAction.current) {
                        nextAction.current();
                        nextAction.current = null;
                    }
                    resetLock();
                })
                .catch((e: Error) => {
                    // AbortError can happen if the user closes a modal with media that would
                    // have autoplayed, but the media wasn't able to play before the closing.
                    // The error doesn't seem to break the app so hopefully its safe to ignore it
                    if (e.name === 'AbortError') return;
                    else throw e;
                });

            return promise;
        }
        return undefined;
    }, [mediaRef.current, lockPlayRef.current, nextAction.current]);

    const mediaControlOnPause = useCallback(() => {
        if (mediaRef.current) {
            const pauseFunc = () => {
                mediaRef.current?.pause();
            };
            if (!lockPlayRef.current) pauseFunc();
            else nextAction.current = pauseFunc;
        }
    }, [mediaRef.current, lockPlayRef.current, nextAction.current]);

    // Some browsers return `Promise` on `.play()` and may throw errors
    // if one tries to execute another `.play()` or `.pause()` while that
    // promise is resolving. So we prevent that with this lock.
    // See: https://bugs.chromium.org/p/chromium/issues/detail?id=593273

    const mediaControls = {
        play: mediaControlOnPlay,
        load: useCallback(() => {
            if (mediaRef.current) {
                const loadFunc = () => {
                    mediaRef.current?.load();
                };
                if (!lockPlayRef.current) loadFunc();
                else nextAction.current = loadFunc;
            }
        }, [mediaRef.current, lockPlayRef.current, nextAction.current]),
        pause: mediaControlOnPause,
        togglePlayState: useCallback(() => {
            if (mediaRef.current) {
                if (mediaRef.current.paused) {
                    mediaControlOnPlay();
                } else {
                    mediaControlOnPause();
                }
            }
        }, [mediaRef.current]),
        seek: useCallback(
            (time: number) => {
                const el = mediaRef.current;
                if (!el || mediaState.duration === undefined) {
                    return;
                }
                time = Math.min(mediaState.duration, Math.max(0, time));
                el.currentTime = time;
            },
            [mediaRef.current, mediaState.duration]
        ),
        volume: useCallback(
            (volume: number) => {
                const el = mediaRef.current;
                if (!el) {
                    return;
                }
                volume = Math.min(1, Math.max(0, volume));
                el.volume = volume;
                setMediaState({...mediaState, volume});
            },
            [mediaRef.current, setMediaState]
        ),
        mute: useCallback(() => {
            const el = mediaRef.current;
            if (!el) {
                return;
            }
            el.muted = true;
        }, [mediaRef.current]),
        unmute: useCallback(() => {
            const el = mediaRef.current;
            if (!el) {
                return;
            }
            el.muted = false;
        }, [mediaRef.current]),
        toggleMuteState: useCallback(() => {
            const el = mediaRef.current;
            if (!el) {
                return;
            }
            if (el.muted) {
                el.muted = false;
            } else {
                el.muted = true;
            }
        }, [mediaRef.current])
    };

    useEffect(() => {
        if (!mediaRef.current) return;

        setMediaState({
            ...mediaState,
            volume: mediaRef.current.volume,
            muted: mediaRef.current.muted,
            paused: mediaRef.current.paused,
            duration: mediaRef.current.duration
        });

        // Start media, if autoPlay requested.
        if (props.autoPlay && mediaRef.current.paused) {
            mediaControls.play();
        }

        // Buffering interval
        const interval = setInterval(() => {
            // only update if buffering has started
            if (mediaRef.current && mediaRef.current.buffered.length > 0) {
                setMediaState((prevState) => {
                    return {
                        ...prevState,
                        buffered: mediaRef.current?.buffered.end(0) || 0,
                        time: mediaRef.current?.currentTime || 0
                    };
                });
            }
        }, 150);

        // Cleanup
        return () => {
            clearInterval(interval);
        };
    }, [props.src]);

    return {media, mediaState, mediaControls, mediaRef} as const;
}
