import React from 'react';
import {Button, Text} from 'bigdatr-style';
import {Link} from 'bigdatr-style';
import {Creative} from 'bigdatr-style';
import {CreativePreview} from 'bigdatr-style';
import {Ol, Wrapper} from 'bigdatr-style/layout';
import {Box, Flex, Ul, Li, Grid} from 'bigdatr-style/layout';
import {MDXProvider} from '@mdx-js/react';

export default function MdxLayout(props: {
    maxWidth?: string;
    fontSize?: string;
    nofollow?: boolean;
    verticalMargin?: number;
    height?: string;
    children: any;
}) {
    const {maxWidth = '60rem', fontSize, nofollow, verticalMargin = 6, height} = props;
    return (
        <Wrapper maxWidth={maxWidth} my={verticalMargin} fontSize={fontSize} height={height}>
            <MDXProvider
                children={props.children}
                components={{
                    h1: (props) => (
                        <Box as="h1" mb={3}>
                            <Text textStyle="heading0" {...props} />
                        </Box>
                    ),
                    h2: (props) => (
                        <Box as="h2" mb={4} mt={5}>
                            <Text textStyle="heading2" {...props} />
                        </Box>
                    ),
                    h3: (props) => (
                        <Box as="h3" mb={3} mt={4}>
                            <Text textStyle="heading3" {...props} />
                        </Box>
                    ),
                    h4: (props) => <Text as="h3" my={3} textStyle="heading4" {...props} />,
                    p: (props) => <Box my={4} as="p" {...props} />,
                    a: (props) => (
                        <Link
                            rel={nofollow ? 'nofollow' : undefined}
                            style={{wordBreak: 'break-word'}}
                            {...props}
                        />
                    ),
                    em: (props) => <Text as="em" textStyle="emphasis" {...props} />,
                    strong: (props) => <Text as="strong" textStyle="strong" {...props} />,
                    ol: Ol,
                    ul: Ul,
                    li: Li,
                    span: (props) => <span style={{wordBreak: 'break-word'}} {...props} />,
                    blockquote: (props) => (
                        <Box pl={3} ml={3} borderLeft="outline">
                            <Text as="blockquote" textStyle="emphasis" {...props} />
                        </Box>
                    ),
                    img: Img,
                    Embed,
                    Creative: ({creative, alt}: {creative: string; alt: string}) => {
                        const creativeItem = new Creative({id: creative.split('.')[0], creative});
                        return (
                            <Box my={3} as="p">
                                <CreativePreview
                                    creativeItem={creativeItem}
                                    alt={alt}
                                    autoPlay={false}
                                />
                            </Box>
                        );
                    },
                    Button: ({text, to, href}: {text: string; to?: string; href?: string}) => {
                        return (
                            <Flex my={3}>
                                <Button to={to} href={href} style={{fontSize: '1rem'}}>
                                    {text}
                                </Button>
                            </Flex>
                        );
                    },
                    Sup: ({text, id}: {text: string; id?: string}) => {
                        const sup = <sup style={{fontSize: '0.8rem'}}>{text}</sup>;
                        if (!id) return sup;
                        return <Link href={`#${id}`}>{sup}</Link>;
                    },
                    ID: ({id}: {id: string}) => <Box my={3} id={id} aria-hidden="true" />,
                    // really bespoke component, doing it this way cause there is a bug in the blog
                    // dataLoader which duplicates JSX elements within MDX components, so you can't
                    // pass in JSX elements as children to MDX
                    ImageColumns: ({
                        firstSrc,
                        firstAlt,
                        firstHref,
                        secondSrc,
                        secondAlt,
                        secondHref,
                        thirdSrc,
                        thirdAlt,
                        thirdHref,
                        description
                    }: {
                        firstSrc: string;
                        firstAlt: string;
                        firstHref: string;
                        secondSrc: string;
                        secondAlt: string;
                        secondHref: string;
                        thirdSrc?: string;
                        thirdAlt?: string;
                        thirdHref?: string;
                        description: string;
                    }) => {
                        const hasThirdImage = thirdHref && thirdSrc && thirdAlt;
                        const columns = `1fr 1fr ${hasThirdImage ? '1fr' : ''}`;
                        return (
                            <Grid gridTemplateColumns={{sm: columns}} gap={3}>
                                <a href={firstHref}>
                                    <Img src={firstSrc} alt={firstAlt} />
                                </a>
                                <a href={secondHref}>
                                    <Img src={secondSrc} alt={secondAlt} />
                                </a>
                                {hasThirdImage && (
                                    <a href={thirdHref}>
                                        <Img src={thirdSrc} alt={thirdAlt} />
                                    </a>
                                )}
                                <em style={{gridColumn: '-1/1'}}>{description}</em>
                            </Grid>
                        );
                    }
                }}
            />
        </Wrapper>
    );
}

function Img(props: {src: string; alt: string; height?: string}) {
    return <img {...props} style={{margin: 'auto', maxWidth: '100%', display: 'block'}} />;
}

function Embed(props: {src: string}) {
    let url = props.src;
    if (url.indexOf('youtu.be') !== -1) {
        const [id] = url.match(/([^/]+$)/g) || [];
        url = `https://www.youtube.com/embed/${id}`;
    } else if (url.indexOf('youtube') !== -1) {
        const params = new URLSearchParams(url.split('?')[1]);
        url = `https://www.youtube.com/embed/${params.get('v')}`;
    } else if (url.indexOf('vimeo') !== -1) {
        const [id] = url.match(/([^/]+$)/g) || [];
        url = `https://player.vimeo.com/video/${id}`;
    }

    return (
        <Flex justifyContent="center" mb={3} mt={4}>
            <iframe
                src={url}
                width="672"
                height="378"
                allow="autoplay; fullscreen"
                style={{border: 0}}
                allowFullScreen
            />
        </Flex>
    );
}
