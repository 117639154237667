import React from 'react';

import {Brand, EntityAvatarBrand, Skeleton, Text} from 'bigdatr-style';
import {Box, Flex} from 'bigdatr-style/layout';

import MaybeClickable, {MaybeClickableProps} from '../affordance/MaybeClickable';
import usePageRoute from '../core/usePageRoute';
import {ClientWebBrand} from '../ClientWebBrand';

type LayoutProps = {
    name: React.ReactNode;
    image: React.ReactNode;
    clickableProps?: MaybeClickableProps['clickableProps'];
    mobile?: boolean;
};
const Layout = ({name, image, clickableProps}: LayoutProps) => (
    <Box p={2} width="100%">
        <MaybeClickable {...{clickableProps}}>
            <Flex alignItems="center">
                <Flex alignItems="center" width="3rem" height="3rem" m={2}>
                    {image}
                </Flex>
                {name}
            </Flex>
        </MaybeClickable>
    </Box>
);

const SkeletonLayout = (props: {mobile?: boolean}) => (
    <Layout
        {...props}
        image={<Skeleton width="3rem" height="3rem" />}
        name={<Skeleton width="8rem" />}
    />
);

const ItemLayout = ({brand, mobile}: {brand: ClientWebBrand; mobile?: boolean}) => {
    const clickableProps = usePageRoute(brand.key ? `/au/brand/${brand.key}` : undefined);

    return (
        <Layout
            {...{clickableProps, mobile}}
            image={<EntityAvatarBrand entity={new Brand({name: brand.name})} width="3rem" />}
            name={<Text textStyle="strong">{brand.name}</Text>}
        />
    );
};

type Props =
    | {brand: ClientWebBrand; mobile?: boolean; skeleton?: false}
    | {mobile?: boolean; skeleton: true};
const BrandDiscoverBrandListItem = (props: Props) => {
    if ('skeleton' in props && props.skeleton) return <SkeletonLayout mobile={props.mobile} />;
    return <ItemLayout brand={props.brand} mobile={props.mobile} />;
};

export default BrandDiscoverBrandListItem;
