import React from 'react';
import {Svg} from 'bigdatr-style/layout';

export default function Creatives() {
    return (
        <Svg viewBox="0 0 826 690">
            <path
                d="M635.359 426.013h-161.98c-101.66 0-184.07-82.41-184.07-184.07v-51.54c0-101.66 82.41-184.07 184.07-184.07h161.98c101.66 0 184.07 82.41 184.07 184.07v51.54c.01 101.66-82.41 184.07-184.07 184.07Z"
                fill="#fff"
            />
            <path
                d="M635.359 419.693H478.279c-23.08 0-45.51-3.16-67.25-11.22-29.54-10.96-55.76-30.15-75.69-54.49-20.21-24.69-33.25-54.91-37.92-86.43-3.18-21.44-1.78-43.8-1.78-65.42 0-9.65-.1-19.29.79-28.91 1.5-16.19 5.4-32.19 11.26-47.36 11.28-29.23 30.69-55.08 55.09-74.66 24.83-19.91 55.37-32.85 86.91-37.04 14.88-1.98 29.95-1.51 44.93-1.51h127.42c17.88 0 35.5.41 53.06 4.45 30.94 7.11 59.66 22.51 82.66 44.4 22.8 21.7 39.69 49.26 48.41 79.52 4.59 15.91 6.89 32.32 6.95 48.88.03 7.82 0 15.64 0 23.46 0 23.77.68 47.32-5 70.62-7.47 30.67-23.14 59.02-45.12 81.67-22.05 22.72-49.98 39.35-80.52 47.72-15.4 4.22-31.18 6.27-47.12 6.32-8.14.02-8.16 12.68 0 12.65 34.02-.09 67.74-9.16 97.03-26.54 28.44-16.88 52.38-41.26 68.69-70.03 16.21-28.61 24.62-61.16 24.68-94.02.03-17.11.04-34.22 0-51.33-.07-33.86-9.08-67.45-26.31-96.64-16.81-28.48-41.13-52.48-69.87-68.86-28.65-16.33-61.32-24.84-94.28-24.91-12.68-.03-25.37 0-38.05 0h-121.38c-27.09 0-53.52 5.06-78.43 15.79-30.37 13.08-56.75 34.35-76.62 60.71-20.36 27-32.85 59.31-36.67 92.85-2.45 21.53-1.19 43.69-1.19 65.32 0 11.51.37 22.91 1.96 34.34 2.32 16.72 7 32.92 13.64 48.43 13.04 30.41 34.2 56.82 60.52 76.77 26.94 20.42 59.21 32.99 92.74 36.9 15 1.75 30.21 1.25 45.28 1.25h138.24c8.17-.03 8.18-12.68.02-12.68Z"
                fill="#170B24"
            />
            <path
                d="m638.429 189.323-100.92-63.1c-23.46-14.67-54.01 1.56-54.98 29.21l-4.19 118.95c-.97 27.65 28.35 45.99 52.79 33.01l105.11-55.85c24.43-12.99 25.65-47.55 2.19-62.22Z"
                fill="#fff"
            />
            <path
                d="M641.619 183.863c-12.01-7.51-24.03-15.02-36.04-22.53-18.42-11.51-36.83-23.03-55.25-34.54-3.85-2.41-7.65-5.03-11.65-7.2-13.91-7.54-31.56-6.78-44.5 2.52-11.13 8.01-17.47 20.14-18 33.82-.22 5.6-.39 11.2-.59 16.8-.79 22.37-1.58 44.75-2.36 67.12-.32 9-.63 17.99-.95 26.99-.23 6.48-.6 12.83.97 19.23 3.74 15.21 16.55 27.56 31.84 30.88 10.94 2.37 21.34.1 31.08-5.07 15.97-8.49 31.94-16.97 47.91-25.46 17.18-9.13 34.37-18.26 51.55-27.39 7.5-3.99 14.17-8.68 19.03-15.88 8.48-12.57 9.05-29.32 2.31-42.75-3.44-6.86-8.94-12.43-15.35-16.54-6.87-4.41-13.23 6.54-6.39 10.93 10.6 6.8 15.74 19.53 13.09 31.32-2.08 9.28-8.01 16.09-16.34 20.53-14.27 7.61-28.56 15.18-42.84 22.77l-53.19 28.26c-4.02 2.14-8.13 4.75-12.48 6.16-12.14 3.93-25.92-.45-33.36-10.93-4.69-6.6-5.6-13.93-5.33-21.75l.75-21.18c.77-21.79 1.53-43.57 2.3-65.36.28-7.9.56-15.79.83-23.69.22-6.12.4-12.06 3.33-17.68 5.76-11.06 18.2-17.75 30.56-15.99 6.35.9 11.44 4.21 16.71 7.51 31.15 19.48 62.3 38.95 93.45 58.43.84.53 1.69 1.06 2.53 1.58 6.92 4.34 13.28-6.6 6.38-10.91Z"
                fill="#170B24"
            />
            <path
                d="M110.979 637.003c-60.62-18.79-104.65-75.3-104.65-142.1v-120.72c0-82.15 66.59-148.74 148.74-148.74h120.72c40.66 0 77.52 16.32 104.37 42.76"
                fill="#fff"
            />
            <path
                d="M112.659 630.903c-30.86-9.68-58.06-29.89-76.01-56.84-15.96-23.97-24-51.85-24-80.58v-112.57c0-6.84 0-13.66.63-20.49 1.42-15.53 5.56-31.05 12.08-45.22 13.03-28.28 34.99-51.79 62.41-66.57 22.05-11.89 46.22-16.87 71.12-16.87h113.47c29.59 0 57.94 7.55 82.69 24.06 7.42 4.95 14.26 10.62 20.64 16.85 5.83 5.7 14.78-3.24 8.95-8.95-28.42-27.79-66.7-43.98-106.5-44.6-14.89-.23-29.8-.02-44.7-.02h-66.52c-4.77 0-9.55-.06-14.32.02-17.03.28-34.12 3.32-50.12 9.15-31.13 11.34-57.99 32.42-76.46 59.92-17.12 25.5-25.98 55.9-26.01 86.56-.02 16.78 0 33.56 0 50.33v62.89c0 7.45.02 14.86.79 22.29 1.83 17.8 6.59 35.01 14.22 51.2 14.6 30.94 39.94 56.79 70.55 72.06 7.64 3.81 15.6 7.01 23.74 9.56 7.78 2.46 11.11-9.75 3.35-12.18Z"
                fill="#170B24"
            />
            <path d="M122.449 640.063c-3.88-.87-7.71-1.89-11.47-3.06Z" fill="#fff" />
            <path
                d="M124.139 633.953c-3.86-.87-7.69-1.89-11.47-3.06-1.54-.48-3.5-.17-4.88.64-1.31.77-2.56 2.27-2.91 3.78-.37 1.62-.29 3.44.64 4.88.91 1.41 2.16 2.41 3.78 2.91 3.78 1.17 7.61 2.19 11.47 3.06 1.65.37 3.39.23 4.88-.64 1.31-.77 2.56-2.27 2.91-3.78.37-1.62.29-3.44-.64-4.88-.87-1.35-2.17-2.54-3.78-2.91Z"
                fill="#170B24"
            />
            <path
                d="M329.059 683.283h-79.51c-93.53 0-169.35-75.82-169.35-169.35v-79.51c0-93.53 75.82-169.35 169.35-169.35h79.51c93.53 0 169.35 75.82 169.35 169.35v79.51c0 93.53-75.82 169.35-169.35 169.35Z"
                fill="#fff"
            />
            <path
                d="M329.059 676.953c-28.12 0-56.34.73-84.45-.07-53.82-1.54-104.48-30.61-133.11-76.19-14.5-23.08-23.13-49.68-24.69-76.91-.84-14.61-.28-29.38-.28-44 0-15.02-.03-30.04 0-45.06.05-25.56 5.84-50.95 17.45-73.76 24.28-47.74 71.72-81.79 125.07-88.31 15.58-1.9 31.49-1.23 47.15-1.23h50.76c22.93 0 45.66 4.08 66.75 13.27 24.39 10.64 45.88 27.11 62.61 47.79 16.74 20.69 28 45.63 32.99 71.73 3.19 16.69 2.77 33.53 2.77 50.42v53.93c0 20.97-2.61 41.3-9.76 61.13-18.19 50.46-61.79 89.46-113.74 102.45-12.95 3.23-26.17 4.77-39.52 4.81-8.14.02-8.16 12.68 0 12.65 57.97-.17 112.76-29.25 145.43-77.13 16.04-23.5 25.88-50.78 29.12-79 1.86-16.14 1.12-32.66 1.12-48.88 0-16.4.01-32.81 0-49.21-.02-26.08-5.54-51.72-16.53-75.39-23.75-51.16-72.78-88.91-128.38-98.53-17.56-3.04-35.12-2.71-52.84-2.71h-53.77c-23.3 0-46.29 3.55-68.01 12.2-26.2 10.45-49.84 26.96-68.3 48.33-18.36 21.25-31.67 46.75-38.18 74.1-4.45 18.68-4.82 37.43-4.82 56.48v56.46c0 21.19 2.02 42.03 8.68 62.28 17.75 53.93 61.58 96.9 115.91 113.46 19.86 6.05 40.13 7.54 60.74 7.54h69.84c8.14.01 8.15-12.65-.01-12.65Z"
                fill="#170B24"
            />
            <path
                d="M121.649 572.263c30.45-38.8 60.91-77.6 91.36-116.41 15.8 16.19 31.6 32.38 47.4 48.56 25.87-37.74 51.74-75.49 77.62-113.23 39.09 61.44 78.18 122.87 117.27 184.31"
                fill="#fff"
            />
            <path
                d="M126.129 576.743c30.45-38.8 60.91-77.6 91.36-116.41h-8.95c15.8 16.19 31.6 32.38 47.4 48.56 3.02 3.09 7.68 2.01 9.94-1.28 25.87-37.74 51.74-75.49 77.62-113.23h-10.93c30.44 47.85 60.89 95.7 91.33 143.55 8.64 13.59 17.29 27.17 25.93 40.76 4.36 6.85 15.32.51 10.93-6.39-30.44-47.85-60.89-95.7-91.33-143.55-8.64-13.59-17.29-27.17-25.93-40.76-2.66-4.19-8.22-3.95-10.93 0-25.87 37.74-51.74 75.49-77.62 113.23 3.31-.43 6.63-.85 9.94-1.28-15.8-16.19-31.6-32.38-47.4-48.56-2.18-2.23-6.85-2.68-8.95 0-30.45 38.8-60.91 77.6-91.36 116.41-2.11 2.69-2.62 6.33 0 8.95 2.19 2.19 6.82 2.7 8.95 0Z"
                fill="#170B24"
            />
            <path
                d="M246.259 434.543c17.032 0 30.84-13.807 30.84-30.84 0-17.032-13.808-30.84-30.84-30.84-17.033 0-30.84 13.808-30.84 30.84 0 17.033 13.807 30.84 30.84 30.84Z"
                fill="#fff"
            />
            <path
                d="M270.779 403.703c-.14 10.06-6.31 19.53-15.9 22.98-9.96 3.58-21.19.58-27.83-7.72-6.31-7.88-6.97-19.21-1.8-27.86 5.12-8.57 15.38-13.37 25.25-11.57 11.73 2.14 20.11 12.33 20.28 24.17.11 8.14 12.77 8.16 12.65 0-.21-15.62-9.83-29.36-24.43-34.9-14.31-5.43-31.47-.81-41.17 11-9.91 12.06-11.61 29.36-3.53 42.88 8.08 13.52 23.78 20.22 39.13 17.46 17.38-3.13 29.75-19.11 29.99-36.45.12-8.13-12.53-8.14-12.64.01Z"
                fill="#170B24"
            />
        </Svg>
    );
}
