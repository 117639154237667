import React from 'react';
import {Flex} from 'bigdatr-style/layout';
import {CreativePreview, Creative} from 'bigdatr-style';
import ClientWebCreative from '../ClientWebCreative';

type CreativePlayerProps = {creative: ClientWebCreative; mobile?: boolean};
export default function CreativePlayer({creative, mobile}: CreativePlayerProps) {
    // All of our creatives in client-web are 16/9 - remove if we need more
    return (
        <Flex
            backgroundColor="black"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
            flexDirection="column"
            position="relative"
        >
            <CreativePreview
                autoPlay={false}
                creativeItem={new Creative({id: creative.id, creative: creative.creative})}
                trackPlaypause={() => {}}
                trackMute={() => {}}
                trackFullscreen={() => {}}
                poster={mobile ? 'small' : 'large'}
            />
        </Flex>
    );
}
