import React from 'react';
import styled from 'styled-components';

const OuterWrapper = styled.div<{
    ratio: number;
}>`
    display: block;
    position: relative;
    width: 100%;

    /**
     * For human readability, the ratio is expressed as
     * width / height, so we need to invert it.
     */
    padding-top: ${(props) => (1 / props.ratio) * 100}%;
`;

const InnerWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
    object-position: 50%;
`;

const AspectRatio = (props: React.PropsWithChildren<{ratio: number}>) => (
    <OuterWrapper ratio={props.ratio}>
        <InnerWrapper>{props.children}</InnerWrapper>
    </OuterWrapper>
);

export default AspectRatio;
