import React from 'react';

import {Image, Skeleton, Text} from 'bigdatr-style';
import {AspectRatio, Box} from 'bigdatr-style/layout';

import usePageRoute from '../core/usePageRoute';
import MaybeClickable, {MaybeClickableProps} from '../affordance/MaybeClickable';
import ClientWebCreative from '../ClientWebCreative';

type LayoutProps = {
    image: React.ReactNode;
    text: React.ReactNode;
    clickableProps?: MaybeClickableProps['clickableProps'];
};
const Layout = ({clickableProps, image, text}: LayoutProps) => (
    <Box width={{_: 1 / 2, lg: 1 / 3}} pr="1rem" mb="1.5rem">
        <MaybeClickable {...{clickableProps}}>
            <AspectRatio ratio={16 / 9}>{image}</AspectRatio>
            <Box lineHeight={0} mt={2}>
                {text}
            </Box>
        </MaybeClickable>
    </Box>
);

const SkeletonLayout = () => (
    <Layout
        image={<Skeleton width="100%" height="100%" />}
        text={
            <>
                <Skeleton height="1rem" my={2} width="100%" />
                <Skeleton height="1rem" my={2} width="40%" />
            </>
        }
    />
);

const ItemLayout = ({creative}: {creative: ClientWebCreative}) => {
    const clickableProps = usePageRoute(`/au/ad/${creative.id}`);

    return (
        <Layout
            {...{clickableProps}}
            image={
                <Image
                    alt="Creative Preview"
                    width="100%"
                    src={creative.croppedThumbnail}
                    sourceSets={[{src: creative.croppedThumbnailWebp, type: 'image/webp'}]}
                    onError={() => null}
                    onLoading={() => <Skeleton width="100%" height="100%" />}
                    loading="lazy"
                />
            }
            text={
                <Text fontSize=".9rem" dateFormat="dd MMM yyyy">
                    {creative.firstAppeared}
                </Text>
            }
        />
    );
};

type Props = {creative: ClientWebCreative; skeleton?: false} | {skeleton: true};
const BrandCreativeListItem = (props: Props) => {
    if ('skeleton' in props && props.skeleton) return <SkeletonLayout />;

    return <ItemLayout creative={props.creative} />;
};

export default BrandCreativeListItem;
