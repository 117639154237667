import {EntityApi} from 'react-enty';
import {PrimingData} from './ProviderFactory';
import {EntyRequest} from 'bigdatr-style';
import EntitySchema from './EntitySchema';
import {ComponentType, createContext, useContext} from 'react';
import registerSubmit from '../auth/data/RegisterApi';

export async function post(url: string, body: any) {
    const response = await fetch(url, {method: 'POST', body: JSON.stringify(body)});
    if (!response.ok) throw new Error(JSON.stringify(response.body));
    return response.json();
}

//
//
// Requesters

// Fetch the json representation of a given page url from the client
async function pageData(url: string) {
    const response = await fetch(`${url}.json`);
    const data: PrimingData = await response.json();
    if (!response.ok) throw data.payload;
    return data.payload;
}

//
// Submit an answer to the quiz on the landing pages
async function landingPageVideoQuiz(answer: {
    id: string;
    answer: string;
    anonymousId: string;
    position: number;
}) {
    return post(`/api`, {
        variables: {answer},
        query: `mutation SubmitLandingPageVideoQuiz($answer: QuizSubmitInput!) {
            quiz {quizSubmit(mutation: $answer)}
        }`
    });
}

//
//
// Context

type ApiContextType = {
    Provider: ComponentType<any>;
    pageData: EntyRequest<unknown>;
    landingPageVideoQuiz: EntyRequest<unknown>;
    registerSubmit: EntyRequest<unknown>;
};

export const ApiContext = createContext<ApiContextType | undefined>(undefined);

// App Context Hook
export function useApi(): ApiContextType {
    const api = useContext(ApiContext);
    if (!api) throw new Error('ApiContext is not yet initialized');
    return api;
}

//
//
// Factory
export default function EntityApiFactory(primingData: Array<PrimingData>) {
    return EntityApi(
        {
            pageData,
            landingPageVideoQuiz,
            registerSubmit
        },
        EntitySchema,
        primingData
    ) as ApiContextType;
}
