import {
    Box,
    FlexColumn,
    Grid,
    Table,
    TableBody,
    TableCell as Td,
    TableHead,
    TableHeadCell as Th,
    TableRow as Tr,
    Wrapper
} from 'bigdatr-style/layout';
import {Text, HelpTooltip, Accordion, Link, useAnalytics} from 'bigdatr-style';
import React from 'react';
import {IconAdvertistingCreative, IconCheck, IconMediaValue} from 'bigdatr-style/icon';
import SeoTemplate from '../affordance/SeoTemplate';
import RegisterButton from '../auth/RegisterButton';

export const advertisingCreativeDescription =
    "Decide how you'll attract your target audience by understanding what your competitors are doing first.";
export const mediaValueDescription =
    'Spend your budget more wisely and improve ROI by knowing where and how much others are spending.';

export default function ComparePlans() {
    return (
        <Wrapper maxWidth="55rem" width="100%">
            <SeoTemplate
                title="Bigdatr - Product plans"
                description="Discover different Bigdatr products and how they can help you grow your business."
                slug="/compare-plans"
            />

            <FlexColumn gap={5} pt={4} pb={5}>
                <Text as="h1" textStyle={{_: 'heading2', sm: 'heading0'}} textAlign="center">
                    Track advertising as it happens. Start free.
                </Text>
                <Grid
                    gridTemplateColumns={{sm: '1fr 1fr'}}
                    gap={{_: 5, md: 3}}
                    justifyItems="center"
                >
                    <ProductCard
                        icon={<IconAdvertistingCreative size="3rem" />}
                        title="Advertising Creative"
                        description={advertisingCreativeDescription}
                    />
                    <ProductCard
                        icon={<IconMediaValue size="3rem" />}
                        title="Media Value"
                        description={mediaValueDescription}
                    />
                </Grid>
                <CompareTables />
                <Box alignSelf="center">
                    <RegisterButton large />
                </Box>
                <Faqs />
            </FlexColumn>
        </Wrapper>
    );
}

function ProductCard(props: {
    icon: React.ReactNode;
    title: string;
    description: string;
    cta?: React.ReactNode;
}) {
    return (
        <FlexColumn
            p={4}
            gap={2}
            border="outline"
            borderRadius=".5rem"
            alignItems="center"
            maxWidth="20rem"
        >
            {props.icon}
            <Text textStyle={{_: 'heading4', md: 'heading3'}} as="h2" textAlign="center">
                {props.title}
            </Text>
            <Text as="p" maxWidth="22rem" textAlign="center">
                {props.description}
            </Text>
        </FlexColumn>
    );
}

function Faqs() {
    return (
        <FlexColumn gap={4}>
            <Text as="h2" textStyle="heading1">
                FAQs
            </Text>

            <FlexColumn gap={3}>
                {faqs.map((ff, ii) => (
                    <Accordion key={ii} children={ff.children} title={ff.title} />
                ))}
            </FlexColumn>
        </FlexColumn>
    );
}

function Contact() {
    const analytics = useAnalytics();
    return (
        <Text>
            <Link onClick={() => analytics.chat()}>chat with us</Link>
            <Text> or send us an email at </Text>
            <Link href="mailto:support@bigdatr.com" target="_blank">
                support@bigdatr.com
            </Link>
        </Text>
    );
}

const baseCellProps = {
    padding: '.5rem 0',
    verticalAlign: 'middle'
};
const thProps = {
    ...baseCellProps,
    width: '50%'
};
const tdProps = {
    ...baseCellProps,
    width: '25%',
    textAlign: 'center' as any // Styled components complaining about textAlign incompabilities
};

function CompareTables() {
    return (
        <FlexColumn gap={4}>
            <Table width="100%">
                <TableHead>
                    <Tr>
                        <Th {...thProps}>
                            <Text as="h1" textStyle={{_: 'heading4', sm: 'heading1'}}>
                                Compare Plans
                            </Text>
                        </Th>
                        <Td {...tdProps}>
                            <Text as="h3" textStyle={{_: '', sm: 'heading3'}}>
                                Advertising Creative
                            </Text>
                        </Td>
                        <Td {...tdProps}>
                            <Text as="h3" textStyle={{_: '', sm: 'heading3'}}>
                                Media Value
                            </Text>
                        </Td>
                    </Tr>
                </TableHead>
            </Table>

            <FlexColumn gap={5}>
                <FeatureTable title="Key Features" features={keyFeatures} />
                <FeatureTable title="Report and Export" features={reportAndExportFeatures} />
                <FeatureTable title="Support" features={supportFeatures} />
            </FlexColumn>
        </FlexColumn>
    );
}

function FeatureTable(props: {
    title: string;
    features: {
        feature: string;
        tooltip?: string;
        inCreative?: boolean;
        inMedia?: boolean;
    }[];
}) {
    return (
        <FlexColumn gap={2}>
            <Text as="h3" textStyle="heading4">
                {props.title}
            </Text>
            <Table width="100%">
                <TableBody>
                    {props.features.map((ff, ii) => (
                        <Tr key={ii} borderBottom="outline">
                            <Th {...thProps}>
                                <Text>{ff.feature} </Text>
                                {ff.tooltip && (
                                    <Text
                                        verticalAlign="middle"
                                        children={<HelpTooltip content={ff.tooltip} />}
                                    />
                                )}
                            </Th>
                            <Td {...tdProps}>
                                {ff.inCreative && (
                                    <Box display="inline-block" verticalAlign="middle">
                                        <IconCheck size="2rem" color="green" />
                                    </Box>
                                )}
                            </Td>
                            <Td {...tdProps}>
                                {ff.inMedia && (
                                    <Box display="inline-block" verticalAlign="middle">
                                        <IconCheck size="2rem" color="green" />
                                    </Box>
                                )}
                            </Td>
                        </Tr>
                    ))}
                </TableBody>
            </Table>
        </FlexColumn>
    );
}

const faqs = [
    {
        title: 'What are your subscription options?',
        children:
            'Each subscriber can tailor their own specific data sets and industry access. As such we prefer to engage with each subscriber to build the most appropriate access for their needs.'
    },
    {
        title: 'How do I gain access to Media Value?',
        children: (
            <Text>
                Media Value is Bigdatr&apos;s premium data plan. If you don&apos;t have access to
                this yet and want to upgrade your plan subscription, <Contact />. We&apos;re happy
                to set up your new permissions within the day.
            </Text>
        )
    },
    {
        title: 'How can I add more industries to my subscription?',
        children: (
            <Text>
                We&apos;d be happy to help upgrade your industry access to include additional
                visibility for Advertising Creative and/or Media Value products. Simply <Contact />{' '}
                regarding your request to add more industries to your subscription.
            </Text>
        )
    },
    {
        title: 'How long is my free trial?',
        children: (
            <Text>
                You can try Bigdatr free for 7 days without the need of a credit card. To activate
                your free trial, create your account at{' '}
                <Link to="/register">bigdatr.com/register</Link>.
            </Text>
        )
    },
    {
        title: 'Can I invite my team members during my free trial?',
        children:
            'Absolutely. You can grant Bigdatr access to everyone in your team, as there is no limit to how many users you can add during your access. All they need to do is accept your team invite and set up their account.'
    },
    {
        title: 'Can I request for a demo?',
        children: (
            <Text>
                You sure can. Just <Contact /> and one of our friendly team members will get in
                touch to discuss and schedule a session for your team.
            </Text>
        )
    }
];
const keyFeatures: React.ComponentProps<typeof FeatureTable>['features'] = [
    {
        feature: 'Millions of ads',
        inCreative: true,
        inMedia: true
    },
    {
        feature: 'Historical data',
        tooltip:
            'Historical coverage can go as far back as January 2017. As we have progressively expanded upon the coverage made available and continue to do so, the coverage start date can differ on a Brand, Industry and Media Type basis.',
        inCreative: true,
        inMedia: true
    },
    {
        feature: 'Downloadable and shareable ads',
        tooltip:
            "There are two handy ways for you to download ads: individually or in bulk. You can also share ads with everyone else as one doesn't need a Bigdatr subscription to visit the link: anyone should be able to see the ad.",
        inCreative: true,
        inMedia: true
    },
    {
        feature: 'Ad alerts and daily updates',
        tooltip:
            "Daily Update Alerts are the notifications you get based on the brands you follow. Allows you to see ads and campaigns as soon as they're released in the market.",
        inCreative: true,
        inMedia: true
    },
    {
        feature: 'Share of Voice changes',
        tooltip:
            "Bigdatr sends you a weekly Share of Voice report that gives you a view into the magnitude of your competitors' activity in market. You can opt-out of and re-subscribe to this email at any time.",
        inCreative: true,
        inMedia: true
    },
    {
        feature: 'Data management',
        tooltip:
            'Personalise and communicate your data comprehensibly with a segmentation tool that provides you freedom to consolidate data beyond our classification of industries and categories.',
        inCreative: true,
        inMedia: true
    },
    {
        feature: 'Invite team members',
        tooltip:
            'You can invite all members of your team and share the same level of permissions you currently have.',
        inCreative: true,
        inMedia: true
    },
    {
        feature: 'Competitor Media Value data',
        tooltip:
            'It gives you valuable insights into how your competitors are planning and spending their media budgets. ',
        inCreative: false,
        inMedia: true
    },
    {
        feature: 'Top spending brands, campaigns and categories',
        tooltip:
            'See competitor rankings and Media Value breakdown of brands, campaigns, and categories.',
        inMedia: true
    },
    {
        feature: 'Preliminary media investment',
        tooltip:
            'Preliminary Media Value data is the latest estimated media investment for the referring month that is yet to be completely finalised.',
        inMedia: true
    },
    {
        feature: 'Trends in Media Value data',
        tooltip:
            'Compare Media Value trends at any preselected date range (previous 1, 3, 6 and 12 months)',
        inMedia: true
    }
];
const reportAndExportFeatures = [
    {
        feature: 'Create custom reports shareable with your team',
        tooltip:
            'Build unlimited custom reports and quickly share reports and templates with team members.',
        inCreative: true,
        inMedia: true
    },
    {
        feature: 'Export data as CSV or PDF',
        inCreative: true,
        inMedia: true
    },
    {
        feature: 'Dynamic chart options ',
        tooltip: 'Dynamic chart options such as table, line, column, stacked, and stacked % views',
        inMedia: true
    }
];
const supportFeatures = [
    {
        feature: 'Email Support',
        inCreative: true,
        inMedia: true
    },
    {
        feature: 'Live Chat Support',
        inCreative: true,
        inMedia: true
    },
    {
        feature: 'Knowledge Base Access',
        inCreative: true,
        inMedia: true
    }
];
