import {Svg} from 'bigdatr-style/layout';
import React from 'react';

export default function MediaChannels() {
    return (
        <Svg viewBox="0 0 1010 1006">
            <path
                d="M517.52 729.49c141.534 0 256.27-114.736 256.27-256.27S659.054 216.95 517.52 216.95 261.25 331.686 261.25 473.22s114.736 256.27 256.27 256.27Z"
                fill="#fff"
            />
            <path
                d="M769.44 473.22c-.04 26.51-4.14 52.86-12.33 78.08-7.86 24.2-19.48 47.1-34.2 67.85-14.51 20.46-32.22 38.82-52.14 54.07-20.13 15.41-42.6 27.86-66.41 36.56-24.72 9.04-50.79 14.19-77.1 15.17-26.51.99-53.06-2.11-78.59-9.34-24.67-6.99-48.15-17.79-69.57-31.87-21.04-13.84-39.93-30.87-55.98-50.26-16.09-19.43-29.32-41.45-38.84-64.81-9.82-24.11-15.95-49.74-17.93-75.71-2.03-26.66.05-53.58 6.39-79.56a251.714 251.714 0 0 1 29.21-70.62c13.12-21.66 29.5-41.24 48.42-58.06 18.89-16.8 40.34-30.67 63.35-41.14 23.5-10.69 48.86-17.71 74.51-20.63 26.49-3.01 53.47-1.96 79.6 3.36 25.29 5.15 49.7 14.17 72.23 26.75 22.04 12.3 42.13 27.87 59.57 46.1 17.54 18.34 32.24 39.28 43.57 61.98 11.52 23.09 19.34 47.97 23.32 73.46 1.99 12.77 2.9 25.7 2.92 38.62.01 5.6 8.71 5.61 8.71 0-.04-27.23-4.23-54.49-12.64-80.4-8.12-25-20.03-48.79-35.27-70.21a264.56 264.56 0 0 0-54.54-56.51c-20.83-15.98-44.06-28.69-68.7-37.73-25.42-9.33-52.33-14.53-79.38-15.58-27.35-1.06-54.93 2.18-81.27 9.64a259.629 259.629 0 0 0-71.65 32.75c-21.84 14.36-41.56 31.97-58.17 52.14a263.266 263.266 0 0 0-40.39 67.51c-10.23 24.93-16.38 51.44-18.47 78.3-2.13 27.4.12 55.19 6.6 81.89 6.23 25.68 16.48 50.44 30.17 73.03 13.54 22.35 30.48 42.71 50.04 60.05 19.55 17.34 41.66 31.8 65.48 42.56 24.62 11.12 50.75 18.32 77.58 21.45 27.38 3.19 55.26 1.95 82.28-3.52 26-5.26 51.18-14.63 74.36-27.53 22.85-12.72 43.81-28.95 61.86-47.87 18-18.87 33.23-40.45 44.85-63.81 11.88-23.89 20.12-49.56 24.15-75.93 2.04-13.32 3.1-26.76 3.11-40.23 0-5.61-8.71-5.61-8.71 0Z"
                fill="#170B24"
            />
            <path
                d="M517.52 216.94c-92.6 57.66-147.59 160.03-143.44 267.75 6.04 156.49 133.6 238.33 144.07 244.8"
                fill="#fff"
            />
            <path
                d="M515.32 213.18c-43.48 27.15-80.06 64.86-105.53 109.38-25.08 43.85-39.12 93.8-40.22 144.32-1.04 47.89 9.77 95.45 31.3 138.23 16.61 33 39.31 62.93 65.81 88.63 14.56 14.12 30.34 27.38 47.43 38.36.61.39 1.22.78 1.84 1.16 4.78 2.95 9.16-4.58 4.39-7.52-4.73-2.92-9.3-6.13-13.78-9.43-.9-.66-1.8-1.33-2.7-2.01-.39-.3-.79-.59-1.18-.89-.29-.22-1.99-1.52-1.07-.81-2.2-1.71-4.39-3.44-6.55-5.2-4.85-3.95-9.58-8.04-14.2-12.25-25.46-23.19-47.58-50.08-64.72-79.98-22.54-39.33-35.67-83.56-37.67-128.88-2.17-49.37 8.34-99 30.27-143.29 21.97-44.39 54.92-83.16 95.3-111.87 5.11-3.63 10.33-7.1 15.65-10.42 4.77-2.97.4-10.5-4.37-7.53Z"
                fill="#170B24"
            />
            <path
                d="M516.88 219.49C612.12 278 668.01 383.05 661.59 492.34c-8.9 151.38-132.61 230.13-144.07 237.15"
                fill="#fff"
            />
            <path
                d="M514.68 223.25c42.22 26.01 77.84 62.15 102.88 104.98 24.47 41.86 38.54 89.61 40.04 138.1 1.44 46.44-8.61 92.78-29.47 134.32-16.24 32.35-38.38 61.5-64.42 86.59-5.07 4.88-10.28 9.61-15.63 14.18-2.3 1.97-4.63 3.9-6.99 5.8l-3.15 2.52c-.49.38-.97.76-1.46 1.14-.26.2-.52.4-.78.61.92-.71-.23.17-.4.3-5.83 4.45-11.79 8.74-17.98 12.68-.66.42-1.33.84-2 1.25-4.77 2.92-.4 10.46 4.39 7.52 15.38-9.43 29.64-20.97 42.95-33.09 26.37-24.02 49.29-52.14 66.72-83.3 22.88-40.9 35.69-86.85 36.95-133.72 1.35-50.13-10.43-100.23-33.31-144.8-22.98-44.76-57.1-83.45-98.27-112.31-5.12-3.59-10.36-7.02-15.68-10.3-4.78-2.93-9.16 4.6-4.39 7.53Z"
                fill="#170B24"
            />
            <path d="M261.41 482.02c170.68 1.4 341.37 2.79 512.06 4.19Z" fill="#fff" />
            <path
                d="M261.41 486.37c66.77.55 133.55 1.09 200.32 1.64 66.43.54 132.87 1.09 199.3 1.63 37.48.31 74.95.61 112.43.92 5.6.05 5.61-8.66 0-8.71-66.78-.55-133.55-1.09-200.33-1.64-66.43-.54-132.87-1.09-199.3-1.63-37.48-.31-74.95-.61-112.43-.92-5.6-.04-5.6 8.67.01 8.71Z"
                fill="#170B24"
            />
            <path
                d="M513.16 216.94v512.55c0 5.6 8.71 5.61 8.71 0V216.94c0-5.6-8.71-5.61-8.71 0Z"
                fill="#170B24"
            />
            <path
                d="M84.2 201.65c4.49 3.65 11.05 3.59 15.5-.12 13.3-11.08 29.56-15.64 43.98-11.68 23.8 6.55 31.61 33.3 32.03 34.85 8.92 32.45-20.98 76.16-75.15 109.62-3.74 2.31-8.41 2.5-12.33.52C31.99 306.32-.34 264.38 6.21 230.57c2.41-12.42 12.03-33.9 32.85-40.05 14.74-4.35 31.5.03 45.14 11.13Z"
                fill="#fff"
            />
            <path
                d="M82.01 205.41c3.91 2.99 9.09 3.94 13.85 2.81 4.95-1.17 8.5-5.13 12.68-7.77 7.94-5.02 17.13-8.07 26.59-7.6 14.82.74 25.97 11.05 32.48 23.5.56 1.08 1.09 2.17 1.59 3.27.13.28.74 1.7.34.75.22.53.44 1.06.64 1.6.45 1.18.88 2.37 1.23 3.58 1.49 5.1 1.79 9.87 1.3 15.47-1.19 13.58-8.4 27.28-16.56 38.57-10.91 15.09-25.04 27.98-39.99 38.97-4.35 3.2-8.81 6.26-13.35 9.19-3.93 2.53-7.61 5.39-12.39 3.33-4.88-2.1-9.58-5.05-14.15-7.75-4.42-2.61-8.76-5.37-13-8.27-14.47-9.92-28.17-21.82-38.5-36.09-7.87-10.88-14.31-23.89-14.98-36.96-.32-6.13.42-11.01 2.36-16.89 2.11-6.41 5.37-12.72 9.8-17.96 5.19-6.15 11.82-10.81 19.67-12.82 8.83-2.26 18.29-.96 26.6 2.6 4.61 1.97 8.99 4.66 12.89 7.79 1.83 1.47 4.37 1.79 6.16 0 1.52-1.52 1.84-4.68 0-6.16-16.39-13.16-39.23-19.2-58.5-8.39-13.87 7.77-23.35 23.09-26.6 38.43-6.89 32.53 17.84 62.87 41.13 82.23 8.32 6.91 17.19 13.16 26.44 18.75 4.82 2.91 9.74 5.66 14.74 8.23 5.22 2.69 10.65 3.72 16.21 1.29 5.22-2.27 10.08-6.1 14.74-9.35 4.72-3.29 9.33-6.75 13.83-10.35 7.95-6.36 15.49-13.24 22.39-20.73 11.59-12.58 21.88-27.16 27.01-43.64 3.41-10.98 4.3-23.28.34-34.24-4.52-12.51-12.65-24.44-24.41-31.1-8.52-4.83-18.4-6.44-28.06-5.12-11.01 1.5-20.6 6.6-29.27 13.34-3.48 2.7-7.38 2.63-10.87-.03-1.87-1.43-4.87-.29-5.96 1.56-1.3 2.25-.3 4.53 1.58 5.96Z"
                fill="#170B24"
            />
            <path
                d="M551.25 125.72c-15.35.11-30.69.23-46.04.34-7.15 6.14-14.3 12.28-21.44 18.42-1.43 1.23-3.59-.14-3.1-1.96l4.69-17.19c-26.76.28-48.55-21.33-48.55-48.03V52.62c0-26.53 21.5-48.03 48.03-48.03h66.05c26.53 0 48.03 21.5 48.03 48.03v25.07c0 26.39-21.29 47.83-47.67 48.03Z"
                fill="#fff"
            />
            <path
                d="M551.25 121.37c-15.01.11-30.08-.18-45.08.34-2.05.07-3.14.55-4.75 1.9-1.45 1.21-2.86 2.46-4.29 3.68-2.86 2.46-5.72 4.91-8.58 7.37-1.19 1.02-2.38 2.05-3.57 3.07l-2.14 1.84c-.24.2-.48.41-.71.61-1.86 1.19-.94 2.35 2.75 3.49.36-.64.45-1.64.64-2.33.89-3.28 1.79-6.55 2.68-9.83.65-2.4 2.31-6 .93-8.37-1.61-2.76-5.09-2.09-7.8-2.28-2.46-.18-4.88-.6-7.27-1.22a43.884 43.884 0 0 1-12.95-5.66c-7.89-5.07-14.16-12.85-17.31-21.7-2.33-6.53-2.62-13.04-2.62-19.86 0-6.28-.01-12.57 0-18.86.01-8.12 1.84-15.93 5.96-22.98 8.28-14.13 23.2-21.63 39.31-21.63h35.53c9.64 0 19.28-.06 28.92 0 19.08.12 36.38 12.75 41.85 31.13 1.91 6.43 1.83 12.86 1.83 19.48 0 5.95.02 11.89 0 17.84-.05 18.27-11.49 35.3-28.9 41.42-4.69 1.64-9.48 2.47-14.43 2.55-5.6.08-5.61 8.79 0 8.71 18.84-.27 36.56-10.63 45.61-27.25 4.22-7.75 6.37-16.42 6.41-25.24.03-5.02 0-10.04 0-15.06 0-5.06.26-10.23-.3-15.27-1.03-9.33-4.36-18.28-10.02-25.81C582.86 8.03 567.2.27 550.42.24c-20.67-.04-41.34 0-62.01 0-4.28 0-8.49.11-12.73.82-8.99 1.5-17.38 5.59-24.4 11.35-7.13 5.85-12.47 13.68-15.68 22.3-2.4 6.45-3.16 13.22-3.16 20.07V72.8c0 6.22.2 12.35 1.87 18.41 4.87 17.75 19.29 32.06 37.09 36.75 4.53 1.19 9.26 1.75 13.94 1.73-1.03-.43-2.05-.85-3.08-1.28-.37-1.41-.75-2.82-1.12-4.24-1.54 5.64-3.37 11.28-4.63 16.99-.62 2.81.18 5.68 2.78 7.22 4 2.37 7.11-.46 10.04-2.98 6.31-5.42 12.63-10.84 18.94-16.27-1.03.43-2.05.85-3.08 1.28 15.35-.11 30.69-.23 46.04-.34 5.62-.04 5.63-8.75.02-8.7Z"
                fill="#170B24"
            />
            <path d="M482.31 74.77a4.69 4.69 0 1 0 0-9.38 4.69 4.69 0 0 0 0 9.38Z" fill="#fff" />
            <path
                d="M482.65 70.08c0 .22-.02.43-.04.65.05-.39.1-.77.16-1.16-.06.42-.17.82-.34 1.21.15-.35.29-.69.44-1.04-.17.38-.38.73-.63 1.05.23-.29.45-.59.68-.88-.23.29-.49.54-.78.78.29-.23.59-.45.88-.68-.33.25-.68.46-1.05.63.35-.15.69-.29 1.04-.44-.39.16-.79.27-1.21.34.39-.05.77-.1 1.16-.16-.43.05-.86.05-1.29 0 .39.05.77.1 1.16.16a4.64 4.64 0 0 1-1.21-.34c.35.15.69.29 1.04.44-.38-.17-.73-.38-1.05-.63.29.23.59.45.88.68a5.23 5.23 0 0 1-.78-.78c.23.29.45.59.68.88-.25-.33-.46-.68-.63-1.05.15.35.29.69.44 1.04-.16-.39-.27-.79-.34-1.21.05.39.1.77.16 1.16-.05-.43-.05-.86 0-1.29-.05.39-.1.77-.16 1.16.06-.42.17-.82.34-1.21-.15.35-.29.69-.44 1.04.17-.38.38-.73.63-1.05-.23.29-.45.59-.68.88.23-.29.49-.54.78-.78-.29.23-.59.45-.88.68.33-.25.68-.46 1.05-.63-.35.15-.69.29-1.04.44.39-.16.79-.27 1.21-.34-.39.05-.77.1-1.16.16.43-.05.86-.05 1.29 0-.39-.05-.77-.1-1.16-.16.42.06.82.17 1.21.34-.35-.15-.69-.29-1.04-.44.38.17.73.38 1.05.63-.29-.23-.59-.45-.88-.68.29.23.54.49.78.78-.23-.29-.45-.59-.68-.88.25.33.46.68.63 1.05-.15-.35-.29-.69-.44-1.04.16.39.27.79.34 1.21-.05-.39-.1-.77-.16-1.16.02.2.04.42.04.64.02 1.13.47 2.28 1.28 3.08.76.76 1.99 1.32 3.08 1.28 2.33-.1 4.39-1.91 4.35-4.35-.02-1.71-.49-3.55-1.49-4.96-.76-1.06-1.42-1.71-2.45-2.48-.68-.51-1.46-.84-2.27-1.14-.83-.31-1.76-.43-2.64-.46-.87-.04-1.79.09-2.62.32-1.61.45-3.23 1.4-4.3 2.68-.83 1-1.3 1.82-1.79 3.02-.33.82-.43 1.68-.51 2.56-.09.89.06 1.84.26 2.7.19.82.54 1.64.97 2.36.85 1.43 2.16 2.74 3.68 3.44 1.21.56 2.14.8 3.46.96.88.1 1.77-.03 2.64-.19.91-.16 1.82-.56 2.61-1.03.76-.45 1.51-1.03 2.08-1.71 1.48-1.74 2.33-3.78 2.36-6.08.02-1.11-.5-2.3-1.28-3.08-.76-.76-1.99-1.32-3.08-1.28-2.37.11-4.31 1.92-4.34 4.36Z"
                fill="#170B24"
            />
            <path d="M519.27 74.77a4.69 4.69 0 1 0 0-9.38 4.69 4.69 0 0 0 0 9.38Z" fill="#fff" />
            <path
                d="M519.61 70.08c0 .22-.02.43-.04.65.05-.39.1-.77.16-1.16-.06.42-.17.82-.34 1.21.15-.35.29-.69.44-1.04-.17.38-.38.73-.63 1.05.23-.29.45-.59.68-.88-.23.29-.49.54-.78.78.29-.23.59-.45.88-.68-.33.25-.68.46-1.05.63.35-.15.69-.29 1.04-.44-.39.16-.79.27-1.21.34.39-.05.77-.1 1.16-.16-.43.05-.86.05-1.29 0 .39.05.77.1 1.16.16a4.64 4.64 0 0 1-1.21-.34c.35.15.69.29 1.04.44-.38-.17-.73-.38-1.05-.63.29.23.59.45.88.68a5.23 5.23 0 0 1-.78-.78c.23.29.45.59.68.88-.25-.33-.46-.68-.63-1.05.15.35.29.69.44 1.04-.16-.39-.27-.79-.34-1.21.05.39.1.77.16 1.16-.05-.43-.05-.86 0-1.29-.05.39-.1.77-.16 1.16.06-.42.17-.82.34-1.21-.15.35-.29.69-.44 1.04.17-.38.38-.73.63-1.05-.23.29-.45.59-.68.88.23-.29.49-.54.78-.78-.29.23-.59.45-.88.68.33-.25.68-.46 1.05-.63-.35.15-.69.29-1.04.44.39-.16.79-.27 1.21-.34-.39.05-.77.1-1.16.16.43-.05.86-.05 1.29 0-.39-.05-.77-.1-1.16-.16.42.06.82.17 1.21.34-.35-.15-.69-.29-1.04-.44.38.17.73.38 1.05.63-.29-.23-.59-.45-.88-.68.29.23.54.49.78.78-.23-.29-.45-.59-.68-.88.25.33.46.68.63 1.05-.15-.35-.29-.69-.44-1.04.16.39.27.79.34 1.21-.05-.39-.1-.77-.16-1.16.02.2.04.42.04.64.02 1.13.47 2.28 1.28 3.08.76.76 1.99 1.32 3.08 1.28 2.33-.1 4.39-1.91 4.35-4.35-.02-1.71-.49-3.55-1.49-4.96-.76-1.06-1.42-1.71-2.45-2.48-.68-.51-1.46-.84-2.27-1.14-.83-.31-1.76-.43-2.64-.46-.87-.04-1.79.09-2.62.32-1.61.45-3.23 1.4-4.3 2.68-.83 1-1.3 1.82-1.79 3.02-.33.82-.43 1.68-.51 2.56-.09.89.06 1.84.26 2.7.19.82.54 1.64.97 2.36.85 1.43 2.16 2.74 3.68 3.44 1.21.56 2.14.8 3.46.96.88.1 1.77-.03 2.64-.19.91-.16 1.82-.56 2.61-1.03.76-.45 1.51-1.03 2.08-1.71 1.48-1.74 2.33-3.78 2.36-6.08.02-1.11-.5-2.3-1.28-3.08-.76-.76-1.99-1.32-3.08-1.28-2.37.11-4.31 1.92-4.34 4.36Z"
                fill="#170B24"
            />
            <path d="M556.03 74.77a4.69 4.69 0 1 0 0-9.38 4.69 4.69 0 0 0 0 9.38Z" fill="#fff" />
            <path
                d="M556.37 70.08c0 .22-.02.43-.04.65.05-.39.1-.77.16-1.16-.06.42-.17.82-.34 1.21.15-.35.29-.69.44-1.04-.17.38-.38.73-.63 1.05.23-.29.45-.59.68-.88-.23.29-.49.54-.78.78.29-.23.59-.45.88-.68-.33.25-.68.46-1.05.63.35-.15.69-.29 1.04-.44-.39.16-.79.27-1.21.34.39-.05.77-.1 1.16-.16-.43.05-.86.05-1.29 0 .39.05.77.1 1.16.16a4.64 4.64 0 0 1-1.21-.34c.35.15.69.29 1.04.44-.38-.17-.73-.38-1.05-.63.29.23.59.45.88.68a5.23 5.23 0 0 1-.78-.78c.23.29.45.59.68.88-.25-.33-.46-.68-.63-1.05.15.35.29.69.44 1.04-.16-.39-.27-.79-.34-1.21.05.39.1.77.16 1.16-.05-.43-.05-.86 0-1.29-.05.39-.1.77-.16 1.16.06-.42.17-.82.34-1.21-.15.35-.29.69-.44 1.04.17-.38.38-.73.63-1.05-.23.29-.45.59-.68.88.23-.29.49-.54.78-.78-.29.23-.59.45-.88.68.33-.25.68-.46 1.05-.63-.35.15-.69.29-1.04.44.39-.16.79-.27 1.21-.34-.39.05-.77.1-1.16.16.43-.05.86-.05 1.29 0-.39-.05-.77-.1-1.16-.16.42.06.82.17 1.21.34-.35-.15-.69-.29-1.04-.44.38.17.73.38 1.05.63-.29-.23-.59-.45-.88-.68.29.23.54.49.78.78-.23-.29-.45-.59-.68-.88.25.33.46.68.63 1.05-.15-.35-.29-.69-.44-1.04.16.39.27.79.34 1.21-.05-.39-.1-.77-.16-1.16.02.2.03.42.04.64.02 1.13.47 2.28 1.28 3.08.76.76 1.99 1.32 3.08 1.28 2.33-.1 4.39-1.91 4.35-4.35-.02-1.71-.49-3.55-1.49-4.96-.76-1.06-1.42-1.71-2.45-2.48-.68-.51-1.46-.84-2.27-1.14-.83-.31-1.76-.43-2.64-.46-.87-.04-1.79.09-2.62.32-1.61.45-3.23 1.4-4.3 2.68-.83 1-1.3 1.82-1.79 3.02-.33.82-.43 1.68-.51 2.56-.09.89.06 1.84.26 2.7.19.82.54 1.64.97 2.36.85 1.43 2.16 2.74 3.68 3.44 1.21.56 2.14.8 3.46.96.88.1 1.77-.03 2.64-.19.91-.16 1.82-.56 2.61-1.03.76-.45 1.51-1.03 2.08-1.71 1.48-1.74 2.33-3.78 2.36-6.08.02-1.11-.5-2.3-1.28-3.08-.76-.76-1.99-1.32-3.08-1.28-2.37.11-4.31 1.92-4.34 4.36Z"
                fill="#170B24"
            />
            <path
                d="M963.404 253.981c14.032-5.84 20.673-21.948 14.834-35.98-5.84-14.032-21.948-20.673-35.98-14.833-14.031 5.839-20.673 21.948-14.833 35.979 5.839 14.032 21.948 20.673 35.979 14.834Z"
                fill="#fff"
            />
            <path
                d="M974.09 219.13c3.7 9.2 1.87 19.54-5.46 26.42-7.08 6.64-18.06 8.07-26.61 3.47-8.39-4.51-13.39-13.91-12.16-23.42 1.28-9.94 8.97-17.83 18.69-19.82 11.08-2.26 21.63 4.32 25.97 14.4.93 2.16 4.1 2.65 5.96 1.56 2.23-1.31 2.5-3.79 1.56-5.96-5.14-11.93-17.77-19.84-30.78-19.06-13.32.8-24.92 9.79-28.87 22.56-3.96 12.8.91 26.86 11.53 34.86 10.47 7.88 25.51 8.36 36.44 1.07 12.41-8.27 17.73-24.45 12.13-38.39-.88-2.18-2.9-3.71-5.36-3.04-2.07.56-3.92 3.16-3.04 5.35Z"
                fill="#170B24"
            />
            <path
                d="M875.54 354.99c-7.06 2.94-15.17-.4-18.11-7.46l-18.49-44.43c-2.94-7.06.4-15.17 7.46-18.11 7.06-2.94 15.17.4 18.11 7.46L883 336.88c2.94 7.07-.4 15.17-7.46 18.11Z"
                fill="#fff"
            />
            <path
                d="M874.38 350.79c-3.35 1.39-7.11 1.19-10.04-1.1-.54-.42-1.11-.96-1.54-1.5-.41-.51-.69-1.11-1.04-1.66-.65-1.03-.29-.51-.13-.17-.12-.26-.22-.54-.33-.8-1.61-3.83-3.19-7.67-4.79-11.51-4.24-10.18-8.47-20.35-12.71-30.53-.47-1.14-.98-2.27-1.29-3.47-.89-3.48.38-7.34 3.17-9.63.35-.29.75-.55 1.14-.78-.1.06 1.79-.92.95-.54-.83.38 1.15-.4 1.04-.37.41-.12.85-.25 1.27-.33 1.75-.31 3.64-.11 5.29.58 1.54.64 2.95 1.74 3.95 3.07.33.44.56.93.86 1.39.65 1.03.29.51.13.17.04.09.07.18.11.28 4.05 9.12 7.68 18.44 11.51 27.65l6.21 14.91c.65 1.57 1.38 3.16 1.54 4.87.44 4.53-2.37 8.14-6.35 9.91-2.15.95-2.66 4.09-1.56 5.96 1.29 2.21 3.8 2.52 5.96 1.56 8.75-3.89 12.89-14.38 9.38-23.23-1.56-3.93-3.25-7.81-4.87-11.71-4.45-10.68-8.88-21.38-13.34-32.05-3.56-8.52-11.82-13.67-21.13-11.75-10.66 2.21-16.97 14.18-13.03 24.24 3.79 9.67 7.99 19.2 11.98 28.79 2.09 5.02 4.18 10.05 6.27 15.07 1.55 3.72 3.65 6.79 7.01 9.14 4.71 3.29 11.36 4.15 16.69 1.93 2.17-.9 3.72-2.88 3.04-5.36-.56-2.04-3.17-3.94-5.35-3.03Z"
                fill="#170B24"
            />
            <path
                d="m808.97 289.05.21.47c5.92 13.18 20.62 19.94 34.52 16 6.2-1.76 12.66-3.39 19.35-4.87 33.17-7.3 63.84-8.75 90.37-7.41 18.28.92 31.13-17.76 23.88-34.56-10.8-25.04-21.6-50.08-32.41-75.13-7.24-16.79-29.49-20.17-41.5-6.38-18.04 20.73-39.94 42.28-66.37 62.78-6.14 4.76-12.23 9.24-18.26 13.46-11.39 7.98-15.49 22.95-9.79 35.64Z"
                fill="#fff"
            />
            <path
                d="M805.21 291.25c6.32 14.06 21.54 21.76 36.61 19.15 4.37-.75 8.66-2.26 12.97-3.34 4.98-1.25 9.99-2.38 15.03-3.4 9.51-1.92 19.1-3.43 28.75-4.49 17.45-1.91 35.05-2.46 52.59-1.68 7.38.33 14.4-.87 20.46-5.36 5.59-4.14 9.76-10.18 11.32-17 .82-3.57.94-7.17.43-10.79-.58-4.06-2.22-7.65-3.83-11.39-4.07-9.43-8.13-18.85-12.2-28.28-4.07-9.43-8.13-18.85-12.2-28.28-2.1-4.86-4.17-9.73-6.29-14.58-1.38-3.17-3.25-6.06-5.63-8.58-9.16-9.7-24.63-11.72-36.03-4.85-6.59 3.97-11.24 10.78-16.39 16.35a465.704 465.704 0 0 1-18.65 19.06c-13.95 13.48-28.74 26.12-44.29 37.72-7.02 5.24-15.02 9.66-20.08 17.01-4.19 6.08-6.26 13.89-5.59 21.25.36 4.04 1.4 7.75 3.02 11.48.94 2.16 4.1 2.65 5.96 1.56 2.23-1.3 2.5-3.79 1.56-5.96-2.53-5.83-2.56-12.53-.37-18.46 2.55-6.9 7.95-10.7 13.68-14.83a475.613 475.613 0 0 0 42.41-34.39c12.26-11.17 23.91-23 34.92-35.4 4.1-4.62 7.79-8.76 14.01-10.27 4.81-1.17 10.07-.52 14.49 1.89 5.72 3.11 8.29 8.12 10.78 13.89l11.25 26.07c3.81 8.84 7.63 17.68 11.44 26.51 1.91 4.42 3.81 8.84 5.72 13.26 1.48 3.42 3.24 6.85 3.77 10.59 1.55 10.95-6.5 21.64-17.49 23.07-3.52.46-7.23-.07-10.76-.18-3.93-.12-7.86-.18-11.79-.17-8.31.02-16.62.35-24.91.97-18.13 1.37-36.17 4.16-53.85 8.38-7.41 1.77-15.02 5.02-22.75 4.38-6-.5-11.69-3.47-15.77-7.84-2.05-2.19-3.55-4.76-4.77-7.48-.96-2.15-4.08-2.66-5.96-1.56-2.21 1.3-2.53 3.82-1.57 5.97Z"
                fill="#170B24"
            />
            <path
                d="M107.37 618.66H75.29c-8.82 0-16.04-7.22-16.04-16.04s7.22-16.04 16.04-16.04h32.08c8.82 0 16.04 7.22 16.04 16.04s-7.22 16.04-16.04 16.04Z"
                fill="#fff"
            />
            <path
                d="M107.37 614.3H84.33c-3 0-6 .05-9 0-5.84-.1-10.92-4.38-11.67-10.27-.77-6.06 3.56-11.76 9.48-12.92 2.7-.53 5.75-.19 8.48-.19 8.45 0 16.91-.06 25.36 0 5.91.04 11.25 4.13 12.01 10.19.89 7-4.71 13.05-11.62 13.19-5.6.12-5.61 8.82 0 8.71 9.07-.19 17.21-6.14 19.66-14.99 2.39-8.63-1.36-17.88-8.84-22.68-4.47-2.87-9.38-3.13-14.47-3.13H87.51c-4.01 0-8.02-.06-12.03 0-7.89.12-15.37 4.52-18.66 11.82-3.6 7.97-1.88 17.73 4.75 23.63 4.57 4.06 9.99 5.34 15.92 5.34h29.88c5.6.01 5.61-8.7 0-8.7Z"
                fill="#170B24"
            />
            <path
                d="M128.06 731.8H54.6c-25.08 0-45.59-20.52-45.59-45.59v-38c0-25.08 20.52-45.59 45.59-45.59h73.46c25.08 0 45.59 20.52 45.59 45.59v38c0 25.07-20.52 45.59-45.59 45.59Z"
                fill="#fff"
            />
            <path
                d="M128.06 727.44H94.23c-13.03 0-26.06.02-39.08 0-20.62-.04-39.02-15.4-41.5-36.18-.71-5.94-.28-12.15-.28-18.11v-22.68c0-8.59 1.67-16.65 6.26-24.03 5.97-9.59 15.78-16.34 26.82-18.66 4.75-1 9.61-.81 14.43-.81H125.61c6.93 0 13.69.87 20.02 3.94 9.98 4.84 17.96 13.52 21.5 24.09 2.28 6.81 2.18 13.61 2.18 20.66v24.24c0 7.24-.13 14.13-2.72 21.05-3.88 10.34-12.3 18.89-22.42 23.24-5.11 2.18-10.59 3.21-16.11 3.25-5.6.04-5.61 8.75 0 8.71 26.42-.2 48.74-21.43 49.9-47.91.27-6.11.05-12.27.05-18.39 0-7.05.01-14.1 0-21.15-.04-24.08-17.63-45.96-41.75-49.74-5.22-.82-10.4-.7-15.65-.7H97.35c-13.98 0-27.97-.01-41.95 0-23.01.02-43.88 15.56-49.34 38.19-1.72 7.13-1.41 14.42-1.41 21.68v24.6c0 9.21 1.31 17.98 5.56 26.29 5.75 11.24 15.83 19.88 27.64 24.21 6.62 2.43 13.49 2.92 20.46 2.92h69.76c5.59 0 5.6-8.71-.01-8.71Z"
                fill="#170B24"
            />
            <path
                d="M91.33 704.47c20.898 0 37.84-16.942 37.84-37.84 0-20.898-16.942-37.84-37.84-37.84-20.898 0-37.84 16.942-37.84 37.84 0 20.898 16.941 37.84 37.84 37.84Z"
                fill="#fff"
            />
            <path
                d="M124.81 666.63c-.12 13.71-8.66 26.63-21.71 31.37a33.984 33.984 0 0 1-37.59-10.02c-9.1-10.75-10.07-26.75-2.83-38.71 7.17-11.84 21.48-18.3 35.11-15.51 15.62 3.19 26.88 16.95 27.02 32.87.05 5.6 8.76 5.61 8.71 0-.16-17.8-11.37-33.68-28.1-39.77-16.26-5.92-35.51-.65-46.47 12.74-11.25 13.74-13.05 33.46-3.78 48.77 9.21 15.23 27.21 22.99 44.62 19.58 19.52-3.82 33.56-21.65 33.73-41.33.05-5.6-8.66-5.6-8.71.01Z"
                fill="#170B24"
            />
            <path
                d="M559.14 1001.37h-82.55c-25.11 0-45.47-20.36-45.47-45.47v-82.55c0-25.11 20.36-45.47 45.47-45.47h82.55c25.11 0 45.47 20.36 45.47 45.47v82.55c0 25.11-20.36 45.47-45.47 45.47Z"
                fill="#fff"
            />
            <path
                d="M559.14 997.02h-75.93c-7.8 0-15.34-.09-22.72-3.23-13.22-5.63-22.8-18.21-24.7-32.45-.76-5.75-.32-11.83-.32-17.61v-61.22c0-6.75-.34-13.5 1.47-20.08 3.84-13.95 15.06-25.06 29.03-28.8 6.51-1.75 13.25-1.38 19.93-1.38h61.24c5.74 0 11.79-.44 17.51.33 14.23 1.91 26.73 11.51 32.38 24.69 3.17 7.41 3.23 15.01 3.23 22.85v60.66c0 5.05.03 10.09 0 15.14-.09 14.77-8.04 28.51-20.91 35.82-6.19 3.48-13.12 5.22-20.21 5.28-5.6.04-5.61 8.75 0 8.71a50.495 50.495 0 0 0 41.33-22.01c6.29-9.2 8.49-19.79 8.49-30.75v-59.84c0-6.49.02-12.98 0-19.48-.04-14.39-6.16-28.45-17.13-37.88-9.63-8.27-21.51-12.24-34.13-12.24h-80.02c-12.89 0-25.15 4.36-34.74 13.07-10.45 9.49-16.16 23.15-16.19 37.19-.04 18.21 0 36.41 0 54.62v25.02c0 11.22 2.54 22.09 9.22 31.32 9.46 13.06 24.46 20.88 40.62 20.98 5.7.03 11.39 0 17.09 0h65.45c5.61-.01 5.62-8.71.01-8.71Z"
                fill="#170B24"
            />
            <path
                d="M448.31 955.31c12.63-16.1 25.27-32.19 37.9-48.29l19.67 20.15c10.73-15.66 21.47-31.32 32.2-46.98 16.22 25.49 32.43 50.97 48.65 76.46"
                fill="#fff"
            />
            <path
                d="M451.39 958.39c12.63-16.1 25.27-32.19 37.9-48.29h-6.16c6.55 6.72 13.11 13.43 19.67 20.15 2.08 2.13 5.29 1.38 6.84-.88 10.73-15.66 21.47-31.32 32.2-46.98h-7.52c16.22 25.49 32.43 50.97 48.65 76.46 3 4.71 10.54.35 7.52-4.39-16.22-25.49-32.43-50.97-48.65-76.46-1.83-2.88-5.65-2.72-7.52 0-10.73 15.66-21.47 31.32-32.2 46.98 2.28-.29 4.56-.59 6.84-.88-6.55-6.72-13.11-13.43-19.67-20.15-1.5-1.54-4.71-1.84-6.16 0-12.63 16.1-25.27 32.19-37.9 48.29-1.45 1.85-1.8 4.35 0 6.16 1.51 1.5 4.7 1.85 6.16-.01Z"
                fill="#170B24"
            />
            <path
                d="M500.01 898.18c7.064 0 12.79-5.726 12.79-12.79 0-7.064-5.726-12.79-12.79-12.79-7.064 0-12.79 5.726-12.79 12.79 0 7.064 5.726 12.79 12.79 12.79Z"
                fill="#fff"
            />
            <path
                d="M508.45 885.39c0 .36-.03.71-.04 1.07-.04.82-.01.05.03-.11-.11.57-.23 1.12-.4 1.67-.1.33-.22.64-.34.96.06-.15.4-.8.03-.12-.33.59-.66 1.16-1.03 1.72-.55.84.44-.31-.04.04-.21.15-.38.43-.56.62-.21.21-1.5 1.29-.62.64-.45.33-.91.64-1.4.91-.19.11-.4.2-.59.31-.72.42.69-.17-.36.15-.55.17-1.09.34-1.65.46-.26.05-1.05.13-.05.04-.29.03-.59.05-.88.07-.6.02-1.18-.03-1.78-.05-.69-.03 1.03.23-.05-.02-.34-.08-.67-.14-1-.24-.33-.09-.65-.21-.97-.32-.24-.08-.65-.37.1.05-.49-.27-1-.5-1.47-.8-.23-.15-.45-.33-.69-.47-.54-.32.7.68-.03-.05-.47-.47-.92-.96-1.39-1.43.59.59.21.31.07.09-.18-.28-.38-.55-.55-.84-.16-.26-.86-1.8-.46-.77-.21-.53-.39-1.07-.52-1.62-.06-.22-.1-.45-.15-.67-.2-.86.03.76-.05-.4-.04-.53-.04-1.07-.01-1.6.01-.23.23-1.32 0-.24.05-.23.08-.45.13-.68.12-.56.31-1.09.48-1.64.33-1.04-.45.74.1-.2.17-.29.31-.6.48-.88.14-.24.3-.47.45-.7.15-.23.66-.75.02-.05.37-.41.73-.83 1.15-1.21.02-.02.83-.76.32-.31-.5.44.33-.23.37-.25.56-.38 1.15-.68 1.75-.99-.14.07-.86.3-.1.05.32-.11.64-.22.97-.32.22-.06.44-.12.67-.17.04-.01 1.15-.21.45-.1-.7.11.43-.03.47-.03.3-.02.59-.02.89-.02.3 0 .59.03.89.04 1.01.03-.66-.19.23.04.67.17 1.32.34 1.98.55.97.31-.43-.34.05.02.29.23.71.36 1.04.55.19.11.38.23.57.36.18.12.37.25.54.39-.44-.34-.48-.36-.1-.05.39.39.78.79 1.18 1.18.76.76-.45-.74.15.16.18.28.38.55.55.84.17.29.32.59.48.88.44.77-.2-.73.1.2.21.66.38 1.31.55 1.98.06.23.07.82 0-.12-.01.48.03.95.04 1.43.03 2.28 1.98 4.46 4.35 4.35 2.33-.1 4.39-1.91 4.35-4.35-.1-7.29-4.61-13.58-11.4-16.14-6.45-2.43-14.39-.33-18.76 5.01-4.6 5.61-5.51 13.56-1.73 19.89 3.77 6.32 11.04 9.32 18.19 8.04 7.96-1.43 13.6-8.88 13.71-16.79.03-2.28-2.02-4.46-4.35-4.35-2.4.09-4.33 1.9-4.36 4.34Z"
                fill="#170B24"
            />
            <path
                d="M952.17 737.71h-93.13c-29.06 0-52.62-23.56-52.62-52.62v-51.8c0-29.06 23.56-52.62 52.62-52.62h93.13c29.06 0 52.62 23.56 52.62 52.62v51.8c0 29.06-23.56 52.62-52.62 52.62Z"
                fill="#fff"
            />
            <path
                d="M952.17 733.35h-92.09c-10.97 0-21.54-3.17-30.33-9.86-11-8.38-18.1-21.54-18.89-35.36-.39-6.92-.09-13.93-.09-20.86v-29.26c0-2.47-.06-4.96.07-7.43.35-7.03 2.33-14 5.69-20.18 6.68-12.31 18.44-21.2 32.1-24.26 5.96-1.34 12.02-1.12 18.08-1.12h81.79c8.18 0 16.16.8 23.7 4.32 12.73 5.95 22.54 17.13 26.39 30.7 2.22 7.84 1.84 15.86 1.84 23.91v30.67c0 7.99.37 15.94-1.84 23.72-3.86 13.56-13.64 24.75-26.39 30.7-6.25 2.91-13.15 4.27-20.03 4.31-5.6.04-5.61 8.74 0 8.71 15.66-.1 30.9-6.55 41.6-18.05 9.91-10.65 15.31-24.65 15.37-39.17.07-16.79 0-33.58 0-50.37 0-12.97-3.82-25.48-11.77-35.81-9.67-12.56-24.07-20.48-39.82-22.08-5.41-.55-10.97-.27-16.4-.27h-29.52c-17.22 0-34.44-.01-51.66 0-12.18.01-24.2 3.45-34.1 10.67-12.74 9.29-21.23 23.28-23.29 38.94-1 7.6-.51 15.49-.51 23.13v30.86c0 10.63 1.17 20.76 5.92 30.47 6.76 13.81 19.32 24.41 33.94 29.06 7.55 2.4 15.24 2.62 23.06 2.62h87.18c5.6 0 5.61-8.71 0-8.71Z"
                fill="#170B24"
            />
            <path
                d="m935.22 647.99-34.1-21.32c-9.59-6-22.08.64-22.48 11.95l-1.41 40.19c-.4 11.31 11.59 18.81 21.59 13.5l35.51-18.87c9.99-5.32 10.49-19.45.89-25.45Z"
                fill="#fff"
            />
            <path
                d="M937.42 644.23c-10.86-6.79-21.71-13.58-32.57-20.36-2.55-1.6-5.19-3.01-8.21-3.5-5.98-.97-12.04.82-16.47 4.98-5.78 5.42-5.86 12.57-6.11 19.82-.29 8.11-.57 16.23-.86 24.34-.23 6.53-1.23 13.44 2.21 19.37 5.44 9.37 17.01 11.7 26.24 6.84 6-3.16 11.98-6.36 17.96-9.55 5.55-2.95 11.15-5.84 16.67-8.86 9.43-5.15 13.19-17.41 7.45-26.75-1.59-2.6-3.76-4.66-6.31-6.33-4.7-3.07-9.07 4.46-4.39 7.52 6.87 4.49 5.96 14.14-1 17.98-4.64 2.56-9.37 4.98-14.05 7.47l-18.69 9.93c-1.29.68-2.59 1.5-3.97 1.98-3.96 1.4-8.4.18-11.19-2.95-3.55-3.98-2.46-9.76-2.29-14.66.27-7.58.53-15.17.8-22.75.1-2.78.2-5.55.29-8.33.07-1.88.11-3.68.89-5.46 1.62-3.71 5.48-6.15 9.37-6.2 2.55-.03 4.65.98 6.77 2.3 11.02 6.89 22.05 13.78 33.07 20.68 4.76 2.99 9.14-4.54 4.39-7.51Z"
                fill="#170B24"
            />
        </Svg>
    );
}
