import React from 'react';
import {Box} from 'bigdatr-style/layout';
import {Text} from 'bigdatr-style';
import RegisterButton from '../auth/RegisterButton';
import useEventTracking from '../core/useEventTracking';

//
// @intent
// CTA in a box.
// The content here is hard coded, if we need another different text change them to props
export default function CallToActionNotifications(props: {identifier: string}) {
    const {registerCtaClick} = useEventTracking();
    const {identifier} = props;
    return (
        <Box border="outline" p={3}>
            <Text as="div" textStyle="heading3" mb={2}>
                Don&apos;t miss an ad launch.
            </Text>
            <Text as="div" mb={3}>
                Get a round-up of new ads fresh in your inbox each morning.
            </Text>
            <Box onClick={() => registerCtaClick(identifier)}>
                <RegisterButton />
            </Box>
        </Box>
    );
}
