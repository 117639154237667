import React from 'react';
import {Flex} from 'bigdatr-style/layout';
import {Box} from 'bigdatr-style/layout';
import {Text} from 'bigdatr-style';
import Styled from 'styled-components';
import RegisterButton from '../auth/RegisterButton';
import useEventTracking from '../core/useEventTracking';

//
// @intent
// Fade out some content with a call to action
// The content here is hard coded, if we need another different text change them to props

type Props = {
    identifier: string;
    className?: string;
    fade: boolean;
};

export default Styled((props: Props) => {
    const {registerCtaClick} = useEventTracking();
    const {identifier, className} = props;
    return (
        <Flex className={className} alignItems="center" flexDirection="column">
            <Text as="div" textStyle="heading2" mb={2}>
                There’s a lot more where that came from!
            </Text>
            <Text as="div" textStyle="heading4" mb={3}>
                Sign up now to view millions of ads across thousands of brands
            </Text>
            <Box width="auto" onClick={() => registerCtaClick(identifier)}>
                <RegisterButton large />
            </Box>
        </Flex>
    );
})`
    margin-bottom: 5rem;
    background: white;
    padding-top: 4rem;
    ${(props) =>
        props.fade &&
        `
        box-shadow: 0 0 10rem 10rem white;
        position: relative;
        top: -8rem;
    `}
}
`;
