import React from 'react';
import {DeadEnd, Link, StampyInBath} from 'bigdatr-style';

const DeadEndBlogNotFound = () => (
    <DeadEnd
        title="Sorry, we couldn't find this blog"
        stampy={StampyInBath}
        action={() => <Link to="/blog">{'View Insights Blog'}</Link>}
    >
        We searched everywhere but we could not find the blog post you’re looking for. Please check
        the URL address or take a look at our insight blogs.
    </DeadEnd>
);

export default DeadEndBlogNotFound;
