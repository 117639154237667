export type ProductInput = {id?: string; name: string};

export default class Product {
    private data: ProductInput;
    type = 'product' as const;

    constructor(input: ProductInput) {
        this.data = input;
    }

    static unavailableLabel = 'Unavailable';

    get id() {
        return this.data.id || this.data.name;
    }

    get label() {
        return this.data.name;
    }
}
