import {Button} from 'bigdatr-style';
import {Clickable} from 'bigdatr-style';
import React from 'react';

export default function RegisterButton(props: {
    children?: React.ReactNode;
    large?: boolean;
    onClick?: () => void;
}) {
    const registerProps = useRegisterLinkProps();
    const {children = 'Start free trial', large, onClick} = props;
    return <Button large={large} to={registerProps.to} children={children} onClick={onClick} />;
}

export function useRegisterLinkProps(): React.ComponentProps<typeof Clickable> {
    return {to: {pathname: '/register', state: {clearForm: true}}};
}
