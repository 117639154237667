import React from 'react';

import {Clickable} from 'bigdatr-style';

export type MaybeClickableProps = {
    children: React.ReactNode;
    clickableProps?: React.ComponentPropsWithRef<typeof Clickable>;
};
const MaybeClickable = ({clickableProps, children}: MaybeClickableProps) => {
    return clickableProps ? <Clickable {...clickableProps}>{children}</Clickable> : <>{children}</>;
};

export default MaybeClickable;
