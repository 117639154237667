import {useState, useCallback, useEffect, useRef} from 'react';
import {createDraft, Draft, finishDraft} from 'immer';
import {Objectish} from 'immer/dist/internal';

type Tail<T extends any[]> = ((...t: T) => void) extends (h: any, ...r: infer R) => void
    ? R
    : never;

/**
useActionState lets you define a series of action functions to update a
state object. This allows you to provide a small interface for the state
and provide some context to what certain actions do.
*/
export default function useActionState<
    T extends Objectish,
    ActionConfig extends Record<
        string,
        (draft: Draft<T>, ...args: any[]) => (T | void) | Promise<T | void>
    >
>(initialState: T, actionConfig: ActionConfig, dependencies: any[] = []) {
    const [state, setState] = useState<T>(initialState);
    const [dirty, setDirty] = useState(false);
    const refState = useRef<T>(state);

    type Actions = {
        [K in keyof ActionConfig]: ReturnType<ActionConfig[K]> extends Promise<T>
            ? (...args: Tail<Parameters<ActionConfig[K]>>) => Promise<T>
            : (...args: Tail<Parameters<ActionConfig[K]>>) => T;
    };

    useEffect(() => {
        setDirty(false);
        setState(initialState);
    }, dependencies);

    const actions = {} as unknown as Actions;

    const finalize = (draft: Draft<T>) => {
        const next = finishDraft(draft) as T;
        refState.current = next;
        setState(next);
        return next;
    };

    for (const key in actionConfig) {
        const action = (...args) => {
            const draft = createDraft(refState.current);
            if (!dirty) setDirty(true);
            const producerResult = actionConfig[key](draft, ...args);
            if (producerResult instanceof Promise) {
                // async producer
                return producerResult.then(() => {
                    return finalize(draft);
                });
            } else {
                // synchronous producer
                return finalize(draft);
            }
        };
        actions[key] = action as Actions[string];
    }

    const reset = useCallback(() => {
        setDirty(false);
        setState(initialState);
    }, dependencies);

    return [state, actions, {reset, dirty}] as const;
}
