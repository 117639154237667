import React from 'react';
import {DeadEnd} from '../index';
import {Text} from '../index';
import {Link} from '../index';
import {Paper} from '../index';
import {useAnalyticsUnsafe} from '../index';
import {useErrorBoundaryContext} from './ErrorBoundary';

type Props = {
    cutout?: boolean;
    errorObject?: Error;
    showStampy?: boolean;
};

export default function ErrorHandler(props: Props) {
    const {errorObject} = props;
    const {cutout = false} = props;
    const {showStampy = true} = props;
    const stampy = showStampy ? undefined : () => null;
    const analytics = useAnalyticsUnsafe();
    const {showAllErrorMessages = false} = useErrorBoundaryContext() ?? {};

    let title = 'Sorry, something went wrong';
    let child = (
        <Text>
            <Text>Try refreshing the page and if the problem persists feel free to </Text>
            <Link onClick={() => analytics?.chat()}>send us a message</Link>
        </Text>
    );

    if ((process.env.NODE_ENV === 'development' || showAllErrorMessages) && errorObject) {
        const {name, stack, message} = errorObject;
        title = name?.replace(/_/g, ' ') || title;
        if (stack) child = <Text>{stack.split('\n').slice(0, 2).join(' ')}</Text>;
        if (message) child = <Text>{message}</Text>;
    }

    const content = (
        <DeadEnd title={title} stampy={stampy}>
            {child}
        </DeadEnd>
    );

    return cutout ? <Paper cutout={true}>{content}</Paper> : content;
}
