import styled from 'styled-components';
import Clickable from './Clickable';

type Props = {
    active?: boolean;
    small?: boolean;
    large?: boolean;
    tight?: boolean;
    rounded?: boolean;
};

export default styled(Clickable)<Props>((props) => {
    const {rounded, active, small, large, tight} = props;
    const {colors} = props.theme;

    const activeStyle = `
        outline: none;
        color: ${colors.brand};
    `;

    return `
        display: block;
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 1em;
        line-height: 1.5;
        ${rounded ? 'border-radius: .25rem;' : ''}

        &:hover {
            background-color: ${colors.backgroundHover};
        }

        ${tight ? `padding: 0;` : ''}

        ${large ? `padding: 1rem;` : ''}

        ${small ? `padding: .25rem 1rem;` : ''}
        
        ${active ? activeStyle : ''}
        &.active,
        &:active,
        &:focus {
            ${activeStyle}
        }
    `;
});
