import React from 'react';
import {Brand, Skeleton, Text, EntityAvatarBrand, ToggleItem, Clickable} from 'bigdatr-style';
import {Box, Flex, AspectRatio} from 'bigdatr-style/layout';
import CallToActionFadeOut from '../affordance/CallToActionFadeOut';
import CallToActionNotifications from '../affordance/CallToActionNotifications';
import usePageData from '../core/usePageData';
import DeadEndBrandNotFound from '../affordance/DeadEndBrandNotFound';
import BrandSeo from './BrandSeo';
import BrandCreativeHero from './BrandCreativeHero';
import BrandCreativeList from './BrandCreativeList';
import BrandDiscoverBrandList from './BrandDiscoverBrandList';
import {format} from 'stringdate';
import ClientWebCreative from '../ClientWebCreative';
import {ClientWebBrand} from '../ClientWebBrand';

export type BrandTemplateProps = {
    match: {url: string};
};

export type BrandMessage = {
    clientWebData: {
        brandCreatives: {
            id: string;
            brand: ClientWebBrand;
            discoverBrands: Array<ClientWebBrand>;
            heroCreative?: ClientWebCreative;
            thumbCreatives: Array<ClientWebCreative>;
            availableYears: Array<string>;
        };
    };
};

export default function BrandTemplateMoreCta(props: BrandTemplateProps) {
    const {url} = props.match;
    const message = usePageData<BrandMessage>({url});

    if (message.isEmpty) return null;
    if (message.isFetching || message.isRefetching) return <SkeletonLayout />;
    if (message.isError) {
        if (message.requestError?.statusCode === 404) {
            return <DeadEndBrandNotFound />;
        }
        throw message.requestError;
    }

    const data = message.response.clientWebData.brandCreatives;
    const brandKey = data.brand.key;

    // Truncate creatives to multiples of three & max out at 12
    const limit = Math.min(12, Math.max(3, 3 * Math.floor((data.thumbCreatives.length || 0) / 3)));

    return (
        <Box>
            <Box pt={{_: 4, lg: 5}} pb={4}>
                <BrandSeo brand={data.brand} imageUrl={data.heroCreative?.croppedThumbnail} />
                <Flex alignItems="center" mb={2}>
                    <Box>
                        <EntityAvatarBrand
                            entity={new Brand({name: data.brand.name})}
                            width="2rem"
                        />
                    </Box>
                    <Box ml={3}>
                        <Text as="h1" textStyle={{_: 'heading3', sm: 'heading2', md: 'heading1'}}>
                            {data.brand.name} ads
                        </Text>
                    </Box>
                </Flex>
                <Text>
                    Discover a wide range of {data.brand.name} ads across digital, video, and social
                    media.
                </Text>
                <br />
                <Text>
                    You can set up personalised ad alerts when {data.brand.name} or their
                    competitors launch a new campaign to market.
                </Text>
                {data.availableYears.length > 0 && (
                    <Flex alignItems="center" mt={3}>
                        <Clickable pr={2} to={`/au/brand/${brandKey}`}>
                            <ToggleItem active={true} separate>
                                Recent
                            </ToggleItem>
                        </Clickable>
                        {data.availableYears.map((date: string) => {
                            const year = format('yyyy')(date);
                            return (
                                <Clickable pr={2} key={year} to={`/au/brand/${brandKey}/${year}`}>
                                    <ToggleItem separate active={false} children={year} />
                                </Clickable>
                            );
                        })}
                    </Flex>
                )}
            </Box>
            <Box display={{md: 'flex'}}>
                <Box width={{md: '70%'}}>
                    {data.heroCreative && <BrandCreativeHero creative={data.heroCreative} />}
                    <BrandCreativeList creatives={data.thumbCreatives} limit={limit} />
                    <CallToActionFadeOut
                        fade={limit === 12}
                        identifier={'brandPageCreativeListOverlay'}
                    />
                </Box>
                <Box width={{md: '30%'}} pl={{md: 4}} zIndex={1}>
                    <BrandDiscoverBrandList brands={data.discoverBrands} />
                    <Box mt={4}>
                        <CallToActionNotifications identifier={'brandPageRightBox'} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

function SkeletonLayout() {
    return (
        <Box>
            <Box pt={{_: 4, lg: 5}} pb={4}>
                <Skeleton mb={2} width="40%" height="2.5rem" />
                <Skeleton mb={2} width="60%" height="1rem" />
                <Skeleton width="60%" height="1rem" />
            </Box>
            <Box display={{md: 'flex'}}>
                <Box width={{md: '70%'}}>
                    <Box mb={3}>
                        <AspectRatio ratio={16 / 9}>
                            <Skeleton height="100%" />
                        </AspectRatio>
                    </Box>
                    <Skeleton width="40%" height="1.5rem" mb={2} />
                    <Skeleton width="24%" mb={4} />
                    <Flex flexWrap="wrap" mr="-1rem">
                        {[...new Array(12)].map((_, key: number) => (
                            <Box key={key} width={{_: 1 / 2, lg: 1 / 3}} pr="1rem" mb="1.5rem">
                                <AspectRatio ratio={16 / 9}>
                                    <Skeleton height="100%" />
                                </AspectRatio>
                                <Skeleton mt={2} />
                                <Skeleton my={2} />
                            </Box>
                        ))}
                    </Flex>
                </Box>
                <Box width={{md: '30%'}} pl={{md: 4}} style={{zIndex: 1}}>
                    <Skeleton mb={4} height="30rem" />
                    <Skeleton height="10rem" />
                </Box>
            </Box>
        </Box>
    );
}
