import React from 'react';
import {Absolute, Relative} from '../../layout';
import {DefaultTheme} from 'styled-components';

type Color = keyof DefaultTheme['colors'];
type Props = {
    onScrub?: () => void;
    backgroundColor?: Color;
    height?: string | number;
    borderRadius?: string;
    bars: Array<{
        value: number;
        color: Color;
        testId?: string;
    }>;
};

export default function ProgressBar(props: Props) {
    const {onScrub, bars, backgroundColor = 'background1', height = '.3rem', borderRadius} = props;

    return (
        <Relative
            onMouseDown={onScrub}
            height={height}
            backgroundColor={backgroundColor}
            width="100%"
            borderRadius={borderRadius}
            children={bars.map((bar, key) => (
                <Absolute
                    data-testid={bar.testId}
                    key={key}
                    width={`${bar.value * 100}%`}
                    backgroundColor={bar.color || 'brand'}
                    borderRadius={borderRadius}
                    height="100%"
                />
            ))}
        />
    );
}
