import React, {ReactNode} from 'react';
import {ComponentType} from 'react';
import {Route} from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';

type Props = {
    public?: boolean;
    path?: string;
    exact?: boolean;
    resetOnUrl?: boolean;
    component?: ComponentType<any>;
    children?: ReactNode;
    render?: <P>(props: P) => ReactNode;
};

export default function ErrorBoundaryRoute(props: Props) {
    const {component: Component, render, path, resetOnUrl} = props;

    // Checks based on react-router:
    // https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/Route.js
    if (Component) {
        return (
            <Route
                {...props}
                key={path}
                component={undefined}
                render={(pp) => (
                    <ErrorBoundary {...pp} resetKeys={resetOnUrl ? [pp.match.url] : undefined}>
                        <Component {...pp} />
                    </ErrorBoundary>
                )}
            />
        );
    }

    if (render) {
        return (
            <Route
                {...props}
                key={path}
                render={(pp) => (
                    <ErrorBoundary {...pp} resetKeys={resetOnUrl ? [pp.match.url] : undefined}>
                        {render(pp)}
                    </ErrorBoundary>
                )}
            />
        );
    }

    return <Route key={path} {...props} />;
}
