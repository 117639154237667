import React from 'react';
import {Flex, Box, FlexColumn, Grid, FlexCenter} from 'bigdatr-style/layout';
import {Button, Link, MediaPlayer, Text} from 'bigdatr-style';
import SeoTemplate from '../affordance/SeoTemplate';
import BrandLogos from '../affordance/BrandLogos';
import RegisterButton from '../auth/RegisterButton';
import useEventTracking from '../core/useEventTracking';
import Brands from '../img/home/Brands';
import Creatives from '../img/home/Creatives';
import Industries from '../img/home/Industries';
import MediaChannels from '../img/home/MediaChannels';
import AdvertisingCreative from '../img/home/AdvertisingCreative';
import MediaValue from '../img/home/MediaValue';
import Reports from '../img/home/Reports';
import dashboardLarge from '../img/home/dashboardLarge.jpeg';
import dashboardMedium from '../img/home/dashboardMedium.jpeg';
import appPreview from './appPreview.mp4';
import {advertisingCreativeDescription, mediaValueDescription} from '../pages/compare-plans';

export default () => {
    const {registerCtaClick} = useEventTracking();
    const seoTitle = 'Bigdatr: A beautiful way to track advertising campaigns';
    const seoDescription =
        'Get personalised notifications when new ads launch from the brands you choose to monitor.';

    return (
        <FlexColumn gap={{_: 6, lg: 7}} py={5}>
            <SeoTemplate title={seoTitle} description={seoDescription} slug={''} />

            <Hero analytics={registerCtaClick} />
            <Features />
            <AnalyseLandscape />
            <BrandLogos />
            <DataSources />
            <GetStarted analytics={registerCtaClick} />
        </FlexColumn>
    );
};

type AnalyticsProp = {analytics: (id: string) => void};

const largeText = 'heading0';
const mediumText = 'heading2';

function Hero(props: AnalyticsProp) {
    return (
        <FlexColumn gap={3} alignItems="center" textAlign="center">
            <Text textStyle={{_: mediumText, sm: largeText}} as="h1">
                Insights you won&apos;t find anywhere else.
            </Text>
            <Text textStyle="heading4" as="p" mb={3}>
                Helping Marketing professionals advertise with confidence
                <br /> by understanding the marketplace first.
            </Text>
            <RegisterButton large onClick={() => props.analytics('homePage')} />

            {/* @intent - the dashboard preview is a static image, which shrinks too much on
                smaller screens and becomes unreadable. This dodgy css makes the dashboard
                screenshot look a bit nicer. The images are displayed as a background image of a Box,
                because we can change the image url easily thanks to styled-system */}
            <Box
                mt={4}
                aria-hidden="true"
                width="100%"
                // These are just the width of the screenshots
                aspectRatio={{_: '1920 / 1001', xl: '1920 / 738'}}
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                backgroundImage={{
                    _: `url('${dashboardMedium}')`,
                    xl: `url('${dashboardLarge}')`
                }}
                title="Bigdatr dashboard preview"
            />

            <Text mt={4}>
                <Link to="/register">Create an account</Link> to see more brands.
            </Text>
        </FlexColumn>
    );
}

const featureCards = [
    {
        title: 'Advertising Creative',
        description: advertisingCreativeDescription,
        svg: <AdvertisingCreative />
    },
    {
        title: 'Media Value',
        description: mediaValueDescription,
        svg: <MediaValue />
    },
    {
        title: 'Report Builder',
        description:
            'Get shareable facts and figures fast so you can spread the knowledge and get quick buy-in from your stakeholders.',
        svg: <Reports />
    }
];
function Features() {
    return (
        <FlexColumn gap={5} textAlign="center" alignItems="center">
            <Text as="h2" textStyle={{_: mediumText, sm: largeText}} maxWidth="65rem">
                A competitor intelligence tool you will love, making it easy to track your
                competitor&apos;s advertising in real time
            </Text>

            <Flex gap={{_: 3, lg: 4}} flexDirection={{_: 'column', md: 'row'}}>
                {featureCards.map((ff, ii) => (
                    <FeatureCard
                        key={ii}
                        title={ff.title}
                        description={ff.description}
                        children={ff.svg}
                    />
                ))}
            </Flex>
        </FlexColumn>
    );
}

function FeatureCard(props: {title: string; description: string; children: React.ReactNode}) {
    return (
        <Grid
            gap={3}
            gridTemplateRows="auto"
            border="outline"
            borderRadius=".5rem"
            p={4}
            justifyItems="center"
            maxWidth="25rem"
        >
            <Text as="h3" textStyle="heading2">
                {props.title}
            </Text>
            <Box mt={2} mb={3} width="100%">
                {props.children}
            </Box>
            <Text as="p" textStyle="heading4">
                {props.description}
            </Text>
        </Grid>
    );
}

function AnalyseLandscape() {
    return (
        <FlexColumn textAlign="center" gap={3} alignItems="center">
            <Text textStyle={{_: mediumText, sm: largeText}} as="h2">
                Analyse your competitive landscape
            </Text>
            <Text textStyle="heading4" as="p" maxWidth="60rem">
                Your personalised dashboard will highlight the most recent activity so you can get a
                quick overview of what&apos;s happening in market.
            </Text>

            <FlexCenter mt={4}>
                <MediaPlayer
                    type="video"
                    src={appPreview}
                    autoPlay={false}
                    showAudioControl={false}
                    autoPlayWhenInViewport
                    muted
                />
            </FlexCenter>

            <Box mt={4}>
                <Button large to="/compare-plans">
                    Compare plans
                </Button>
            </Box>
        </FlexColumn>
    );
}

const stats: Array<React.ComponentProps<typeof StatisticCard>> = [
    {svg: <Creatives />, quantity: '6.5M+', noun: 'Creatives'},
    {svg: <Brands />, quantity: '35,000+', noun: 'Brands'},
    {svg: <Industries />, quantity: '70+', noun: 'Industries'},
    {svg: <MediaChannels />, quantity: '11', noun: 'Media Channels'}
];
function DataSources() {
    return (
        <FlexColumn gap={5}>
            <Text
                as="h3"
                textStyle="heading4"
                fontSize={{sm: 4, md: 5}}
                lineHeight={1}
                textAlign="center"
                maxWidth="60rem"
                mx="auto"
            >
                We&apos;ve spent the last 10 years sourcing data from various media channels
                including digital that helps brands across multiple industries make the right
                decisions.
            </Text>
            <Flex gap="4rem" justifyContent="space-evenly" flexWrap="wrap">
                {stats.map((ss, ii) => (
                    <StatisticCard key={ii} {...ss} />
                ))}
            </Flex>
        </FlexColumn>
    );
}
function StatisticCard(props: {svg: React.ReactNode; quantity: string; noun: string}) {
    return (
        <Grid
            gridTemplateRows="150px auto auto"
            justifyContent="center"
            justifyItems="center"
            textAlign="center"
        >
            <Box maxWidth="150px" maxHeight="150px" width="100%">
                {props.svg}
            </Box>
            <Text as="p" textStyle="heading0" mt={{_: 2, md: 3}} mb={2}>
                {props.quantity}
            </Text>
            <Text as="p" textStyle="heading4">
                {props.noun}
            </Text>
        </Grid>
    );
}

const getStarted: Array<React.ComponentProps<typeof GetStartedCard>> = [
    {
        number: 1,
        title: 'Create free account',
        description: 'Get started with a free trial and invite your team members!'
    },
    {
        number: 2,
        title: "Follow your competitor's brands",
        description:
            'Your dashboard will highlight the most recent marketing activity from the brands you follow.'
    },
    {
        number: 3,
        title: 'Get daily campaign alerts & ad trends',
        description:
            'Receive tailored campaign notifications, movers & shakers and create custom reports.'
    }
];
function GetStarted(props: AnalyticsProp) {
    return (
        <FlexColumn gap={5}>
            <Text textStyle="heading0" textAlign="center" mb={4}>
                Get started in 2 minutes
            </Text>

            <Grid gridTemplateColumns={{md: 'repeat(3, 1fr)'}} gap={4}>
                {getStarted.map((gg, ii) => (
                    <GetStartedCard
                        key={ii}
                        number={gg.number}
                        title={gg.title}
                        description={gg.description}
                    />
                ))}
            </Grid>

            <Box alignSelf="center">
                <RegisterButton large onClick={() => props.analytics('homePage:getStarted')} />
            </Box>
        </FlexColumn>
    );
}
function GetStartedCard(props: {number: number; title: string; description: string}) {
    return (
        <Grid
            gridTemplateRows="6rem auto 6rem"
            gap={3}
            maxWidth="25rem"
            mx={{_: 'auto', md: 'unset'}}
            textAlign={{_: 'center', md: 'left'}}
        >
            <Text textStyle="heading0" textAlign="center" fontSize="6rem" as="p">
                {props.number}
            </Text>
            <Text textStyle={{_: 'heading1', md: 'heading2', lg: 'heading1'}} as="h3">
                {props.title}
            </Text>
            <Text textStyle="heading4" as="p">
                {props.description}
            </Text>
        </Grid>
    );
}
