import styled from 'styled-components';
import React from 'react';
import {IconChevronDown} from '../../icon';
import {Box} from '../../layout';
import Text from './Text';

const OpenIndicator = styled(IconChevronDown)`
    transition: transform 0.2s;
    transform: rotate(-90deg);
    width: 1.25rem;
    height: 1.25rem;
`;

const Summary = styled.summary`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 0.25rem;
    user-select: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;

    &::marker {
        display: none;
    }
`;

const Details = styled.details`
    border: ${(p) => p.theme.borders.outline};
    border-radius: 0.5rem;

    &[open] {
        ${OpenIndicator} {
            transform: rotate(0deg);
        }

        ${Summary} {
            padding-bottom: 0.5rem;

            & ~ * {
                animation: fadeIn 0.3s ease-in-out forwards;
            }
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
            transform: translateY(-10px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

type AccordionProps = {
    title: React.ReactNode;
    children: React.ReactNode;
    openByDefault?: boolean;
};

export default function Accordion(props: AccordionProps) {
    const {title, children, openByDefault} = props;

    return (
        <Details open={openByDefault}>
            <Summary>
                {typeof title === 'string' ? <Text textStyle="heading4">{title}</Text> : title}
                <OpenIndicator />
            </Summary>
            <Box px={3} pb={2}>
                {children}
            </Box>
        </Details>
    );
}
