import React from 'react';
import {Brand, Text} from 'bigdatr-style';
import {Box, Flex} from 'bigdatr-style/layout';
import {BigDatrUrl, BigDatrLogoOG} from '../core/urls';
import CallToActionFadeOut from '../affordance/CallToActionFadeOut';
import CallToActionNotifications from '../affordance/CallToActionNotifications';
import usePageData from '../core/usePageData';
import usePageRoute from '../core/usePageRoute';
import {Clickable} from 'bigdatr-style';
import {AspectRatio} from 'bigdatr-style/layout';
import {Skeleton} from 'bigdatr-style';
import {Image} from 'bigdatr-style';
import {EntityAvatarBrand} from 'bigdatr-style';
import {Seo} from '../affordance/SeoTemplate';
import DeadEndCollectionNotFound from '../affordance/DeadEndCollectionNotFound';
import CollectionFeaturedBrandList from './CollectionFeaturedBrandList';
import ClientWebCreative from '../ClientWebCreative';
import {ClientWebBrand} from '../ClientWebBrand';

const host = BigDatrUrl;

type CollectionItem = {
    id: string;
    name: string;
    description: string;
    query: string;
    limit?: number;
};

export type CollectionMessage = {
    clientWebData: {
        collectionCreatives: {
            collection: CollectionItem;
            creatives: Array<ClientWebCreative>;
            featuredBrands: Array<ClientWebBrand>;
        };
    };
};

type CollectionTemplateProps = {
    match: {url: string};
};

export default function CollectionTemplate(props: CollectionTemplateProps) {
    const {url} = props.match;
    const message = usePageData<CollectionMessage>({url});

    if (message.isEmpty) return null;
    if (message.isFetching || message.isRefetching) return <SkeletonLayout />;
    if (message.isError) {
        if (message.requestError.statusCode === 404) return <DeadEndCollectionNotFound />;
        throw message.requestError;
    }

    const {collection, creatives, featuredBrands} =
        message.response.clientWebData.collectionCreatives;
    const {id, description, name} = collection;

    // Truncate creatives to multiples of three & max out at 12
    const limit = Math.min(12, Math.max(3, 3 * Math.floor((creatives.length || 0) / 3)));
    const truncatedCreatives = creatives.slice(0, limit);

    return (
        <Box key={id}>
            <Seo
                title={name}
                titleTemplate="%s - Bigdatr"
                description={description}
                canonical={`${host}/au/collection/${id}`}
                openGraph={{
                    type: 'website',
                    url: `${host}/au/collection/${id}`,
                    title: `${name} - Bigdatr`,
                    description,
                    images: [
                        {
                            url: BigDatrLogoOG
                        }
                    ]
                }}
                twitter={{
                    cardType: 'summary_large_image'
                }}
            />
            <Box pt={{_: 4, lg: 5}} pb={4}>
                <Text as="h1" textStyle={{_: 'heading3', sm: 'heading2', md: 'heading1'}}>
                    {name}
                </Text>
                <Text>{description}</Text>
            </Box>
            <Box display={{md: 'flex'}}>
                <Box width={{md: '70%'}}>
                    <Flex flexWrap="wrap" mr="-1rem">
                        {truncatedCreatives.map((creative, key) => (
                            <CreativeItem creative={creative} key={key} />
                        ))}
                    </Flex>
                    <CallToActionFadeOut
                        fade={limit === 12}
                        identifier={'collectionPageCreativeListOverlay'}
                    />
                </Box>
                <Box width={{md: '30%'}} pl={{md: 4}} style={{zIndex: 1}}>
                    <CollectionFeaturedBrandList brands={featuredBrands} />
                    <Box mt={4}>
                        <CallToActionNotifications identifier={'collectionPageRightBox'} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

function CreativeItem({creative}: {creative: ClientWebCreative}) {
    const brand = creative.brandList?.[0];
    const {to: creativeTo, preload: creativePreload} = usePageRoute(`/au/ad/${creative.id}`) || {};
    const {to: brandTo, preload: brandPreload} = usePageRoute(undefined) || {};

    return (
        <Box width={{_: 1 / 2, lg: 1 / 3}} pr="1rem" mb="1.5rem">
            <Clickable to={creativeTo} preload={creativePreload}>
                <AspectRatio ratio={16 / 9}>
                    <Image
                        alt="Creative Preview"
                        width="100%"
                        src={creative.croppedThumbnail}
                        sourceSets={[{src: creative.croppedThumbnailWebp, type: 'image/webp'}]}
                        onError={() => null}
                        onLoading={() => <Skeleton width="100%" height="100%" />}
                        loading="lazy"
                    />
                </AspectRatio>
            </Clickable>
            {brand && (
                <Clickable to={brandTo} preload={brandPreload}>
                    <Flex alignItems="center">
                        <Flex alignItems="center">
                            <EntityAvatarBrand
                                entity={new Brand({name: brand.name})}
                                width="1rem"
                            />
                            <Box ml={2}>{brand.name}</Box>
                        </Flex>
                        <Box ml="auto">
                            <Text color="muted" dateFormat="MMM dd yyyy">
                                {creative.firstAppeared}
                            </Text>
                        </Box>
                    </Flex>
                </Clickable>
            )}
        </Box>
    );
}

function SkeletonLayout() {
    return (
        <Box>
            <Box pt={{_: 4, lg: 5}} pb={4}>
                <Skeleton mb={2} width="40%" height="2.5rem" />
                <Skeleton width="60%" height="1rem" />
            </Box>
            <Box display={{md: 'flex'}}>
                <Box width={{md: '70%'}}>
                    <Flex flexWrap="wrap" mr="-1rem">
                        {[...new Array(12)].map((_, key: number) => (
                            <Box key={key} width={{_: 1 / 2, lg: 1 / 3}} pr="1rem" mb="1.5rem">
                                <AspectRatio ratio={16 / 9}>
                                    <Skeleton height="100%" />
                                </AspectRatio>
                                <Skeleton mt={2} />
                                <Skeleton my={2} />
                            </Box>
                        ))}
                    </Flex>
                </Box>
                <Box width={{md: '30%'}} pl={{md: 4}} style={{zIndex: 1}}>
                    <Skeleton mb={4} height="24rem" />
                    <Skeleton height="10rem" />
                </Box>
            </Box>
        </Box>
    );
}
