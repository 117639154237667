import React, {useMemo} from 'react';
import {Switch} from 'react-router-dom';
import useStyles from 'isomorphic-style-loader/useStyles';
import {DeadEnd404, ErrorBoundary, ErrorBoundaryRoute, ErrorHandler} from 'bigdatr-style';
import {Wrapper, Flex, Box, Grid, Fixed} from 'bigdatr-style/layout';
import BaseStyles from 'bigdatr-style/src/core/BaseStyles.css';
import routes from './routes';
import Navigation from './affordance/Navigation';
import Footer from './affordance/Footer';
import {Seo as DefaultSeo} from './affordance/SeoTemplate';
import {BigDatrUrl} from './core/urls';

export const APP_PADDING = 3;

export default function App({error}: {error?: Error}) {
    useStyles(BaseStyles);

    const clientRoutes = useMemo(
        () =>
            routes().map((route) => {
                return <ErrorBoundaryRoute key={route.path} {...route} />;
            }),
        []
    );

    return (
        <ErrorBoundary>
            <DefaultSeo
                openGraph={{
                    type: 'website',
                    locale: 'en_US',
                    url: `${BigDatrUrl}/`,
                    site_name: 'Bigdatr'
                }}
            />
            <Flex flexDirection="column" minHeight="100vh">
                <Grid flexGrow={1} gridTemplateRows="64px auto">
                    {/* Need a static child for the nav, otherwise the main website content 
                        can get cutoff by the fixed nav */}
                    <Box>
                        <Fixed left={0} right={0} top={0} zIndex={1} backgroundColor="background">
                            <Navigation />
                        </Fixed>
                    </Box>
                    <Wrapper p={APP_PADDING} height="100%" width="100%">
                        {error ? (
                            <ErrorHandler errorObject={error} />
                        ) : (
                            <Switch>
                                {clientRoutes}

                                {/* Catch Alls */}
                                <DeadEnd404 />
                            </Switch>
                        )}
                    </Wrapper>
                </Grid>
                <Footer />
            </Flex>
        </ErrorBoundary>
    );
}
