// stolen from https://usehooks-ts.com/react-hook/use-intersection-observer
import {RefObject, useEffect, useState} from 'react';

interface Args extends IntersectionObserverInit {
    freezeOnceVisible?: boolean;
    enabled?: boolean;
}

export default function useIntersectionObserver(
    elementRef: RefObject<Element>,
    {threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false, enabled}: Args
) {
    const [entry, setEntry] = useState<IntersectionObserverEntry>();

    const frozen = entry?.isIntersecting && freezeOnceVisible;

    const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
        setEntry(entry);
    };

    useEffect(() => {
        const node = elementRef?.current;
        const hasIOSupport = !!window.IntersectionObserver;

        if (!hasIOSupport || frozen || !node) return;

        const observerParams = {threshold, root, rootMargin};
        const observer = new IntersectionObserver(updateEntry, observerParams);

        if (enabled) observer.observe(node);

        return () => {
            if (enabled) observer.disconnect();
        };
    }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen]);

    return entry;
}
