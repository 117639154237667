import Industry, {IndustryInput} from '../../industry/data/Industry';

export type CategoryInput = {id?: string; name: string; industry?: IndustryInput | Industry | null};

const industryCategorySentinel = '||||';
export default class Category {
    private data: CategoryInput;
    type = 'category' as const;

    constructor(input: CategoryInput) {
        this.data = input;
    }

    static to(id: string) {
        return `/category/${id}/creative`;
    }

    get id() {
        return this.data.id || this.data.name;
    }

    /** Category name only */
    get name() {
        return this.data.name;
    }

    /** Category name (industry name) */
    get label() {
        return `${this.name}${this.industry ? ` (${this.industry.label})` : ''}`;
    }

    get industry() {
        if (!this.data.industry) return;
        if (this.data.industry instanceof Industry) return this.data.industry;
        return new Industry(this.data.industry);
    }

    get trackingType() {
        return {type: 'category', name: `${this.industry?.label} - ${this.name}`};
    }

    get color() {
        return undefined;
    }

    get to() {
        return Category.to(this.id);
    }

    // still need the below two things because media value filters still use the old data structure
    /** @deprecated */
    static fromIndustryCategoryId(id: string): Category {
        if (id.indexOf(industryCategorySentinel) === -1) return new Category({name: id});

        const [industryName, name] = id.split(industryCategorySentinel) as [string, string];
        return new Category({
            name,
            industry: new Industry({name: industryName})
        });
    }

    /** @deprecated */
    get industryCategoryId() {
        return `${this.industry?.id}${industryCategorySentinel}${this.id}`;
    }
}
