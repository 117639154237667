import React, {useEffect} from 'react';
import Clickable, {ClickableProps} from './Clickable';
import Spinner from './Spinner';
import {Absolute, Flex} from '../../layout';
import Styled from 'styled-components';
import {bool} from '../core/themes/themeGetters';
import {space} from 'styled-system';
import {layout} from 'styled-system';
import {IconCheck} from '../../icon';
import useStateWithDeps from '../util/useStateWithDeps';

type Props = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'> &
    ClickableProps & {
        backgroundColor?: string;
        danger?: boolean;
        download?: boolean;
        large?: boolean;
        small?: boolean;
        loading?: boolean;
        success?: boolean;
        onClick?: Function;
        secondary?: boolean;
        tertiary?: boolean;
        flash?: boolean;
        thin?: boolean;
        type?: string;
        icon?: React.ReactNode;
    };

export default Styled(function Button(props: Props) {
    const [showSuccess, setShowSuccess] = useStateWithDeps(props.success ?? false, [props.success]);

    useEffect(() => {
        if (showSuccess) setTimeout(() => setShowSuccess(false), 2000);
    }, [showSuccess]);

    const content = (
        <Flex gap={2} alignItems="center">
            {props.icon}
            {props.children}
        </Flex>
    );

    if (props.loading || showSuccess) {
        return (
            <Clickable {...props} disabled={true}>
                <div style={{opacity: 0}}>{content}</div>
                <Absolute>
                    {props.loading && <Spinner />}
                    {showSuccess && <IconCheck />}
                </Absolute>
            </Clickable>
        );
    }

    return <Clickable {...props}>{content}</Clickable>;
})`
    background-color: ${(p) => p.theme.colors.brand};
    border: 2px solid;
    border-color: ${(p) => p.theme.colors.brand};
    border-radius: 10rem;
    box-sizing: border-box;
    color: ${(p) => p.theme.colors.white};
    cursor: pointer;
    font-family: inherit;
    line-height: 1;
    height: 2rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
    min-width: 2rem;

    &:hover {
        opacity: .7;
        outline: none;
    }

    &:focus {
        outline: none;
    }

    ${bool<Props>('danger')((p) => {
        return `
            background-color: ${p.theme.colors.red};
            border-color: ${p.theme.colors.red};
        `;
    })}

    ${bool('large')(() => {
        return `
            padding: 0 1.5rem;
            height: 3rem;
        `;
    })}

    ${bool('small')(() => {
        return `
            padding: 0 1.2rem;
            height: 1.7rem;
            font-size: 12px;
        `;
    })}


    ${bool('thin')(() => {
        return `padding: 0;`;
    })}

    ${bool<Props>('secondary')((p) => {
        return `
            color: ${p.theme.colors.foreground};
            border: 2px solid ${p.theme.colors.background2};
            background-color: ${p.theme.colors.background};
            &:hover {
                opacity: 1;
                color: ${p.theme.colors.brand};
                border-color: ${p.theme.colors.brand};
            }
        `;
    })}

    ${bool<Props>('tertiary')((p) => {
        return `
            color: ${p.theme.colors.foreground};
            border: 2px solid transparent;
            background-color: transparent;
            &:hover {
                opacity: 1;
                background-color: ${p.theme.colors.background1};
                border-color: ${p.theme.colors.background1};
            }
        `;
    })}

    ${bool<Props>('disabled')((p) => {
        const {foreground2, foreground3, background1} = p.theme.colors;
        const color = p.secondary || p.tertiary ? foreground3 : foreground2;
        const background = p.secondary || p.tertiary ? 'transparent' : background1;
        const border = p.tertiary ? 'transparent' : background1;

        return `
            cursor: default;
            color: ${color};
            background-color: ${background};
            border: 2px solid ${border};
            opacity: 1;
            &:hover {
                color: ${color};
                border-color: ${border};
                opacity: 1;
                box-shadow: none;
            }
        `;
    })}


    ${bool<Props>('flash')((p) => {
        const blankStyle = `
            border: 2px solid ${p.tertiary ? 'transparent' : p.theme.colors.outline};
            box-shadow: 0 0 1rem transparent;
        `;
        const flashStyle = `
            border: 2px solid ${p.theme.colors.brand};
            box-shadow: 0 0 1rem ${p.theme.colors.purple700};
        `;
        const animationName = `ButtonFlash${p.tertiary ? 'Tertiary' : ''}`;
        return `
            animation-name: ${animationName};
            animation-duration: 2.5s;
            animation-delay: .5s;

            @keyframes ${animationName} {
                0% {${blankStyle}}
                25% {${flashStyle}}
                50% {${blankStyle}}
                75% {${flashStyle}}
                100% {${blankStyle}}
            }
        `;
    })}


    ${space}
    ${layout}
`;
