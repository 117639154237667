
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import MdxLayout from '../affordance/MdxLayout';
import SeoTemplate from '../affordance/SeoTemplate';
import {Grid,Box} from 'bigdatr-style/layout';
import {Text, Link} from 'bigdatr-style';
export const frontmatter = {
  "title": "Book a demo",
  "description": "Discover how Bigdatr's competitor tools will drive your advertising success, with no commitment. Expert insights await—book your slot now",
  "slug": "book-demo",
  "date": "2024-02-19"
};

const layoutProps = {
  frontmatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <MdxLayout maxWidth='80rem' verticalMargin={4} height="100%" mdxType="MdxLayout">
      <SeoTemplate title={frontmatter.title} description={frontmatter.description} slug={frontmatter.slug} mdxType="SeoTemplate" />
      <Grid gap={{
        lg: 3
      }} gridTemplateColumns={{
        lg: '4fr 8fr'
      }} height="100%" mdxType="Grid">
        <Box pt={{
          lg: '7rem'
        }} mdxType="Box">
          <h1>{`Look inside Bigdatr`}</h1>
          <h4>{`Get a glimpse of the market in near-real time.`}</h4>
          <p>{`By booking a personalised demo with a Platform Expert, you'll learn about Bigdatr's competitor tooling that will transform your team's efficiency and insights.`}</p>
          <p>{`With no commitment or obligations, fill out the form with your preferred time and our friendly team will be in touch soon.`}</p>
          <Text textStyle="disclaimer" mdxType="Text">By clicking "Book" you agree to our <Link target="blank" href="https://bigdatr.com/terms-of-use" children="Terms of Use" mdxType="Link" />, <Link target="blank" href="https://bigdatr.com/privacy-policy" children="Privacy Policy" mdxType="Link" />, <Link target="blank" href="https://bigdatr.com/licenseagreement" children="Licencing Agreement" mdxType="Link" />, and recognise our team will be in contact with you regarding your account, enquiries or other purposes.</Text>
        </Box>
        <Box minHeight={{
          _: '75rem',
          md: "45rem"
        }} mdxType="Box">
          <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3OqOtWEBvlb0nlS36NfWGiY3Qy9IUPEfIBEFLMMJf9CrVsFzLoq4aV0L2KmIplqosPgzz8BwJ-?gv=true" height="100%" width="100%" frameBorder="0"></iframe>
        </Box>
      </Grid>
    </MdxLayout>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;