import React from 'react';

import {Image, Skeleton, Text} from 'bigdatr-style';
import {AspectRatio, Box, Flex} from 'bigdatr-style/layout';

import {BigDatrLogo} from '../core/urls';
import usePageRoute from '../core/usePageRoute';
import MaybeClickable, {MaybeClickableProps} from '../affordance/MaybeClickable';
import {BlogPostItem} from './BlogListTemplate';

type LayoutProps = {
    image: React.ReactNode;
    title: React.ReactNode;
    description: React.ReactNode;
    details: React.ReactNode;
    clickableProps?: MaybeClickableProps['clickableProps'];
};
const Layout = ({clickableProps, image, title, description, details}: LayoutProps) => {
    return (
        <Box mb={4}>
            <MaybeClickable {...{clickableProps}}>
                <Flex width="100%" flexDirection={{_: 'column', lg: 'row'}}>
                    <Box flexShrink={0} width={{_: '100%', lg: '256px'}}>
                        <AspectRatio ratio={16 / 9}>{image}</AspectRatio>
                    </Box>
                    <Box ml={{_: '0rem', lg: '1rem'}} mt={{_: '1rem', lg: '0rem'}} width="100%">
                        {title}
                        {description}
                        {details}
                    </Box>
                </Flex>
            </MaybeClickable>
        </Box>
    );
};

const SkeletonLayout = () => (
    <Layout
        image={<Skeleton width="100%" height="100%" />}
        title={<Skeleton width="20rem" height="2rem" />}
        description={
            <>
                <Skeleton width="100%" my={1} height="1.2rem" />
                <Skeleton width="90%" my={1} height="1.2rem" />
            </>
        }
        details={<Skeleton width="15rem" />}
    />
);

const ItemLayout = ({post}: {post: BlogPostItem}) => {
    const clickableProps = usePageRoute(`/blog/${post.slug}`);

    return (
        <Layout
            {...{clickableProps}}
            image={
                <Image
                    alt={post.heroImageAlt || `Article thumbnail image`}
                    width="100%"
                    height="100%"
                    style={{objectFit: 'cover'}}
                    src={post.heroImage || BigDatrLogo}
                    onLoading={() => <Skeleton width="100%" height="100%" />}
                    onError={() => (
                        <Image
                            alt="Hero Image"
                            height="100%"
                            width="100%"
                            src={BigDatrLogo}
                            onError={() => null}
                        />
                    )}
                />
            }
            title={
                <Text as="h3" textStyle="heading3">
                    {post.title}
                </Text>
            }
            description={<Text>{post.description}</Text>}
            details={
                <Box color="muted">
                    <Text dateFormat="MMM dd yyyy">{post.date}</Text>
                    {post.author && (
                        <>
                            <Text> • </Text>
                            <Text>{post.author}</Text>
                        </>
                    )}
                </Box>
            }
        />
    );
};

const BlogListItem = (props: {post: BlogPostItem; skeleton?: false} | {skeleton: true}) => {
    if ('skeleton' in props && props.skeleton) return <SkeletonLayout />;

    return <ItemLayout post={props.post} />;
};

export default BlogListItem;
