import React from 'react';
import {Flex, FlexColumn} from 'bigdatr-style/layout';
import {Text} from 'bigdatr-style';
import bmw from '../img/logos/bmw.png';
import bupa from '../img/logos/bupa.png';
import chep from '../img/logos/chep.png';
import hcf from '../img/logos/hcf.png';
import ooh from '../img/logos/ooh.png';
import hesta from '../img/logos/hesta.png';
import hyundai from '../img/logos/hyundai.png';
import medibank from '../img/logos/medibank.png';
import mitsubishi from '../img/logos/mitsubishi.png';
import optus from '../img/logos/optus.png';
import reagroup from '../img/logos/reagroup.png';
import subaru from '../img/logos/subaru.png';
import swinburne from '../img/logos/swinburne.png';
import toyota from '../img/logos/toyota.png';
import vanguard from '../img/logos/vanguard.png';
import yum from '../img/logos/yum.png';

export default function BrandLogos(props: {headingStyle?: string; subtitle?: boolean}) {
    return (
        <FlexColumn alignItems="center" gap={4}>
            <FlexColumn gap={3} alignItems="center" textAlign="center" mb={4}>
                <Text as="h2" textStyle={{_: 'heading2', sm: 'heading0'}}>
                    Grow your business with Bigdatr
                </Text>
                <Text textStyle="heading4">
                    Our competitor intelligence tool is loved and trusted by global brands
                </Text>
            </FlexColumn>
            {/* Have to have some specific heights here to make the logos fit nicely */}
            <Flex alignItems="center" justifyContent="center" flexWrap="wrap" gap={4}>
                <img height="60px" alt="Bmw logo" loading="lazy" src={bmw} />
                <img height="50px" alt="Bupa logo" loading="lazy" src={bupa} />
                <img height="30px" alt="Chep logo" loading="lazy" src={chep} />
                <img height="40px" alt="HCF logo" loading="lazy" src={hcf} />
                <img height="40px" alt="oOh! logo" loading="lazy" src={ooh} />
                <img height="40px" alt="Hesta logo" loading="lazy" src={hesta} />
                <img height="30px" alt="Hyundai logo" loading="lazy" src={hyundai} />
                <img height="50px" alt="Medibank logo" loading="lazy" src={medibank} />
                <img height="70px" alt="Mitsubishi logo" loading="lazy" src={mitsubishi} />
                <img height="30px" alt="Optus logo" loading="lazy" src={optus} />
                <img height="40px" alt="Rea Group logo" loading="lazy" src={reagroup} />
                <img height="60px" alt="Subaru logo" loading="lazy" src={subaru} />
                <img
                    height="70px"
                    alt="Swinburne University of Technology logo"
                    loading="lazy"
                    src={swinburne}
                />
                <img height="60px" alt="Toyota logo" loading="lazy" src={toyota} />
                <img height="60px" alt="Vanguard logo" loading="lazy" src={vanguard} />
                <img height="60px" alt="Yum logo" loading="lazy" src={yum} />
            </Flex>
        </FlexColumn>
    );
}
