import {post} from '../../core/EntityApi';
import {RegisterFormData} from './RegisterData';

async function graphql(query: string, variables: Record<string, any>) {
    const data = await post('/api', {variables, query});
    if (data.errors) {
        const error = data.errors[0];
        switch (error?.extensions?.code) {
            case 'USERNAME_ALREADY_EXISTS':
            case 'USER_REGISTRATION_VALIDATION_ERROR':
                throw new Error(error.message);

            case 'INTERNAL_SERVER_ERROR':
            default:
                throw new Error(JSON.stringify(data));
        }
    }
    return data;
}

export default async function registerSubmit({
    firstName,
    lastName,
    email,
    jobTitle,
    company,
    industryGroup,
    registrationToken,
    timeZone,
    utm
}: RegisterFormData) {
    return graphql(
        `
            mutation ($input: RegisterSubmitInput!) {
                auth {
                    registerSubmit(input: $input)
                }
            }
        `,
        {
            input: {
                firstName,
                lastName,
                email,
                jobTitle,
                company,
                industryGroup,
                registrationToken,
                timeZone,
                ...(utm && {utm})
            }
        }
    );
}
