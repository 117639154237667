import React from 'react';
import {Box} from 'bigdatr-style/layout';
import {Flex} from 'bigdatr-style/layout';
import {Relative} from 'bigdatr-style/layout';
import {Text} from 'bigdatr-style';
import {IconMail} from 'bigdatr-style/icon';

export default function VerifyEmail() {
    return (
        <Flex display={'block'} maxWidth={'38rem'} alignItems="center" mx="auto" mt="16vh" mb="8vh">
            <Box>
                <Flex alignItems="center">
                    <Text as="h1" mb={3} mt={2} textStyle="heading1" children="Check your email" />
                    <Relative ml={3} top="-.25rem">
                        <IconMail size="3rem" />
                    </Relative>
                </Flex>
                <Text as="div" textStyle="heading4">
                    You&apos;re almost there! To complete your registration click the verification
                    link sent to your provided email.
                </Text>
                <Box mt={3}>
                    Please check your spam folder if the email doesn&apos;t appear within a few
                    minutes.
                </Box>
            </Box>
        </Flex>
    );
}
