import React from 'react';
import {DeadEnd, StampyInBath} from 'bigdatr-style';

const DeadEndBrandNotFound = () => (
    <DeadEnd title="Sorry, we couldn't find this brand" stampy={StampyInBath}>
        We searched everywhere but we could not find the brand you’re looking for. Please check the
        URL address and give it another whirl.
    </DeadEnd>
);

export default DeadEndBrandNotFound;
