import React, {useState} from 'react';
import EntityAvatarAuto from './EntityAvatarAuto';
import {Image, Brand} from '../index';
import {Flex} from '../../layout';
import {useTheme} from '../core/ThemeContext';

type Props = {
    entity: Brand;
    width: string;
};
type LogoImageType = 'initialImage' | 'fallbackImage' | 'fallbackAuto';
const logoImageTypeCache: Map<string, LogoImageType> = new Map();

export default function EntityAvatarBrand(props: Props) {
    const {entity, width} = props;
    const {isDark} = useTheme();
    const {label, color} = entity;
    const style = {
        width,
        height: width,
        borderRadius: width
    };

    const [imageType, setImageType] = useState<LogoImageType>(
        logoImageTypeCache.get(label) || 'initialImage'
    );

    // Brand auto icons
    const fallbackAuto = <EntityAvatarAuto name={label} color={color} style={style} />;

    const initialImage = (
        <Image
            src={entity.logoUrl}
            alt={label}
            style={{width: '100%'}}
            onError={() => {
                logoImageTypeCache.set(label, 'fallbackAuto');
                setImageType('fallbackAuto');
                return fallbackAuto;
            }}
        />
    );

    const imageTypes = {initialImage, fallbackAuto};
    const image = imageTypes[imageType] ?? initialImage;

    const darkStyles =
        isDark && imageType !== 'fallbackAuto'
            ? {
                  backgroundColor: 'white',
                  padding: 2
              }
            : {};

    return (
        <Flex {...style} {...darkStyles} alignItems="center" data-testid="BrandLogo">
            {image}
        </Flex>
    );
}
