import React, {useState} from 'react';
import {Flex, Box, FlexColumn, Wrapper} from 'bigdatr-style/layout';
import {Clickable, styled} from 'bigdatr-style';
import {Button} from 'bigdatr-style';
import RegisterButton from '../auth/RegisterButton';
import {BigDatrAppSignInUrl, BigDatrLogo} from '../core/urls';
import {Link, useLocation} from 'react-router-dom';
import useEventTracking from '../core/useEventTracking';
import {IconMenu} from 'bigdatr-style/icon';
import {APP_PADDING} from '../App';

export default function Navigation() {
    const {registerCtaClick} = useEventTracking();
    const location = useLocation();

    const [showNav, setShowNav] = useState(false);

    const closeNav = () => setShowNav(false);
    const links = (
        <>
            <Link to="/compare-plans" children="Compare plans" onClick={closeNav} />
            <Link to="/contact-us" children="Contact us" onClick={closeNav} />
            <Link to="/book-demo" children="Book a demo" onClick={closeNav} />
            <Button secondary href={BigDatrAppSignInUrl} children="Log in" onClick={closeNav} />
        </>
    );
    return (
        <Box>
            <Wrapper p={APP_PADDING}>
                <Flex alignItems="center" gap={3}>
                    <Box mr="auto">
                        <Clickable to="/" onClick={closeNav}>
                            <img
                                alt="Bigdatr Logo"
                                src={BigDatrLogo}
                                height="32px"
                                style={{display: 'block'}}
                            />
                        </Clickable>
                    </Box>
                    <Flex gap={3} alignItems="center">
                        <Flex gap={3} display={{_: 'none', sm: 'flex'}} alignItems="center">
                            {links}
                        </Flex>
                        {location.pathname !== '/register' ? (
                            <RegisterButton
                                onClick={() => {
                                    registerCtaClick('navigationBar');
                                    closeNav();
                                }}
                            />
                        ) : null}
                    </Flex>
                    <Clickable
                        display={{sm: 'none'}}
                        aria-label="Open navigation"
                        onClick={() => setShowNav((p) => !p)}
                    >
                        <IconMenu size="2rem" />
                    </Clickable>
                </Flex>
            </Wrapper>
            <MobileNav className={showNav ? 'open' : 'closed'}>
                <FlexColumn p={3} pt={0} gap={3} alignItems="center">
                    {links}
                </FlexColumn>
            </MobileNav>
        </Box>
    );
}

const MobileNav = styled.div`
    transition: height 0.3s;
    overflow: hidden;

    @media (min-width: ${(p) => p.theme.breakpoints.sm}) {
        display: none;
    }

    &.open {
        height: 11rem; // rendered height of an opened nav
        box-shadow: 0 8px 10px -9px ${(p) => p.theme.colors.muted};
    }

    &.closed {
        height: 0;
    }
`;
