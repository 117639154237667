import React from 'react';
import {BigDatrUrl, BigDatrLogoOG} from '../core/urls';
import usePageData from '../core/usePageData';
import MdxLayout from '../affordance/MdxLayout';
import MdxFromAst from '../affordance/MdxFromAst';
import DeadEndBlogNotFound from '../affordance/DeadEndBlogNotFound';
import {Seo} from '../affordance/SeoTemplate';
import {Text} from 'bigdatr-style';
import {Link} from 'bigdatr-style';
import {Loader} from 'bigdatr-style';
import {Box} from 'bigdatr-style/layout';
import {Flex} from 'bigdatr-style/layout';

export default function BlogItemTemplate(props: {match: {url: string}}) {
    const {url} = props.match;
    const message = usePageData({url});

    if (message.isEmpty) return null;
    if (message.isError) {
        if (message.requestError?.statusCode === 404) {
            return <DeadEndBlogNotFound />;
        }
        throw message.requestError;
    }
    if (message.isFetching || message.isRefetching) return <Loader />;

    const {frontmatter, ast} = message.get('blogItem');
    const {
        title,
        description,
        heroImage,
        date,
        author,
        heroImageAlt,
        slug,
        showHeroImageOnPage = true
    } = frontmatter;
    return (
        <MdxLayout maxWidth="42rem" fontSize="18px" nofollow={true}>
            <Seo
                title={title}
                titleTemplate="%s - Bigdatr"
                description={description}
                canonical={`${BigDatrUrl}/blog/${slug}`}
                openGraph={{
                    type: 'website',
                    url: `${BigDatrUrl}/blog/${slug}`,
                    title: `${title} - Bigdatr`,
                    description: description,
                    images: [
                        {
                            url: heroImage || BigDatrLogoOG
                        }
                    ]
                }}
                twitter={{
                    cardType: 'summary_large_image'
                }}
            />
            <Box my={5}>
                <Text as="h1" textStyle="heading0">
                    {title}
                </Text>
                <Box mt={3}>
                    <Text as="strong" textStyle="strong">
                        {author}
                    </Text>
                </Box>
                <Text dateFormat="dd MMM yyyy">{date}</Text>
                <Box mt={4}>
                    <Text textStyle="heading4">{description}</Text>
                </Box>
            </Box>
            {showHeroImageOnPage && (
                <img
                    src={heroImage}
                    alt={heroImageAlt}
                    style={{margin: 'auto', maxWidth: '100%', display: 'block'}}
                ></img>
            )}
            <MdxFromAst {...ast} />
            <Flex my={4} justifyContent="center">
                <Link to="/blog">View more articles</Link>
            </Flex>
        </MdxLayout>
    );
}
