import {createGlobalStyle} from 'styled-components';
import {ThemeType} from 'bigdatr-style';

const HubspotFormStyles = createGlobalStyle<{theme: ThemeType}>`
${(p) => {
    const {colors, fontWeights, fontSizes} = p.theme;
    return `
    .hs-input {
        /* Styles copied from Input.tsx */
        -webkit-appearance: none;
        border-radius: 1rem;
        border: none;
        box-shadow: inset 0 0 0 2px ${colors.outline};
        box-sizing: border-box;
        color: ${colors.foreground};
        background-color: ${colors.background};
        cursor: text;
        display: block;
        font-family: inherit;
        font-size: 1rem;
        font-weight: ${fontWeights.regular};
        min-height: 2rem;
        padding: .25rem .75rem;
        line-height: 1.5;
        width: 100%;

        &:-ms-input-placeholder {
            color: ${colors.muted};
        }

        &::placeholder {
            color: ${colors.muted};
        }

        &:-webkit-autofill {
            -webkit-text-fill-color: ${colors.foreground} !important;
        }

        &:focus {
            box-shadow: inset 0 0 0 2px ${colors.brand};
            outline: none;
        }
    }
    textarea.hs-input {
        height: 4rem;
    }
    .hs-submit {
        margin: 2rem 0;
    }
    .hs-form-field {
        margin-bottom: 1rem;
    }
    .hs-form-field > label {
        font-weight: ${fontWeights.bold};
    }
    .hs-error-msgs {
        color: ${colors.red};
        margin-bottom: 1rem;
        font-size: ${fontSizes[1]}px;
    }
    .hs-button {
        /* Copied from Button.tsx */
        background-color: ${colors.purple};
        border: 2px solid;
        border-color: ${colors.purple};
        border-radius: 10rem;
        box-sizing: border-box;
        color: ${colors.white};
        cursor: pointer;
        font-family: inherit;
        font-size: .9rem;
        font-weight: ${fontWeights.bold};
        line-height: 1;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        white-space: nowrap;
        height: 3rem;
        width: 100%;
        &:hover {
            opacity: .7;
            outline: none;
        }
        &:focus {
            outline: none;
        }
    }
    `;
}}
`;
export default HubspotFormStyles;
