import {formatDate, formatDateRange} from 'bigdatr-style';
import {Media} from 'bigdatr-style';
import {formatDistanceStrict} from 'date-fns';
import addDays from 'date-fns/addDays';

export type CreativeMedium = 'image' | 'video' | 'audio';

export type CreativeRaw = Partial<{
    brandList: Array<{name: string; key?: string}>;
    campaignId?: string;
    creative: string;
    campaignName: string | null;
    firstAppeared: string;
    categoryList: Array<{name: string; industry?: string}>;
    mediaTypeList: Array<{name: string}>;
    publicationList: Array<{name: string}>;
    lastAppeared: string;
    isCampaignPreview?: boolean;
}> & {id: string};

const formatDurationAsDays = (from: Date, to: Date): string => {
    return formatDistanceStrict(from, addDays(to, 1), {unit: 'day'});
};

export default class ClientWebCreative extends Media<ClientWebCreative> {
    readonly brandList: Array<{name: string; key?: string}>;
    readonly campaignId?: string;
    readonly categoryList: Array<{name: string; industry?: string}>;
    readonly mediaTypeList: Array<{name: string}>;
    readonly publicationList: Array<{name: string}>;
    readonly _firstAppeared?: string;
    readonly _lastAppeared?: string;

    constructor(input: CreativeRaw) {
        const {id, creative = 'MISSING_CREATIVE'} = input;
        super({id, creative});

        this.brandList = input.brandList ?? [];
        this.campaignId = input.campaignId;
        this.categoryList = input.categoryList ?? [];
        this.mediaTypeList = input.mediaTypeList ?? [];
        this.publicationList = input.publicationList ?? [];
        this._firstAppeared = input.firstAppeared;
        this._lastAppeared = input.lastAppeared;
    }

    static downloadUrl(list: Array<string>): string {
        const endpoint = process.env.BIGDATR_ENTITY_API_ENDPOINT;
        return `${endpoint}/creativeDownload?creativeList=${list.join(',')}`;
    }

    get isRadio(): boolean {
        if (this.mediaTypeList.length) {
            // @todo this is here because the activity inbox creates a faux creative out of a campaign
            // Those campagins can have multiple media types and we only want to say it's radio if all of them are.
            return this.mediaTypeList?.every((i) => i.name.toLowerCase().includes('radio'));
        } else {
            return this.creative.endsWith('.mp3');
        }
    }

    get schedule() {
        const format = formatDate('MMM d yyyy');
        if (!this.firstAppeared && this.lastAppeared) {
            return `Unknown Date - ${format(this.lastAppeared)}`;
        }
        if (!this.lastAppeared && this.firstAppeared) {
            return `${format(this.firstAppeared)} - Unknown Date`;
        }
        if (this.firstAppeared && this.lastAppeared) {
            const formattedRange = formatDateRange(this.firstAppeared, this.lastAppeared, false);
            const days = formatDurationAsDays(
                this.firstAppeared,
                new Date(Math.min(+new Date(), +this.lastAppeared))
            );
            return `${formattedRange} (${days.replace(' ', '\xa0')})`;
        }
        return 'Unknown Schedule';
    }

    get croppedThumbnail(): string {
        return this.mediaTypeList.some((mm) => mm.name === 'Digital Social') // We don't want to tile social creatives
            ? this.thumbnailUrl('w512c16_9t1', 'jpg')
            : this.thumbnailUrl('w512c16_9', 'jpg');
    }

    get croppedThumbnailWebp(): string {
        return this.mediaTypeList.some((mm) => mm.name === 'Digital Social') // We don't want to tile social creatives
            ? this.thumbnailUrl('w512c16_9t1', 'webp')
            : this.thumbnailUrl('w512c16_9', 'webp');
    }

    get to() {
        return `/creative/${this.id}`;
    }

    get publicLink() {
        return `${process.env.BIGDATR_CLIENT_MAIN_HOST}creative/${this.id}`;
    }

    get firstAppeared(): Date | undefined {
        return this._firstAppeared ? new Date(this._firstAppeared) : undefined;
    }
    get lastAppeared(): Date | undefined {
        return this._lastAppeared ? new Date(this._lastAppeared) : undefined;
    }
}
