import React from 'react';
import {DeadEnd, StampyInBath} from 'bigdatr-style';

const DeadEndAdNotFound = () => (
    <DeadEnd title="Sorry, we couldn't find this ad" stampy={StampyInBath}>
        We searched everywhere but we could not find the ad you’re looking for. Please check the URL
        address and give it another whirl.
    </DeadEnd>
);

export default DeadEndAdNotFound;
