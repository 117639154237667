import React from 'react';
import {DeadEnd, StampyInBath} from 'bigdatr-style';

const DeadEndCollectionNotFound = () => (
    <DeadEnd title="Sorry, we couldn't find this collection" stampy={StampyInBath}>
        We searched everywhere but we could not find the collection you’re looking for. Please check
        the URL address and give it another whirl.
    </DeadEnd>
);

export default DeadEndCollectionNotFound;
