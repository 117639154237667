import React, {StrictMode} from 'react';
import {hydrateRoot} from 'react-dom/client';
import App from '../common/App';
import ProviderFactory from '../common/core/ProviderFactory';
const Providers = ProviderFactory({target: 'client'});

hydrateRoot(
    document.getElementById('app')!,
    <StrictMode>
        <Providers>
            <App />
        </Providers>
    </StrictMode>
);

module.hot?.accept();
