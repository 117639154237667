import React from 'react';
import {Box} from '../../layout';
import hash from '../util/hash';
import {chunk} from '../util/array';
import {Flex} from '../../layout';

export default function EntityAvatarAuto({
    name,
    color,
    style
}: {
    name: string;
    style: Record<string, any>;
    color?: string;
}) {
    const hex = parseInt(hash(name)).toString(2).slice(0, 16);
    const binaries = hex.split('').map((ii) => Boolean(parseInt(ii, 2)));

    const borderRadius = (items) => {
        const [a, b, c, d] = items;
        const check = (x) => (x ? '10rem' : '0rem');
        return {
            borderTopLeftRadius: check(a),
            borderTopRightRadius: check(b),
            borderBottomRightRadius: check(c),
            borderBottomLeftRadius: check(d)
        };
    };

    return (
        <Flex flexWrap="wrap" {...style}>
            {chunk(4, binaries).map((ii, key) => {
                return (
                    <Box
                        key={key}
                        width="50%"
                        height="50%"
                        {...borderRadius(ii)}
                        backgroundColor={color || 'background3'}
                    />
                );
            })}
        </Flex>
    );
}
