import {StyledProps, Absolute, Relative} from '../../layout';
import React, {InputHTMLAttributes, useId, useMemo} from 'react';
import Styled from 'styled-components';
import debounce from '../util/debounce';
import {IconSearch, IconCross} from '../../icon';
import Spinner from './Spinner';
import Clickable from './Clickable';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    value?: any;
    isError?: boolean;
    isIcon?: boolean;
    /** Set row number of a textarea, if `as="textarea"` is also passed in */
    rows?: string;
    style?: Record<string, any>;
    debounce?: number;
    onDebounce?: (next: any) => void;
    /**
    To debounce a callback you need to memoise it between renders.
    If the onDebounce callback is changing due to props you might find that it's become stale
    */
    debounceDeps?: React.DependencyList;
}

const StyledInput = Styled.input<StyledProps & InputProps & {as: 'textarea' | 'input'}>`${(p) => {
    const {colors, fontWeights} = p.theme;
    return `
        -webkit-appearance: none;
        border-radius: 1rem;
        border: none;
        box-shadow: inset 0 0 0 2px ${colors.outline};
        box-sizing: border-box;
        color: ${colors.foreground};
        background-color: ${colors.background};
        cursor: text;
        display: block;
        font-family: inherit;
        font-size: inherit;
        font-weight: ${fontWeights.regular};
        min-height: 2rem;
        padding: .25rem .75rem;
        line-height: 1.5;
        width: 100%;
        height: ${p.height};

        &:-ms-input-placeholder {
            color: ${colors.muted};
        }

        &::placeholder {
            color: ${colors.muted};
        }

        &:-webkit-autofill {
            -webkit-text-fill-color: ${colors.foreground} !important;
        }


        &:focus {
            box-shadow: inset 0 0 0 2px ${colors.brand};
            outline: none;
        }

        ${p.isError ? `border: 2px solid ${colors.red};` : ''}
        ${p.isIcon ? `padding-left: 2.25rem;` : ''}
    `;
}}`;

type Props = Omit<InputProps, 'onChange'> & {
    as?: 'textarea' | 'input';
    icon?: React.ReactNode;
    onChange?: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
    loading?: boolean;
    clearable?: boolean;
};

export default React.forwardRef(function Input(
    props: Props,
    ref: React.MutableRefObject<HTMLInputElement>
) {
    const {
        value: valueFromProps,
        onChange: onChangeFromProps,
        onDebounce: onDebounceFromProps,
        debounce: debounceFromProps,
        loading,
        ...inputProps
    } = props;
    const value = valueFromProps ?? '';

    const onDebounce = useMemo(() => {
        let debounceTime = debounceFromProps ?? 350;
        if (process.env.NODE_ENV === 'test') debounceTime = 0;
        if (onDebounceFromProps) return debounce(onDebounceFromProps, debounceTime);
        return null;
    }, props.debounceDeps || []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChangeFromProps?.(e.target.value, e);
        onDebounce?.(e.target.value);
    };

    const uniqueId = useId();
    const id = inputProps.id ?? uniqueId;

    const hasIcon = Boolean(props.icon || props.type === 'search' || loading);
    return (
        <Relative
            display={props.type === 'hidden' ? 'none' : undefined}
            width={props.width}
            height={props.height}
        >
            {hasIcon && (
                <Absolute top={2} left=".75rem" color="background2" as="label" htmlFor={id}>
                    {loading ? (
                        <Spinner />
                    ) : (
                        props.icon || (
                            <div data-testid="appcues-input-search-icon">
                                <IconSearch />
                            </div>
                        )
                    )}
                </Absolute>
            )}
            <StyledInput
                {...inputProps}
                id={id}
                ref={ref}
                as={props.as || 'input'}
                rows={props.rows}
                isError={!!props.isError}
                isIcon={hasIcon}
                onChange={onChange}
                value={value}
            />
            {props.clearable && value && (
                <Absolute top={2} cursor="pointer" right=".75rem">
                    <Clickable
                        onClick={() => {
                            onChangeFromProps?.('');
                            onDebounce?.('');
                        }}
                        aria-label="Clear text"
                    >
                        <IconCross />
                    </Clickable>
                </Absolute>
            )}
        </Relative>
    );
});
