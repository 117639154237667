import React from 'react';
import SeoTemplate from '../affordance/SeoTemplate';
import BrandLogos from '../affordance/BrandLogos';
import {Box, Flex, FlexColumn, Grid} from 'bigdatr-style/layout';
import {Button, Input, Label, Link, Select, Text, useActionState} from 'bigdatr-style';
import {RegisterFormData} from '../auth/data/RegisterData';
import usePageData from '../core/usePageData';
import {RegisterData} from '../../server/dataLoaders/registerData';
import {Redirect, useLocation} from 'react-router-dom';
import {excludedMailProviders, validEmail} from '../util/validation';
import {useApi} from '../core/EntityApi';
import RegisterPageDrawing from '../img/register/RegisterPageDrawing';

export default function Register() {
    const registerSubmit = useApi().registerSubmit.useRequest();
    const {pathname, search} = useLocation();

    // It's safe to get the token directly from the message as
    // it comes straight from the server render
    const pageData = usePageData<RegisterData>({url: pathname});

    const initialValues: Omit<RegisterFormData, 'utm' | 'registrationToken' | 'timeZone'> = {
        firstName: '',
        lastName: '',
        jobTitle: '',
        company: '',
        email: '',
        industryGroup: ''
    };

    const initialState = {
        invalid: initialValues,
        values: initialValues
    };

    const validate = (draft: typeof initialState, key: keyof typeof initialValues) => {
        const requiredText = 'This field is required.';

        let helpText = '';
        switch (key) {
            case 'email': {
                const {email} = draft.values;
                const isEmpty = email.trim().length === 0;
                const isValidEmail = email.match(validEmail);
                const [, domain] = email.split('@');
                const isForbiddenMail = excludedMailProviders.find((ee) => domain === ee);

                helpText = isEmpty
                    ? requiredText
                    : !isValidEmail
                    ? 'Please enter a valid email address.'
                    : isForbiddenMail
                    ? 'Please use your work email.'
                    : '';
                break;
            }
            case 'industryGroup': {
                const {industryGroup} = draft.values;
                const isValidIndustryGroup = industryGroups.includes(industryGroup);

                helpText = !isValidIndustryGroup ? 'Please select an industry group' : '';
                break;
            }
            default: {
                const value = draft.values[key];
                helpText = value.trim().length === 0 ? requiredText : '';
                break;
            }
        }

        draft.invalid[key] = helpText;
    };

    const [{values, invalid}, actions] = useActionState(initialState, {
        setValue: (draft, key: keyof typeof initialValues, value: string) => {
            draft.values[key] = value;
        },
        validate,
        validateAll: (draft) => {
            const {values} = draft;

            Object.keys(values).forEach((formInput: keyof typeof initialValues) => {
                validate(draft, formInput);
            });
        }
    });

    const onSubmit = () => {
        const formState = actions.validateAll();
        const {values, invalid} = formState;
        // Check that all fields are valid
        const validForm =
            // Every form value is filled out
            Object.values(values).every((vv) => vv.length) &&
            // Every form value is valid
            Object.values(invalid).every((vv) => vv.length === 0);

        if (validForm) {
            const registrationToken = pageData.response?.clientWebData?.registrationToken || '';
            const data: RegisterFormData = {
                ...values,
                registrationToken,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            };

            // append utm from query params
            const queryParams = new URLSearchParams(search);

            const utmCampaign = queryParams.get('utm_campaign') || undefined;
            const utmSource = queryParams.get('utm_source') || undefined;
            const utmMedium = queryParams.get('utm_medium') || undefined;
            const utmTerm = queryParams.get('utm_term') || undefined;
            const utmContent = queryParams.get('utm_content') || undefined;

            if (utmCampaign || utmSource || utmMedium || utmTerm || utmContent) {
                const utmData = {
                    campaign: utmCampaign,
                    source: utmSource,
                    medium: utmMedium,
                    term: utmTerm,
                    content: utmContent
                };

                data.utm = utmData;
            }

            registerSubmit.request(data);
        }
    };

    const industryGroups = pageData.response?.clientWebData.industryGroups || [];
    const isSubmitting = Boolean(registerSubmit.isFetching || registerSubmit.isRefetching);

    if (registerSubmit.isSuccess) return <Redirect to="/register/verify-email" />;

    return (
        <FlexColumn gap={6} my={5}>
            <Box maxWidth="65rem" mx="auto">
                <SeoTemplate
                    title="Try Bigdatr for free"
                    description="Fill out the form and we’ll be in touch soon. Our friendly team will show you
                    what’s possible with our suite of products, designed to drive your success."
                    slug="register"
                />
                <Grid gridTemplateColumns={{md: '1fr 1fr', lg: '6fr 4fr'}} gap={4}>
                    <FlexColumn
                        maxWidth={{_: '35rem', md: 'unset'}}
                        mx={{_: 'auto', md: 'unset'}}
                        alignSelf="center"
                    >
                        <Box mb={4}>
                            <Text as="h1" textStyle="heading2" mb={2}>
                                Advertise with confidence by understanding the marketplace first.
                            </Text>
                            <Text as="p">
                                Set up your free account and start tracking competitor advertising
                                trends.
                            </Text>
                        </Box>
                        <Box maxWidth={{_: '25rem', md: '30rem'}} alignSelf="center" width="100%">
                            <RegisterPageDrawing />
                        </Box>
                    </FlexColumn>
                    <FlexColumn maxWidth={{_: '35rem', md: 'unset'}} mx={{_: 'auto', md: 'unset'}}>
                        <Text as="h2" textStyle="heading1" my={3}>
                            Try Bigdatr for free
                        </Text>
                        <Text as="h3" textStyle="heading3">
                            Create an account
                        </Text>
                        <Text as="p" mb={3}>
                            7-day free trial | No credit card needed
                        </Text>

                        <Flex gap={3}>
                            <InputRow
                                input="text"
                                name="firstName"
                                label="First Name"
                                value={values.firstName}
                                onChange={(next) => {
                                    actions.setValue('firstName', next);
                                }}
                                onBlur={() => {
                                    actions.validate('firstName');
                                }}
                                error={invalid.firstName}
                                type="text"
                                autocomplete="given-name"
                            />
                            <InputRow
                                input="text"
                                name="lastName"
                                label="Last Name"
                                value={values.lastName}
                                onChange={(next) => {
                                    actions.setValue('lastName', next);
                                }}
                                onBlur={() => {
                                    actions.validate('lastName');
                                }}
                                error={invalid.lastName}
                                type="text"
                                autocomplete="family-name"
                            />
                        </Flex>
                        <InputRow
                            input="text"
                            name="email"
                            label="Work Email"
                            value={values.email}
                            onChange={(next) => {
                                actions.setValue('email', next);
                            }}
                            onBlur={() => {
                                actions.validate('email');
                            }}
                            error={invalid.email}
                            type="email"
                            autocomplete="email"
                        />
                        <InputRow
                            input="text"
                            name="jobTitle"
                            label="Job Title"
                            value={values.jobTitle}
                            onChange={(next) => {
                                actions.setValue('jobTitle', next);
                            }}
                            onBlur={() => {
                                actions.validate('jobTitle');
                            }}
                            error={invalid.jobTitle}
                            type="text"
                            autocomplete="organization-title"
                        />
                        <InputRow
                            input="text"
                            name="company"
                            label="Company Name"
                            value={values.company}
                            onChange={(next) => {
                                actions.setValue('company', next);
                            }}
                            onBlur={() => {
                                actions.validate('company');
                            }}
                            error={invalid.company}
                            type="text"
                            autocomplete="organization"
                        />

                        <InputRow
                            input="select"
                            label="What data are you interested in?"
                            value={values.industryGroup}
                            onChange={(next) => actions.setValue('industryGroup', next)}
                            selectOptions={industryGroups}
                            placeholder="Click to select"
                            error={invalid.industryGroup}
                        />
                        <Box mt={3}>
                            <Button
                                large
                                type="submit"
                                onClick={onSubmit}
                                loading={isSubmitting}
                                width="100%"
                                children="Create free account"
                            />
                            {registerSubmit.isError && (
                                <ErrorBox errorMessage={registerSubmit.requestError?.message} />
                            )}
                        </Box>
                        <Text mt={3} textStyle="disclaimer">
                            <Text>
                                By clicking &quot;Create free account&quot; you agree to our{' '}
                            </Text>
                            <Link
                                target="blank"
                                href="https://bigdatr.com/terms-of-use"
                                children="Terms of Use"
                            />
                            <Text>, </Text>
                            <Link
                                target="blank"
                                href="https://bigdatr.com/privacy-policy"
                                children="Privacy Policy"
                            />
                            <Text> and </Text>
                            <Link
                                target="blank"
                                href="https://bigdatr.com/licenseagreement"
                                children="Licencing Agreement"
                            />
                            <Text>, </Text>
                            <Text>
                                and recognise our team will be in contact with you regarding your
                                account, enquiries or other purposes.
                            </Text>
                        </Text>
                    </FlexColumn>
                </Grid>
            </Box>
            <BrandLogos headingStyle="heading3" />
        </FlexColumn>
    );
}

type InputProps = {
    input: 'text' | 'select';
    label: string;
    value: string;
    onChange: (next: string) => void;
    error?: string;
    placeholder?: string;

    // Select props
    selectOptions?: string[];

    // Textbox props
    onBlur?: () => void;
    type?: string;
    name?: string;
    autocomplete?: string;
};
const InputRow = (props: InputProps) => {
    const {value, onChange, onBlur, error, type, label, name, autocomplete, input, selectOptions} =
        props;
    const placeholder = props.placeholder ?? label;
    return (
        <Box width="100%">
            {input === 'text' && (
                <>
                    <Label children={label} htmlFor={name} />
                    <Input
                        name={name}
                        id={name}
                        type={type}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        autoComplete={autocomplete}
                        placeholder={placeholder}
                    />
                </>
            )}

            {input === 'select' && (
                <Select
                    formLabel={label}
                    value={value}
                    options={selectOptions || []}
                    label={(ii) => ii}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            )}
            <ErrorBox errorMessage={error} />
        </Box>
    );
};

// Fixed height is applied on the Box so that new errors appearing in
// the form don't cause a layout shift
const ErrorBox = ({errorMessage}: {errorMessage?: string}) => {
    return (
        <Box height="1.5rem">
            <Text color="red" fontSize={1}>
                {errorMessage}
            </Text>
        </Box>
    );
};
