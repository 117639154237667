import React from 'react';
import {format} from 'date-fns';
import {Seo} from '../affordance/SeoTemplate';

import {VideoObject} from 'schema-dts';
import {jsonLdScriptProps} from 'react-schemaorg';

import {BigDatrUrl} from '../core/urls';
import ClientWebCreative from '../ClientWebCreative';

const DATE_FORMAT = 'yyyy-MM-dd';

const BASE_URL = `${BigDatrUrl}/au/ad`;

const buildAdSeo = (creative: ClientWebCreative) => {
    const brand = creative.brandList?.[0]?.name;
    const title = `${brand} Ad`;

    const firstAppeared = creative.firstAppeared
        ? format(creative.firstAppeared, DATE_FORMAT)
        : undefined;

    const description = `Video advertisement for ${brand} published on ${creative.publicationList?.[0].name}`;

    const pageUrl = `${BASE_URL}/${creative.id}`;

    return {
        title,
        description,
        firstAppeared,
        pageUrl
    };
};

const isVideoCreative = (creative: ClientWebCreative) =>
    creative.mediaTypeList && creative.mediaTypeList.find((ii) => ii.name === 'Digital Video');

export const buildAdSitemap = (creatives: Array<ClientWebCreative>) => {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <urlset xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
        http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd"
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
        xmlns:xhtml="http://www.w3.org/1999/xhtml"
        xmlns:video="http://www.google.com/schemas/sitemap-video/1.1"
        >
        ${creatives
            .map((creative) => {
                const seo = buildAdSeo(creative);
                const isVideo = isVideoCreative(creative);
                return `
                        <url>
                            <loc>${seo.pageUrl}</loc>
                            ${seo.firstAppeared ? ` <lastmod>${seo.firstAppeared}</lastmod>` : ''}

                            ${
                                isVideo
                                    ? `
                                <video:video>
                                    <video:description>${encodeURIComponent(
                                        seo.description
                                    )}</video:description>
                                    <video:thumbnail_loc>${
                                        creative.croppedThumbnail
                                    }</video:thumbnail_loc>
                                    <video:publication_date>${
                                        seo.firstAppeared
                                    }</video:publication_date>
                                    <video:live>no</video:live>
                                    <video:restriction relationship="allow">au</video:restriction>
                                </video:video>
                            `
                                    : ''
                            }
                        </url>
                    `;
            })
            .join('')}
    </urlset>`;
};

type AdSeoProps = {creative: ClientWebCreative};
const AdSeo = ({creative}: AdSeoProps) => {
    const seo = buildAdSeo(creative);
    const isVideo = isVideoCreative(creative);
    return (
        <>
            <Seo
                title={seo.title}
                description={seo.description}
                canonical={seo.pageUrl}
                openGraph={{
                    url: seo.pageUrl,
                    images: [creative.croppedThumbnail].map((t) => ({
                        width: 512,
                        height: 288,
                        url: t
                    })),
                    type: 'video'
                }}
            />
            {isVideo && (
                <script
                    {...jsonLdScriptProps<VideoObject>({
                        '@context': 'https://schema.org',
                        '@type': 'VideoObject',
                        name: seo.title,
                        description: seo.description,
                        thumbnailUrl: creative.croppedThumbnail,
                        thumbnail: {
                            '@type': 'ImageObject',
                            url: creative.croppedThumbnail,
                            width: '512',
                            height: '288'
                        },
                        contentUrl: creative.filepath,
                        uploadDate: seo.firstAppeared,
                        regionsAllowed: 'AU'
                    })}
                />
            )}
        </>
    );
};

export default AdSeo;
