import React from 'react';
import {Flex, Box, Wrapper} from 'bigdatr-style/layout';
import {IconLinkedin} from 'bigdatr-style/icon';
import {IconTwitter} from 'bigdatr-style/icon';
import {IconFacebook} from 'bigdatr-style/icon';
import {IconInstagram} from 'bigdatr-style/icon';
import {IconYoutube} from 'bigdatr-style/icon';
import {Link} from 'bigdatr-style';
import {formatDate} from 'bigdatr-style';

import {LinkedinUrl} from '../core/urls';
import {TwitterUrl} from '../core/urls';
import {FacebookUrl} from '../core/urls';
import {InstagramUrl} from '../core/urls';
import {YoutubeUrl} from '../core/urls';

export default function Footer() {
    return (
        <Box backgroundColor="background1">
            <Wrapper p={3}>
                <Flex alignItems="center" flexWrap="wrap">
                    <Flex
                        mr="auto"
                        flexWrap="wrap"
                        width={{_: 1, md: 'auto'}}
                        justifyContent={{_: 'center', md: 'normal'}}
                    >
                        <Box mr={3} color="muted">
                            © {formatDate('yyyy')(new Date())} Bigdatr
                        </Box>
                        <FooterLink to="/compare-plans">Compare Plans</FooterLink>
                        <FooterLink to="/contact-us">Contact Us</FooterLink>
                        <FooterLink to="/about-us">About Us</FooterLink>
                        <FooterLink to="/register">Free Trial</FooterLink>
                        <FooterLink to="/blog">Blog</FooterLink>
                        <FooterLink href="https://blueflag.com.au/nevdis/">NEVDIS</FooterLink>
                        <FooterLink to="/licenseagreement">General Terms</FooterLink>
                        <FooterLink to="/terms-of-use">Terms of Use</FooterLink>
                        <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
                    </Flex>
                    <Flex
                        flexWrap="wrap"
                        width={{_: 1, md: 'auto'}}
                        justifyContent={{_: 'center', md: 'normal'}}
                        mt={{_: 3, lg: 0}}
                    >
                        <FooterLink mr={2} href={LinkedinUrl}>
                            <IconLinkedin size="20px" />
                        </FooterLink>
                        <FooterLink mr={2} href={TwitterUrl}>
                            <IconTwitter size="20px" />
                        </FooterLink>
                        <FooterLink mr={2} href={FacebookUrl}>
                            <IconFacebook size="20px" />
                        </FooterLink>
                        <FooterLink mr={2} href={InstagramUrl}>
                            <IconInstagram size="20px" />
                        </FooterLink>
                        <FooterLink mr={0} href={YoutubeUrl}>
                            <IconYoutube size="20px" />
                        </FooterLink>
                    </Flex>
                </Flex>
            </Wrapper>
        </Box>
    );
}

type FooterLinkProps = {
    children: React.ReactNode;
    href?: string;
    to?: string;
    mr?: number;
};
const FooterLink = ({children, href, mr = 3, to}: FooterLinkProps) => (
    <Box mr={mr}>
        <Link to={to} href={href} variant="muted">
            {children}
        </Link>
    </Box>
);
