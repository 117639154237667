import React from 'react';
import {BigDatrUrl, BigDatrLogoOG} from '../core/urls';
import {Helmet} from 'react-helmet';
import buildTags from './seo/buildTags';
import {SeoProps} from './seo/buildTagsTypes';

export function Seo(props: SeoProps) {
    const {
        title,
        noindex = false,
        nofollow,
        description,
        canonical,
        openGraph,
        twitter,
        titleTemplate
    } = props;

    return (
        <Helmet>
            {buildTags({
                title,
                noindex,
                nofollow,
                description,
                canonical,
                openGraph,
                twitter,
                titleTemplate
            })}
        </Helmet>
    );
}

type SeoTemplateProps = {
    title: string;
    description?: string;
    slug: string;
};

export default function SeoTemplate(props: SeoTemplateProps) {
    const {title, description, slug} = props;
    return (
        <Seo
            title={title}
            titleTemplate="%s - Bigdatr"
            description={description}
            canonical={`${BigDatrUrl}/${slug}`}
            openGraph={{
                type: 'website',
                url: `${BigDatrUrl}/${slug}`,
                title: `${title} - Bigdatr`,
                description: description,
                images: [
                    {
                        url: BigDatrLogoOG
                    }
                ]
            }}
            twitter={{
                cardType: 'summary_large_image'
            }}
        />
    );
}
