import React, {ReactNode} from 'react';
import {StaticRouter, BrowserRouter} from 'react-router-dom';
import {Theme, Analytics, AnalyticsProvider, Google} from 'bigdatr-style';
import EntityApi, {ApiContext} from './EntityApi';
import StyleContext, {InsertCSS} from 'isomorphic-style-loader/StyleContext';
import ScrollToTop from './ScrollToTop';

export type PrimingData = {
    type: 'ENTY_RECEIVE' | 'ENTY_ERROR' | 'ENTY_FETCH';
    responseKey: string;
    payload: any;
};

type ServerConfig = {
    target: 'server';
    css: Set<string>;
    path: string;
    primingData: Array<PrimingData>;
};

type ClientConfig = {
    target: 'client';
};

export default function ProviderFactory(config: ClientConfig | ServerConfig) {
    let primingData: Array<PrimingData>;
    let analytics: Analytics;
    let insertCss: InsertCSS;

    if (config.target === 'server') {
        primingData = config.primingData;
        insertCss = (...styles: any[]) =>
            styles.forEach((style) => config.css.add(style._getCss()));

        analytics = new Analytics({});
    } else {
        const initData = (window as any).CACHE_INIT_DATA;
        primingData = initData?.primingData as Array<PrimingData>;

        insertCss = (...styles: any[]) => {
            const removeCss = styles.map((style) => style._insertCss());
            return () => removeCss.forEach((dispose) => dispose());
        };

        const google = new Google(process.env.GA_MEASUREMENT_ID || '', {pageViewOnLoad: false});
        analytics = new Analytics({google});
    }

    const api = EntityApi(primingData);
    const EntyProvider = api.Provider;

    return (props: {children: ReactNode}) => (
        <StyleContext.Provider value={{insertCss}}>
            <EntyProvider>
                <ApiContext.Provider value={api}>
                    <AnalyticsProvider value={analytics}>
                        <Theme>
                            {config.target === 'server' ? (
                                <StaticRouter
                                    location={config.path}
                                    context={{}}
                                    children={props.children}
                                />
                            ) : (
                                <BrowserRouter>
                                    <ScrollToTop />
                                    {props.children}
                                </BrowserRouter>
                            )}
                        </Theme>
                    </AnalyticsProvider>
                </ApiContext.Provider>
            </EntyProvider>
        </StyleContext.Provider>
    );
}
