import AdTemplate from './ad/AdTemplate';
import BlogItemTemplate from './blog/BlogItemTemplate';
import BlogListTemplate from './blog/BlogListTemplate';
import BrandTemplate from './brand/BrandTemplate';
import BrandYearTemplate from './brand/BrandYearTemplate';
import CollectionTemplate from './collection/CollectionTemplate';
import Index from './index/IndexTemplate';
import RegisterVerifyEmail from './auth/RegisterVerifyEmail';
import aboutUs from './pages/about-us.mdx';
import contactUs from './pages/contact-us.mdx';
import bookDemo from './pages/book-demo.mdx';
import register from './pages/register';
import licenseAgreement from './pages/licenseagreement.mdx';
import privacyPolicy from './pages/privacy-policy.mdx';
import termsOfUse from './pages/terms-of-use.mdx';
import thankYou from './pages/thank-you.mdx';
import React from 'react';
import {RouteProps} from 'react-router-dom';
import ComparePlans from './pages/compare-plans';

interface SimpleRouteProps {
    path: string;
    component: RouteProps['component'];
    exact?: boolean;
    headers?: Record<string, string>;
}

const route = (
    path: string,
    component: React.ComponentType<any>,
    other: Partial<SimpleRouteProps> = {}
) => ({path, component, ...other});

export default function router(): Array<SimpleRouteProps> {
    return [
        route('/', Index, {exact: true}),
        route('/au/brand/:brandId/:year', BrandYearTemplate),
        route('/au/brand/:brandId', BrandTemplate),
        route('/blog/:slug', BlogItemTemplate),
        route('/blog', BlogListTemplate),
        route('/au/ad/:creativeId', AdTemplate),
        route('/au/collection/:collectionId', CollectionTemplate),
        route('/book-demo', bookDemo),
        route('/contact-us', contactUs),
        route('/about-us', aboutUs),
        route('/licenseagreement', licenseAgreement),
        route('/privacy-policy', privacyPolicy),
        route('/terms-of-use', termsOfUse),
        route('/thank-you', thankYou),
        route('/compare-plans', ComparePlans),
        route('/register/verify-email', RegisterVerifyEmail, {
            headers: {'cache-control': 'no-cache'}
        }),
        route('/register', register, {headers: {'cache-control': 'no-cache'}})
    ];
}
