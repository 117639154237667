import AdSeo from './AdSeo';
import CallToActionNotifications from '../affordance/CallToActionNotifications';
import DeadEndAdNotFound from '../affordance/DeadEndAdNotFound';
import React, {ReactNode} from 'react';
import usePageData, {usePageDataApi} from '../core/usePageData';
import CreativePlayer from '../affordance/CreativePlayer';
import {Box} from 'bigdatr-style/layout';
import {Brand} from 'bigdatr-style';
import {EntityAvatarBrand} from 'bigdatr-style';
import {Flex} from 'bigdatr-style/layout';
import {Text} from 'bigdatr-style';
import {Skeleton} from 'bigdatr-style';
import {TableKeyValue} from 'bigdatr-style';
import {Link} from 'bigdatr-style';
import {ClientWebBrand} from '../ClientWebBrand';
import ClientWebCreative from '../ClientWebCreative';

type AdTemplateProps = {
    match: {url: string};
};

export type AdMessage = {
    clientWebData: {
        creativeItem: ClientWebCreative;
    };
};

export default function AdTemplateMoreCta(props: AdTemplateProps) {
    const {url} = props.match;
    const message = usePageData<AdMessage>({url});

    if (message.isEmpty) return null;
    if (message.isError) {
        if (message.requestError?.statusCode === 404) {
            return <DeadEndAdNotFound />;
        }
        throw message.requestError;
    }
    if (message.isFetching || message.isRefetching) return <SkeletonLayout />;

    const creative = message.response.clientWebData.creativeItem;
    const {brandList, publicationList} = creative;
    const brand = brandList?.[0];

    return (
        <Box>
            <AdSeo creative={creative} />
            {brand && (
                <Box mb={4} mt={5}>
                    <Flex alignItems="center" mb={2}>
                        <Box>
                            <EntityAvatarBrand
                                entity={new Brand({name: brand.name})}
                                width="2rem"
                            />
                        </Box>
                        <Box ml={3}>
                            <Text as="h1" textStyle="heading1">
                                {brand.name} ad
                            </Text>
                        </Box>
                    </Flex>
                    <BrandLink brand={brand} />
                </Box>
            )}
            <Box display={{lg: 'flex'}}>
                <Box width={{lg: '70rem'}} pr={{lg: 4}} pb={4}>
                    <Flex
                        backgroundColor="black"
                        height="100%"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <CreativePlayer creative={creative} />
                    </Flex>
                </Box>
                <Box width={{lg: '30rem'}} pr={{lg: 4}} zIndex={1}>
                    <Box border={{md: 'outline'}} p={{md: 3}}>
                        <TableKeyValue
                            tight
                            map={
                                new Map<ReactNode, ReactNode>([
                                    [
                                        'Brand',
                                        brand && (
                                            <Flex alignItems="center">
                                                <EntityAvatarBrand
                                                    entity={new Brand({name: brand.name})}
                                                    width="1rem"
                                                />
                                                <Text ml={2}>{brand.name}</Text>
                                            </Flex>
                                        )
                                    ],
                                    ['Publication', publicationList?.map((_) => _.name).join(', ')],
                                    ['Schedule', creative.schedule]
                                ])
                            }
                        />
                    </Box>
                    <Box mt={4}>
                        <CallToActionNotifications identifier={'adPageRightBox'} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

function BrandLink({brand}: {brand: ClientWebBrand}) {
    const request = usePageDataApi();
    const to = `/au/brand/${brand.key}`;
    return (
        <Link
            to={to}
            preload={{request, responseKey: to, payload: to}}
            children={`« More ${brand.name} ads`}
        />
    );
}

function SkeletonLayout() {
    return (
        <Box>
            <Box mb={4} mt={5}>
                <Skeleton mb={2} width="40%" height="2.5rem" />
                <Skeleton mb={2} width="60%" height="2rem" />
                <Skeleton width="20%" height="1rem" />
            </Box>
            <Box display={{md: 'flex'}} pt={2}>
                <Box width={{md: '70%'}} pr={{md: 4}}>
                    <Skeleton height="30rem" />
                </Box>
                <Box width={{md: '30%'}}>
                    <Skeleton mb={4} height="18rem" />
                    <Skeleton height="10rem" />
                </Box>
            </Box>
        </Box>
    );
}
