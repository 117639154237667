import {useEffect} from 'react';
import {useApi} from './EntityApi';

export default function usePageData<T = any>(config: {url: string}) {
    const {url} = config;
    const {useRequest} = usePageDataApi();
    const message = useRequest<T>({responseKey: url});
    const {isEmpty} = message;

    useEffect(() => {
        if (isEmpty) {
            message.request(url);
        }
    }, [url]);

    return message;
}

export function usePageDataApi() {
    const {pageData} = useApi();
    return pageData;
}
