
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import MdxLayout from '../affordance/MdxLayout';
import SeoTemplate from '../affordance/SeoTemplate';
export const frontmatter = {
  "title": "Licence Agreement",
  "description": null
};

const layoutProps = {
  frontmatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <MdxLayout mdxType="MdxLayout">
      <SeoTemplate title={frontmatter.title} description={frontmatter.description} slug={frontmatter.slug} mdxType="SeoTemplate" />
      <h1>{`BigDatr Licence Agreement General Terms`}</h1>
      <p>{`This Agreement is formed between Big Datr Pty Ltd (ACN 165 443 470) of Level 1 / 254 Dorcas St, South Melbourne VIC 3205 (`}<strong parentName="p">{`Us`}</strong>{`, `}<strong parentName="p">{`Our`}</strong>{`, `}<strong parentName="p">{`We`}</strong>{`) and the customer as set out in the Order Form (`}<strong parentName="p">{`You`}</strong>{`, `}<strong parentName="p">{`Your`}</strong>{`).`}</p>
      <p><strong parentName="p">{`THE PARTIES AGREE THAT:`}</strong></p>
      <p><strong parentName="p">{`1 Definitions and interpretation`}</strong></p>
      <p>{`1.1 Definitions`}</p>
      <p>{`In this Agreement:`}</p>
      <p><strong parentName="p">{`Agreement`}</strong>{` means this agreement and includes these General Terms, the Order Form and the Privacy Policy;`}</p>
      <p><strong parentName="p">{`Applications`}</strong>{` means the applications made available to You through the Platform, as set out in the Order Form, which may be varied between the Parties in writing from time to time;`}</p>
      <p><strong parentName="p">{`Business Day`}</strong>{` means any day that is not a weekend or public holiday in the State of Victoria, Australia;`}</p>
      <p><strong parentName="p">{`Change of Control`}</strong>{` in relation to:`}</p>
      <p>{`(a) a body corporate (including a body corporate acting in the capacity as trustee of a trust) means the occurrence of an event or circumstance where a person who is not presently able to do any of the following things becomes able to do one of the following things (whether directly or indirectly or through one or more intervening persons, companies or trusts):`}</p>
      <p>{`(i) control the composition of more than one half of the body's board of directors;`}</p>
      <p>{`(ii) be in a position to cast, or control the casting of, more than one half of the maximum number of votes that might be cast at a general meeting of the members of the body; or`}</p>
      <p>{`(iii) hold or have a beneficial interest in more than one half of the issued share capital of the body;`}</p>
      <p>{`(b) a trust means the occurrence of an event or circumstance where a person who is not presently the Controlling Person of that trust becomes the Controlling Person of that trust;`}</p>
      <p><strong parentName="p">{`Commencement Date`}</strong>{` means the date upon which You execute the Order Form;`}</p>
      <p><strong parentName="p">{`Confidential information`}</strong>{` means the confidential information of a Party which relates to the subject matter of this Agreement and includes all information:`}</p>
      <p>{`(a) relating to trade secrets, know-how, financial information and other commercially valuable information of whatever description and in whatever form (whether written or oral) which may be disclosed by one Party to the other in connection with this Agreement or otherwise concerning the Platform, any technology, marketing strategies and business of the disclosing Party, the terms of this Agreement and any information which is by its nature confidential;`}</p>
      <p>{`(b) that is designated by the disclosing Party to be confidential;`}</p>
      <p>{`(c) which is by its nature confidential; or`}</p>
      <p>{`(d) which the receiving Party ought reasonably to know is confidential,`}</p>
      <p>{`but does not include information already known to the receiving Party at the time of disclosure by the other Party or information in the public domain other than as a result of disclosure by a Party in breach of its obligations of confidentiality under this Agreement;`}</p>
      <p><strong parentName="p">{`Controlling Person`}</strong>{` means, in relation to a trust, the person in whom is vested the ability to appoint a new or additional trustee of that trust or remove the trustee of that trust or with whose instructions such trustees are accustomed to act or any other power which in all relevant circumstances would cause a reasonable person to believe that that person controlled the trust;`}</p>
      <p><strong parentName="p">{`Default Interest`}</strong>{` means interest payable at the rate of 12 per cent per annum calculated on a daily basis;`}</p>
      <p><strong parentName="p">{`Dispute`}</strong>{` means a dispute, difference or question relating to this Agreement including any dispute, difference or question regarding the breach, termination, validity or subject matter of this Agreement, or dispute as to the circumstances leading to the entry by the Parties into this Agreement or any claim whether in tort, negligence, equity, under statute or otherwise;`}</p>
      <p><strong parentName="p">{`General Terms`}</strong>{` means the general terms set out in this document, which forms part of the Agreement;`}</p>
      <p><strong parentName="p">{`Initial Term`}</strong>{` means the initial term of this Agreement as set out in the Order Form;`}</p>
      <p><strong parentName="p">{`Intellectual Property Rights`}</strong>{` means all present and future intellectual and industrial property rights throughout the world, including all rights, whether conferred by statute, common law, equity or otherwise, in or in relation to:`}</p>
      <p>{`(a) inventions, discoveries and novel designs (whether or not registered or registrable as patents or designs) including developments or improvements of equipment, products, technology, processes, methods or techniques;`}</p>
      <p>{`(b) copyright (including future copyright) throughout the world in all literary works, artistic works, computer software and any other works or subject matter in which copyright subsists and may in the future subsist, and includes moral rights;`}</p>
      <p>{`(c) obligations to have confidential information and trade secrets kept confidential and secret;`}</p>
      <p>{`(d) trade and service marks (whether registered or unregistered), business names, trade names, domain names, logos and get-up; and`}</p>
      <p>{`(e) circuit layouts;`}</p>
      <p><strong parentName="p">{`Licence`}</strong>{` means the licence to use the Platform granted by Us to You on the terms of this Agreement;`}</p>
      <p><strong parentName="p">{`Licence Fees`}</strong>{` means the fees set out in the Order Form, excluding GST unless otherwise provided for in the Order Form;`}</p>
      <p><strong parentName="p">{`Parties`}</strong>{` means the parties to this Agreement;`}</p>
      <p><strong parentName="p">{`Platform`}</strong>{` means the enterprise analytics operating platform and any associated functionalities, software, materials or documentation that is supplied to You by Us under this Agreement and includes the Applications;`}</p>
      <p><strong parentName="p">{`Privacy Policy`}</strong>{` means the privacy policy which appears on the Website, as amended from time to time;`}</p>
      <p><strong parentName="p">{`Order Form`}</strong>{` means the order form which We supply to You containing commercial terms forming part of this Agreement;`}</p>
      <p><strong parentName="p">{`OzTAM Data`}</strong>{` means OzTAM television audience ratings data made available by Big Datr to You through the Platform;`}</p>
      <p><strong parentName="p">{`Related Bodies Corporate`}</strong>{` has the meaning given to that term in the `}<em parentName="p">{`Corporations Act 2001`}</em>{` (Cth);`}</p>
      <p><strong parentName="p">{`Renewal Term`}</strong>{` has the meaning it is given by clause 2(b) of these Licence Agreement General Terms;`}</p>
      <p><strong parentName="p">{`Special Terms`}</strong>{` means the special terms, if any, set out in the Order Form which form part of this Agreement;`}</p>
      <p><strong parentName="p">{`Term`}</strong>{` means the Initial Term and each of the Renewal Terms (if any);`}</p>
      <p><strong parentName="p">{`Users`}</strong>{` means the permitted users of the Platform under Your Licence; and`}</p>
      <p><strong parentName="p">{`Website`}</strong>{` means `}<a parentName="p" {...{
          "href": "https://bigdatr.com"
        }}>{`https://bigdatr.com`}</a>{` including any sub-domains and related domains or as otherwise varied by Us.`}</p>
      <p>{`1.2 Interpretation`}</p>
      <p>{`In this Agreement, unless the context otherwise requires:`}</p>
      <p>{`(a) headings and notes in square brackets are for convenience only and do not affect the interpretation of this Agreement, except for the purpose of rectifying any erroneous cross-reference;`}</p>
      <p>{`(b) words importing the singular include the plural and vice versa;`}</p>
      <p>{`(c) words importing a gender include all genders;`}</p>
      <p>{`(d) a reference to a paragraph, clause, or schedule is a reference to a paragraph, clause, or schedule to this Agreement;`}</p>
      <p>{`(e) references to any document or agreement include references to such document or agreement as amended, novated, supplemented, varied or replaced from time to time;`}</p>
      <p>{`(f) all monetary amounts referred to in this Agreement are in AUD or Australian currency unless expressly stated otherwise;`}</p>
      <p>{`(g) a reference to a party to this Agreement includes that party’s legal successors (including executors and administrators) and permitted assigns; and`}</p>
      <p>{`(h) the words ‘including’, ‘includes’ or ‘for example’ or similar expressions are not words of limitation.`}</p>
      <p><strong parentName="p">{`2 Agreement and grant of Licence`}</strong></p>
      <p>{`(a) This Agreement commences on the Commencement Date and, subject to its earlier termination, shall continue for the Initial Term.`}</p>
      <p>{`(b) The Parties may agree to vary this Agreement to extend the current Term or renew the Agreement for a further Term (or Terms) (each a `}<strong parentName="p">{`Renewal Term`}</strong>{`) and on terms and conditions as agreed by the Parties in writing. During the Initial Term or the Renewal Term (as the case may be) We will contact You to ascertain if You wish to renew the current Term prior to its expiry. `}</p>
      <p>{`(c) You acknowledge that We require advanced written notice if you intend to continue the arrangements contemplated in this Agreement beyond the agreed Term.  You must notify Us at least 30 days prior to the expiry of the Initial Term or the Renewal Term (as the case may be) if you wish for this Agreement to be renewed beyond that Term. Any such renewal of this Agreement will be documented by way of a new Order Form to be executed by the Parties. Any failure to provide notice of the renewal/continuation of the arrangements at least 30 days prior to the expiry of the relevant Term may result in interruption of Your access to the Platform and We will not be liable for any loss incurred as a result of any such interruption or suspension of your access to the Platform arising as a result of the provision of late notice under this clause.`}</p>
      <p>{`(d) During the Term, We grant the Licence to You and You accept the Licence on the terms of this Agreement.`}</p>
      <p>{`(e) From the Commencement Date, You are responsible and liable for all Users' use of the Platform. Users may only use the Platform in accordance with the terms of this Agreement.`}</p>
      <p>{`(f) With the exception of any variation to the Term of this Agreement which must be by mutual agreement and in writing, We reserve the right to vary:`}</p>
      <p>{`(i) these General Terms (`}<a parentName="p" {...{
          "href": "https://bigdatr.com/licenseagreement)"
        }}>{`https://bigdatr.com/licenseagreement)`}</a>{`; and/or`}</p>
      <p>{`(ii) the Privacy Policy (`}<a parentName="p" {...{
          "href": "https://bigdatr.com/privacy-policy"
        }}>{`https://bigdatr.com/privacy-policy`}</a>{`),`}</p>
      <p>{`at any time. You are encouraged to monitor each of the above hyperlinks to ensure that you are aware of the most up to date version of each of these terms and policies.`}</p>
      <p>{`(g) If there is any inconsistency between any of the terms of this Agreement, then, to the extent of the inconsistency, the order of precedence will be as follows:`}</p>
      <p>{`(i) the Order Form; `}</p>
      <p>{`(ii) these General Terms; and`}</p>
      <p>{`(iii) the Privacy Policy.`}</p>
      <p><strong parentName="p">{`3 Payment of Licence Fees`}</strong></p>
      <p>{`(a) In consideration of Us granting the Licence to You, You must pay the Licence Fees to Us in accordance with each valid tax invoice We render to You in accordance with this Agreement (`}<strong parentName="p">{`Invoice`}</strong>{`).`}</p>
      <p>{`(b) Subject to clause 3(c), if You do not pay any of the Licence Fees to Us on or by the due date on the Invoice (`}<strong parentName="p">{`Due Date`}</strong>{`), Default Interest will be payable on the overdue amount and, if any payment of Licence Fees and interest is owing to Us after 30 days from the Due Date, We may do any or all of the following:`}</p>
      <p>{`(i) suspend Your Licence (i.e. suspending or otherwise limiting access to the Platform);`}</p>
      <p>{`(ii) terminate this Agreement;`}</p>
      <p>{`(iii) without limiting any other rights available to Us, we may initiate proceedings to recover the Licence Fees and Default Interest then outstanding as a liquidated debt from You.`}</p>
      <p>{`(c) If You dispute the whole or any part of the amount claimed in an Invoice rendered by Us to You in connection with this Agreement, You must pay any undisputed portion on or by the Due Date. Either You or We can refer the dispute regarding the disputed amount to the dispute resolution procedure prescribed by clause 9 of this Agreement. If it is determined by that process that a further amount is payable in addition to the disputed amount, You must pay that amount to Us together with Default Interest on the outstanding amount calculated from the Due Date.`}</p>
      <p>{`(d) We are committed to the continuous improvement of the Platform and the services it can provide to You. This may take the form of an increase in the quality and/or scope of the Platform. To align with this increase in value and in the event of such increase in scope, We reserve the right to increase the Fee payable by You. `}</p>
      <p>{`(e) We will use reasonable endeavours to communicate with You at least 3 months prior to the expiry of the current Term with the details of any such proposed increase in the Fee which will apply from the first date of any Renewal Term (or in the case of an extension of the current Term, from the expiry date of the Term prior to the extension/variation) and documented in the new Order Form.`}</p>
      <p><strong parentName="p">{`4 Licence conditions`}</strong></p>
      <p>{`(a) We warrant that We have the authority to grant the Licence to You.`}</p>
      <p>{`(b) The Licence We grant to You is a limited, revocable, non-transferable, non-assignable, non-exclusive licence for the Users to access and use the Platform in accordance with this Agreement.`}</p>
      <p>{`(c) You warrant that:`}</p>
      <p>{`(i) You have obtained all the necessary authorisations and consents for You to validly enter into, and perform all of Your obligations arising under, this Agreement; and`}</p>
      <p>{`(ii) You will only allow persons who have been provided with a username and password by Us to use the Platform.`}</p>
      <p>{`(d) Unless expressly provided otherwise in the Agreement or agreed by Us in writing, We are not required to support the Platform, whether by providing advice, training, error-correction, modification, updates, upgrades, new releases or enhancements or otherwise.`}</p>
      <p>{`(e) We agree to provide limited support during the Term by way of a support ticketing system which enables Users to report technical issues or perceived data inaccuracies within the Platform. In the event that We receive notice of a technical issue or perceived data inaccuracy within the Platform through the support ticketing system, We will use reasonable endeavours to investigate the issue, and where reasonably practicable, attempt to resolve the issue within a reasonable period of time.`}</p>
      <p>{`(f) You are responsible for ensuring that there is no unauthorised use of the Platform by Users or any other persons under your Licence. You acknowledge that any unauthorised use of the Licence and the Platform would cause significant harm to Our business which could not be calculated by damages alone, and that the award of other legal and equitable remedies such as specific performance would be required in addition to monetary damages to adequately compensate Us for any such unauthorised use.`}</p>
      <p>{`(g) Before the Commencement Date and if we require You to do so, You must supply Us with a list of the persons You wish to appoint as Users of the Platform under this Licence, and obtain Our consent to the appointment of those Users (which consent will not be unreasonably withheld or delayed). Should the Users You wish to appoint under this Licence change during the Term, You must obtain Our written consent to any such change.`}</p>
      <p><strong parentName="p">{`5 OzTAM Terms`}</strong></p>
      <p>{`Where You access the OzTAM Data through the Platform:`}</p>
      <p>{`(a) the terms set out in this clause 5 will apply to Your access, use and exploitation of that OzTAM Data in addition to the other terms included in these General Terms;`}</p>
      <p>{`(b) You are granted a non-exclusive, non-transferable licence to use OzTAM Data, for and as part of your use of the services provided by Big Datr, to the extent required in the normal and necessary course of your operations;`}</p>
      <p>{`(c) You must not use the OzTAM Data for any purpose other than as set out in the preceding clause 5(b);`}</p>
      <p>{`(d) You must not:`}</p>
      <p>{`(i) Sublicence, sell, supply, communicate, lease or loan the OzTAM Data to any person; or`}</p>
      <p>{`(ii) disassemble or decompile the OzTAM Data from the form in which it is made available to you by Us; and`}</p>
      <p>{`(e) You acknowledge that:`}</p>
      <p>{`(i) the OzTAM Data remains at all times the property of OzTAM;`}</p>
      <p>{`(ii) access to and use of OzTAM Data shall be through the Platform; and`}</p>
      <p>{`(iii) OzTAM will not be held responsible or liable for any aspect of the provided OzTAM Data, including but not limited to any errors or inaccuracies arising from or contained within the OzTAM Data.`}</p>
      <p><strong parentName="p">{`6 Ownership and Intellectual Property Rights`}</strong></p>
      <p>{`(a) Notwithstanding anything written elsewhere in this Agreement or in any other document, You acknowledge that We are the sole owner of the Platform, including any copies, modifications, updates or new releases whether modified by Us, You (or by Your officers, employees, contractors, associates, affiliates or agents) or any third party.`}</p>
      <p>{`(b) To the extent permitted by law, all Intellectual Property Rights in the Platform, including any copies, modifications, updates or new releases of the Platform, are vested in and retained by Us. For the avoidance of doubt, nothing in this Agreement gives rise to any assignment, acquisition, transfer or conveyance of any kind whatsoever of any Intellectual Property Rights.`}</p>
      <p>{`(c) If You correspond or otherwise communicate with Us or post or publish any statement about Our Platform, You automatically grant to Us an irrevocable, perpetual, non-exclusive, fully paid-up, royalty-free, world-wide licence to use, copy, display and distribute that content and to prepare derivative works of the content or incorporate the content into other works in order to publish and promote that content. This may include, but is not limited to, publishing testimonials and developing Your ideas and suggestions for improved products or services We provide.`}</p>
      <p>{`(d) Neither You, nor Your Users, officers, employees, contractors, associates, affiliates or agents may publish, make publicly available, electronically transmit, commercialise, repurpose or otherwise share with any third party any of the data or content accessed or receive through the Platform unless, and only to the extent that, You have been expressly authorised to do so by Us.`}</p>
      <p>{`(e) You indemnify Us against any loss or damage suffered or incurred by Us as a result of Your infringement (or the infringement by Your officers, employees, contractors, associates, affiliates or agents) of the Intellectual Property Rights attaching to the Platform.`}</p>
      <p>{`(f) You grant to Us an irrevocable, perpetual, non-exclusive, fully paid-up, royalty-free, world-wide licence to use Your logo, trademark or corporate name for the purposes of promoting Your association with the Platform.`}</p>
      <p><strong parentName="p">{`7 Indemnity and Liability`}</strong></p>
      <p>{`(a) You indemnify Us and Our Related Bodies Corporate and their respective officers and employees and contractors (for whom We enter into this Agreement as trustee) (the `}<strong parentName="p">{`Indemnified`}</strong>{`) in respect of all damages, costs, losses or other liability incurred by any of the Indemnified or awarded (or agreed to be paid by settlement or compromise) as a result of any claim, allegation, action or proceeding made or brought against any of the Indemnified arising out of or in connection with:`}</p>
      <p>{`(i) any negligent, unlawful or wilfully wrong act or omission by You or the Users;`}</p>
      <p>{`(ii) any breach of this Agreement by You or the Users;`}</p>
      <p>{`(iii) any unauthorised use of the Platform, by a User, or one of Your employees or contractors or otherwise, in connection with Your Licence.`}</p>
      <p>{`(b) To the extent permitted by law, We exclude all liability to You or anyone else for loss or damage of any kind (however caused or arising) relating in any way, directly or indirectly, to the this Agreement, including liability for loss of revenue, loss of profits, loss of opportunity, or any other form of consequential, special, indirect, punitive or exemplary loss or damage.`}</p>
      <p>{`(c) Where We are not entitled to exclude Our liability under this Agreement, Our total cumulative liability under this Agreement, whether in contract, tort (including negligence) under statute, strict liability or otherwise shall not exceed an amount equal to the total Licence Fees paid or payable by You over the immediately preceding six (6) month period of the Term.`}</p>
      <p>{`(d) We will provide the Platform to You under this Agreement with due care and skill and in accordance with any non-excludable guarantees which apply to the service under this Agreement. We do not guarantee that the service We provide or the content accessed through the Platform will be continuously available or free from errors, nor do We warrant, endorse, guarantee or assume responsibility for any products or services (or claims or statements about those products or services) that You may view in connection with the Platform that are supplied by third parties. Where We are not permitted to exclude Our liability for any loss or damage in connection with Our breach of any non-excludable guarantee, Our liability for any such breach will be limited, at Our option, to re-supplying the Licence or re-payment of the cost of having the Licence supplied again.`}</p>
      <p><strong parentName="p">{`8 Termination`}</strong></p>
      <p>{`(a) We may terminate this Agreement (and the Licence We grant to You under it) immediately if any of the following events occur:`}</p>
      <p>{`(i) You breach or threaten to breach any of Your material obligations under this Agreement;`}</p>
      <p>{`(ii) any type of administrator, receiver or liquidator is appointed in respect of Your business, property or affairs;`}</p>
      <p>{`(iii) You enter into any scheme, composition or arrangement of any kind with any of Your creditors; or`}</p>
      <p>{`(iv) You are subject to a Change of Control without our prior written consent.`}</p>
      <p>{`(b) We may terminate this Agreement (and the Licence We grant to You under it) for convenience upon 30 days' written notice to You.`}</p>
      <p>{`(c) You may terminate this Agreement (and the Licence We grant to You under it) immediately upon written notice to Us if any of the following events occur:`}</p>
      <p>{`(i) We breach any of Our material obligations to You under this Agreement and do not rectify that breach within thirty (30) days of receiving written notice from You requiring Us to do so; and`}</p>
      <p>{`(ii) We terminate Your access to the Platforms (other than as a consequence of Your breach of this Agreement).`}</p>
      <p>{`(d) If this Agreement is terminated in accordance with clause 3 or this clause 8, You must ensure that the Users immediately cease using the Platform and erase and destroy (as appropriate) software or any materials or documentation related to the Platform from Your Users' operating systems and hardware and certify in writing to Us that they have been erased or destroyed (as the case may be).`}</p>
      <p>{`(e) Termination of this Agreement (and the Licence We grant to You under it) does not affect any of Our accrued rights or liabilities, nor shall it affect any provision of this Agreement which are expressly or by implication intended to continue in force after such termination.`}</p>
      <p>{`(f) If You terminate this Agreement for any reason:`}</p>
      <p>{`(i) You forfeit any right or claim to any Licence Fees You have paid to Us in connection with this Agreement;`}</p>
      <p>{`(ii) any Licence Fees that would be payable by You for the remainder of the then-current Initial Term or Renewal Term (as the case may be) will immediately become due and payable; and`}</p>
      <p>{`(iii) We may plead this clause 8(f) as a full and complete defence to any proceedings arising in connection with any such claim for Licence Fees.`}</p>
      <p>{`(g) If We terminate this Agreement under clause 8(b), We will refund to You an amount equal to the proportion of the Licence Fees paid by You for the period from the termination date until the end of the Initial Term or Renewal Term (as the case may be) calculated on a pro rata basis.`}</p>
      <p><strong parentName="p">{`9 Confidentiality`}</strong></p>
      <p>{`(a) A Party will not, without the prior written approval of the other Party, disclose the other Party’s Confidential Information.`}</p>
      <p>{`(b) Notwithstanding this clause 9, a Party will not be in breach of this Agreement in circumstances where it is legally compelled to disclose the other Party’s Confidential Information.`}</p>
      <p>{`(c) Each Party will take all reasonable steps to ensure that its employees and agents, and any sub-contractors engaged for the purposes of this Agreement, do not make public or disclose the other Party’s Confidential Information.`}</p>
      <p>{`(d) You must inform Us immediately upon becoming aware of any breach of this clause 8 or of any unauthorised, unintended or undesirable disclosure of Our Confidential Information, and must provide Us with all reasonable assistance in remedying any such breach or disclosure.`}</p>
      <p>{`(e) During the Term and for two (2) years after termination of this Agreement, You must not disclose the amount or details of any Licence Fees You have paid under this Agreement, or any other term of this Agreement, to any third party without Our prior written consent (which may be granted or withheld in Our absolute discretion).`}</p>
      <p>{`(f) Notwithstanding anything written elsewhere in this Agreement, a Party may disclose the terms of this Agreement (other than Confidential Information of a technical nature) to its solicitors, auditors, insurers and accountants.`}</p>
      <p><strong parentName="p">{`10 Dispute Resolution`}</strong></p>
      <p>{`(a) In the event of a Dispute, the Parties must comply with this clause 10 before starting any arbitration or court proceedings (except proceedings for urgent injunctive relief).`}</p>
      <p>{`(b) A Party claiming that a Dispute has arisen must give the other Party notice setting out details of the Dispute.`}</p>
      <p>{`(c) During the fourteen (14) days after a notice is given under clause 10b) (or longer period if the Parties to the Dispute agree in writing), each Party to the Dispute must use reasonable efforts to resolve the Dispute. If the Parties cannot resolve the Dispute within that period, a Party may refer the Dispute to a binding arbitration to be conducted in Victoria in accordance with the Rules of the Institute of Arbitrators & Mediators Australia (Institute) and will be conducted by one or more arbitrators appointed by the President (or nominee) of the Institute.`}</p>
      <p>{`(d) A Party must not start court proceedings in relation to a Dispute until it has exhausted the mandatory procedures of this clause 9.`}</p>
      <p><strong parentName="p">{`11 Notices`}</strong></p>
      <p>{`(a) A notice, consent or other communication under this Agreement is only effective if it is:`}</p>
      <p>{`(i) in writing, signed by or on behalf of the person giving it;`}</p>
      <p>{`(ii) addressed to the person to whom it is to be given; and`}</p>
      <p>{`(iii) either:`}</p>
      <p>{`(A) delivered or sent by pre-paid mail (by airmail, if the addressee is overseas) to that person’s address; or`}</p>
      <p>{`(B) sent by email to that person’s email and the machine from which it is sent produces a delivery log which signifies that the email was sent and relayed to its intended recipient successfully.`}</p>
      <p>{`(b) A Party’s mail address and email address are as set out in the Order Form or as that Party notifies the other Party in writing from time to time.`}</p>
      <p>{`(c) If any notice, consent, information, application or request is delivered, or read report in relation to it is received, on a day that is not a Business Day, or if on a Business Day, after 5pm on that day, it is to be treated as having been given or made at the beginning of the next Business Day.`}</p>
      <p><strong parentName="p">{`12 General`}</strong></p>
      <p>{`(a) This Agreement is not to be interpreted against the interests of a Party merely because that Party proposed this Agreement or some provision of it or because that Party relies on a provision of this Agreement to protect itself.`}</p>
      <p>{`(b) You may not assign, whether in whole or part, Your benefit or any of Your rights or obligations under this Agreement to any other party without Our prior written consent, which We may grant or withhold in Our absolute discretion.`}</p>
      <p>{`(c) If You undergo a Change of Control, it will be deemed an assignment of this Agreement. Any assignment or transfer of this Agreement by You other than in accordance with the terms hereof shall be void ab initio and shall entitle Us to terminate this Agreement by written notice with immediate effect.`}</p>
      <p>{`(d) This Agreement will be governed by and interpreted in accordance with the laws of Victoria, Australia. You irrevocably submit to the non-exclusive jurisdiction of the courts of the State of Victoria, Australia.`}</p>
      <p>{`(e) If We do not exercise or enforce any right or provision under this Agreement, it will not constitute a waiver of such right or provision. Any waiver of any provision under this Agreement will only be effective if it is in writing and signed by Us.`}</p>
      <p>{`(f) If any part of this Agreement is found to be void, unlawful or unenforceable, it will be deemed to be severed from this Agreement to the extent of its invalidity, illegality and/or unenforceability only and the remainder of this Agreement will remain in full force and effect.`}</p>
    </MdxLayout>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;