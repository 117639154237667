import React, {useEffect, useRef, useState} from 'react';

type Props = {
    className?: string;
    onError?: (event?: Event) => React.ReactNode;
    onLoading?: () => React.ReactNode;
    onLoaded?: (ref: HTMLImageElement) => void;
    src: string;
    sourceSets?: Array<{src: string; type: string}>;
    alt: string;
    title?: string;
    width?: string;
    height?: string;
    style?: Record<string, any>;
    loading?: 'lazy' | 'eager';
    'aria-hidden'?: boolean;
};

type ImageStatus = 'initial' | 'loading' | 'loaded' | 'error';
const Image = (props: Props) => {
    const [content, setContent] = useState<React.ReactNode | null>(null);
    const [status, setStatus] = useState<ImageStatus>('initial');

    const imageRef = useRef<HTMLImageElement | null>(null);

    const {className} = props;
    const {src} = props;
    const {sourceSets} = props;
    const {alt} = props;
    const {title} = props;
    const {style} = props;
    const {width} = props;
    const {height} = props;
    const {onLoading} = props;
    const {loading} = props;

    const onError = (event?: Event) => {
        // eslint-disable-next-line
        const {onError = (event?) => null} = props;

        setContent(onError(event));
        setStatus('error');
    };

    const onLoad = () => {
        imageRef.current && props.onLoaded?.(imageRef.current);
        setStatus('loaded');
    };

    const onLoadStart = () => setStatus('loading');

    useEffect(() => {
        const image = imageRef.current;

        const listeners = [
            {type: 'error', listener: onError},
            {type: 'load', listener: onLoad},
            {type: 'loadstart', listener: onLoadStart}
        ] as const;

        if (image) {
            listeners.forEach(({type, listener}) => image.addEventListener(type, listener));

            // Handle lost image events with SSR/G
            // https://github.com/facebook/react/issues/15446
            // https://github.com/vercel/next.js/discussions/13379
            // https://stackoverflow.com/questions/59787642/nextjs-images-loaded-from-cache-dont-trigger-the-onload-event
            if (image.complete) {
                if (image.naturalHeight === 0) {
                    onError();
                } else {
                    onLoad();
                }
            } else {
                onLoadStart();
            }
        }

        return () => {
            if (image) {
                listeners.forEach(({type, listener}) => image.removeEventListener(type, listener));
            }
        };
    }, [src]);

    const imageComponent: React.ReactNode = content || (
        <>
            {status === 'loading' && onLoading ? onLoading() : null}
            <img
                ref={imageRef}
                {...{alt, className, title, src, width, height, loading}}
                style={{
                    ...style,
                    // Hide the image if loading as a slow network may partially display the image as downloading
                    visibility: status === 'loading' ? 'hidden' : 'visible'
                }}
                aria-hidden={props['aria-hidden']}
            />
        </>
    );

    return (
        <>
            {sourceSets && sourceSets.length > 0 ? (
                <picture>
                    {sourceSets.map(({src, type}) => (
                        <source key={src} srcSet={src} type={type} />
                    ))}
                    {imageComponent}
                </picture>
            ) : (
                imageComponent
            )}
        </>
    );
};

export default Image;
