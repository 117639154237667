import styled from 'styled-components';

export type MediaTypeIconProps = {
    className?: string;
    onClick?: any; //
    color?: string;
    size?: string;
};

export default styled.svg.attrs<MediaTypeIconProps>(
    ({onClick, color, theme, size = '2rem', className}) => ({
        fill: color ? theme.colors[color] || color : 'currentColor',
        style: {width: size, height: size, display: 'block'},
        viewBox: '0 0 30 30',
        className,
        onClick
    })
)<MediaTypeIconProps>``;
