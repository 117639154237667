import {useAnalytics} from 'bigdatr-style';

//
// @intent
// Used to track interactions that cant be determined via page tracking
// must use the schema:

export default function useEventTracking() {
    const analytics = useAnalytics();
    return {
        // Landing Page Quiz
        quizAnswerSubmission: (payload: {id: string; answer: string; position: number}) => {
            analytics.trackEvent('landing_page_quiz_submission', {
                // This is GA specific
                // event_category: '', // TODO implement event category
                // Prefix with quiz to easily identify
                quiz_id: payload.id,
                quiz_answer: payload.answer,
                quiz_answer_position: payload.position
            });
        },
        // Any links that lead to registration
        // identifier is a unique name to identify the page + position of the cta
        registerCtaClick: (identifier: string) =>
            analytics.trackEvent('registerCTA:click', {identifier})
    };
}
