import React from 'react';
import {Skeleton, Text, EntityAvatarBrand, Clickable, Link, ToggleItem, Brand} from 'bigdatr-style';
import {Box, Flex, AspectRatio} from 'bigdatr-style/layout';
import {format} from 'stringdate';
import CallToActionFadeOut from '../affordance/CallToActionFadeOut';
import CallToActionNotifications from '../affordance/CallToActionNotifications';
import usePageData from '../core/usePageData';
import DeadEndBrandNotFound from '../affordance/DeadEndBrandNotFound';
import BrandSeo from './BrandSeo';
import BrandCreativeList from './BrandCreativeList';
import BrandDiscoverBrandList from './BrandDiscoverBrandList';
import {useRegisterLinkProps} from '../auth/RegisterButton';
import {ClientWebBrand} from '../ClientWebBrand';
import ClientWebCreative from '../ClientWebCreative';

export type BrandTemplateProps = {
    match: {url: string; params: {year: string}};
};

export type BrandMessage = {
    clientWebData: {
        brandYearCreatives: {
            id: string;
            brand: ClientWebBrand;
            discoverBrands: Array<ClientWebBrand>;
            heroCreative?: ClientWebCreative;
            availableYears: string[];
            months: Array<{
                month: string;
                creatives: Array<ClientWebCreative>;
            }>;
        };
    };
};

export default function BrandYearTemplate(props: BrandTemplateProps) {
    const {url, params} = props.match;
    const message = usePageData<BrandMessage>({url});
    const registerProps = useRegisterLinkProps();

    if (message.isEmpty) return null;
    if (message.isFetching || message.isRefetching) return <SkeletonLayout />;
    if (message.isError) {
        if (message.requestError?.statusCode === 404) {
            return <DeadEndBrandNotFound />;
        }
        throw message.requestError;
    }

    const data = message.response.clientWebData.brandYearCreatives;
    const {key: brandKey, name: brandName} = data.brand;
    const {year} = params;
    const description = `Here are some highlights of ${brandName} advertisements from the year ${year} which ran across digital, video and social media. To view the entire library of ${brandName} ads ${year}, plus historical years, you can sign up for a free trial and get access to the full library and more.`;

    return (
        <Box>
            <Box pt={{_: 4, lg: 5}} pb={4}>
                <BrandSeo
                    title={`Explore New ${data.brand.name} Ads ${params.year}`}
                    brand={data.brand}
                    description={description}
                    year={year}
                />
                <Flex alignItems="center" mb={2}>
                    <Box>
                        <EntityAvatarBrand entity={new Brand(data.brand)} width="2rem" />
                    </Box>
                    <Box ml={3}>
                        <Text as="h1" textStyle={{_: 'heading3', sm: 'heading2', md: 'heading1'}}>
                            {brandName} ads {year}
                        </Text>
                    </Box>
                </Flex>
                <Text>
                    Here are some highlights of {brandName} advertisements from the year {year}{' '}
                    which ran across digital, video and social media.
                    <br />
                    To view the entire library of {brandName} ads {year}, plus historical years, you
                    can sign up for a <Link {...registerProps}>free trial</Link> and get access to
                    the full library and more.
                </Text>
                <Flex alignItems="center" mt={3}>
                    <Clickable pr={2} to={`/au/brand/${brandKey}`}>
                        <ToggleItem active={false} separate>
                            Recent
                        </ToggleItem>
                    </Clickable>
                    {data.availableYears.map((date: string) => {
                        const year = format('yyyy')(date);
                        return (
                            <Clickable pr={2} key={year} to={`/au/brand/${brandKey}/${year}`}>
                                <ToggleItem
                                    separate
                                    active={year === params.year}
                                    children={year}
                                />
                            </Clickable>
                        );
                    })}
                </Flex>
            </Box>
            <Box display={{md: 'flex'}}>
                <Box width={{md: '70%'}}>
                    {data.months.slice(0, 4).map(({month, creatives}, index: number) => {
                        return (
                            <Box key={index} mb={4}>
                                <Box mb={3}>
                                    <Text textStyle="heading2">{format('MMMM')(month)}</Text>
                                </Box>
                                <BrandCreativeList creatives={creatives} limit={3} />
                            </Box>
                        );
                    })}
                    <CallToActionFadeOut
                        fade={data.months.length > 4}
                        identifier={'brandYearPageCreativeListOverlay'}
                    />
                </Box>
                <Box width={{md: '30%'}} pl={{md: 4}} zIndex={1}>
                    <BrandDiscoverBrandList brands={data.discoverBrands || []} />
                    <Box mt={4}>
                        <CallToActionNotifications identifier={'brandYearPageRightBox'} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

function SkeletonLayout() {
    return (
        <Box>
            <Box pt={{_: 4, lg: 5}} pb={4}>
                <Skeleton mb={2} width="40%" height="2.5rem" />
                <Skeleton mb={2} width="60%" height="1rem" />
                <Skeleton width="60%" height="1rem" />
            </Box>
            <Box display={{md: 'flex'}}>
                <Box width={{md: '70%'}}>
                    <Box mb={3}>
                        <AspectRatio ratio={16 / 9}>
                            <Skeleton height="100%" />
                        </AspectRatio>
                    </Box>
                    <Skeleton width="40%" height="1.5rem" mb={2} />
                    <Skeleton width="24%" mb={4} />
                    <Flex flexWrap="wrap" mr="-1rem">
                        {[...new Array(12)].map((_, key: number) => (
                            <Box key={key} width={{_: 1 / 2, lg: 1 / 3}} pr="1rem" mb="1.5rem">
                                <AspectRatio ratio={16 / 9}>
                                    <Skeleton height="100%" />
                                </AspectRatio>
                                <Skeleton mt={2} />
                                <Skeleton my={2} />
                            </Box>
                        ))}
                    </Flex>
                </Box>
                <Box width={{md: '30%'}} pl={{md: 4}} style={{zIndex: 1}}>
                    <Skeleton mb={4} height="30rem" />
                    <Skeleton height="10rem" />
                </Box>
            </Box>
        </Box>
    );
}
