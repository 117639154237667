import React from 'react';
import {useMDXComponents} from '@mdx-js/react';

export default function MdxFromAst(props: {
    type: string;
    tagName?: string;
    value: any;
    children: any;
    openingElement: any;
    properties: any;
}) {
    const components = useMDXComponents();
    if (props.type === 'text') return props.value;
    let Element = components[props.tagName] || props.tagName || 'span';

    const childProps = {
        ...props.properties,
        children: props.value
    };

    // recurse into children
    if (props.children && props.children.length) {
        childProps.children = props.children.map((node, key) => {
            return <MdxFromAst key={key} {...node} />;
        });
    }

    // gather custom jsx props from ast
    if (props.type === 'JSXElement') {
        Element = components[props.openingElement.name.name] || 'div';
        for (const attribute of props.openingElement.attributes) {
            const {name, value} = attribute;
            childProps[name.name] = value.value;
        }
    }

    // Apply props to the chosen element
    return <Element {...childProps} />;
}
