// Main Site
export const BigDatrUrl = process.env.BIGDATR_WEB_HOST_URL;

// V2 Application

export const BigDatrAppRegisterUrl = process.env.BIGDATR_APP_REGISTER_URL;
export const BigDatrAppSignInUrl = process.env.BIGDATR_APP_SIGN_IN_URL;

// External Images
export const BigDatrLogo = 'https://media.bigdatr.com/static/app/bigdatr-logo.svg';
export const BigDatrLogoOG = 'https://media.bigdatr.com/static/app/bigdatr-og.png'; // Used for OG tags

// Social
export const LinkedinUrl = 'http://linkedin.com/company/bigdatr';
export const TwitterUrl = 'https://twitter.com/BigDatr';
export const FacebookUrl = 'https://www.facebook.com/BigDatr/';
export const InstagramUrl = 'http://instagram.com/bigdatr';
export const YoutubeUrl = 'https://www.youtube.com/channel/UCPmxK9eH7f_SiU3mHANvp8A';
