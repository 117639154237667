import React from 'react';
import {Text} from 'bigdatr-style';
import {Box, Flex, Wrapper} from 'bigdatr-style/layout';
import {BigDatrLogoOG, BigDatrUrl} from '../core/urls';
import usePageData from '../core/usePageData';
import {Seo} from '../affordance/SeoTemplate';
import BlogListItem from './BlogListItem';

export type BlogPostItem = {
    author: string;
    date: string;
    description: string;
    heroImage?: string;
    heroImageAlt?: string;
    showHeroImageOnPage?: boolean;
    slug: string;
    title: string;
};

type BlogListMessage = {
    blogList: Array<BlogPostItem>;
};

export default function BlogListTemplate(props: {match: {url: string}}) {
    const {url} = props.match;
    const message = usePageData<BlogListMessage>({url});

    if (message.isEmpty) return null;
    if (message.isError) throw message.requestError;

    const title = 'Insights Blog';
    const description =
        'Explore our collection of advertising trends, news and insights from Bigdatr.';
    return (
        <Wrapper maxWidth="60rem">
            <Seo
                title={title}
                titleTemplate="%s - Bigdatr"
                description={description}
                canonical={`${BigDatrUrl}/blog`}
                openGraph={{
                    type: 'website',
                    url: `${BigDatrUrl}/blog`,
                    title: `${title} - Bigdatr`,
                    description: description,
                    images: [
                        {
                            url: BigDatrLogoOG
                        }
                    ]
                }}
                twitter={{
                    cardType: 'summary_large_image'
                }}
            />
            <Flex pt={5} pb={4} alignItems="center">
                <Box>
                    <Text as="h1" textStyle="heading0">
                        Insights Blog
                    </Text>
                </Box>
            </Flex>
            <Box>
                {message.isSuccess
                    ? message.response.blogList.map((post) => (
                          <BlogListItem key={post.slug} post={post} />
                      ))
                    : [...new Array(6)].map((_, key) => <BlogListItem key={key} skeleton />)}
            </Box>
        </Wrapper>
    );
}
