import React from 'react';
import {BigDatrLogoOG, BigDatrUrl} from '../core/urls';
import {Seo} from '../affordance/SeoTemplate';
import {ClientWebBrand} from '../ClientWebBrand';

const BASE_URL = `${BigDatrUrl}/au/brand`;

type BrandSeoProps = {
    brand: ClientWebBrand;
    imageUrl?: string;
    title?: string;
    description?: string;
    year?: string;
};
const BrandSeo = ({brand, imageUrl, title, description, year}: BrandSeoProps) => {
    title ||= `Explore New ${brand.name} Ads`;
    description ||= `Discover a wide range of ${brand.name} ads across digital, video, and social media. You can set up personalised ad alerts when ${brand.name} or their competitors launch a new campaign to market.`;
    const url = `${BASE_URL}/${brand.key}${year ? `/${year}` : ''}`;

    return (
        <Seo
            title={title}
            description={description}
            canonical={url}
            openGraph={{
                title,
                description,
                type: 'website',
                url,
                images: [{url: imageUrl ?? BigDatrLogoOG}]
            }}
            twitter={{
                cardType: 'summary_large_image'
            }}
        />
    );
};

export default BrandSeo;
