
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import MdxLayout from '../affordance/MdxLayout';
import SeoTemplate from '../affordance/SeoTemplate';
import {Box, FlexCenter} from 'bigdatr-style/layout';
import {Loader, Text, Link} from 'bigdatr-style';
import HubspotForm from 'react-hubspot-form';
import HubspotFormCss from '../core/hubspotFormsCss';
export const frontmatter = {
  "title": "Contact Us",
  "description": "Contact us today to learn more about BigDatr. Our friendly team will show you what’s possible with our suite of products, designed to drive your success.",
  "slug": "contact-us",
  "date": "2020-12-08"
};

const layoutProps = {
  frontmatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <MdxLayout mdxType="MdxLayout">
      <HubspotFormCss mdxType="HubspotFormCss" />
      <SeoTemplate title={frontmatter.title} description={frontmatter.description} slug={frontmatter.slug} mdxType="SeoTemplate" />
      <Box display={{
        md: 'flex'
      }} mdxType="Box">
        <Box flexGrow="1" flexBasis="50%" pr={{
          md: 3
        }} pt={{
          md: 5
        }} mb={4} mdxType="Box">
          <h1>{`Let's Chat`}</h1>
          <h4>{`Contact us today and learn how Bigdatr can give your business a competitive edge in your industry.`}</h4>
          <p>{`Fill out the form and we’ll be in touch soon. Our friendly team will show you what’s possible with our suite of products, designed to drive your success.`}</p>
          <Text textStyle="disclaimer" mdxType="Text">By clicking "Send" you agree to our <Link target="blank" href="https://bigdatr.com/terms-of-use" children="Terms of Use" mdxType="Link" />, <Link target="blank" href="https://bigdatr.com/privacy-policy" children="Privacy Policy" mdxType="Link" />, <Link target="blank" href="https://bigdatr.com/licenseagreement" children="Licencing Agreement" mdxType="Link" />, and recognise our team will be in contact with you regarding your account, enquiries or other purposes.</Text>
        </Box>
        <Box flexGrow="1" flexBasis="50%" pl={{
          md: 3
        }} mdxType="Box">
          <HubspotForm portalId="6080063" formId="4d911424-f31e-4ce5-b8fe-9579b33d71bf" loading={<FlexCenter mdxType="FlexCenter"><Loader mdxType="Loader" /></FlexCenter>} mdxType="HubspotForm" />
        </Box>
      </Box>
    </MdxLayout>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;