import {ClassSchema} from 'bigdatr-style';
import {ArraySchema, ObjectSchema} from 'react-enty';
import ClientWebCreative from '../ClientWebCreative';

const creative = new ClassSchema(ClientWebCreative, {});
const creatives = new ArraySchema(creative);

export default new ObjectSchema({
    clientWebData: new ObjectSchema({
        brandCreatives: new ObjectSchema({
            heroCreative: creative,
            thumbCreatives: creatives
        }),
        brandYearCreatives: new ObjectSchema({
            months: new ArraySchema(new ObjectSchema({creatives}))
        }),
        collectionCreatives: new ObjectSchema({
            creatives
        }),
        creativeHome: creative,
        creativeItem: creative
    })
});
