
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import MdxLayout from '../affordance/MdxLayout';
import SeoTemplate from '../affordance/SeoTemplate';
import {DeadEnd} from 'bigdatr-style';
export const frontmatter = {};

const layoutProps = {
  frontmatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <MdxLayout mdxType="MdxLayout">
      <SeoTemplate title="Thank You" description="Thank you for submitting your response" slug="thank-you" mdxType="SeoTemplate" />
      <DeadEnd title="Success!" mdxType="DeadEnd">Thank you for submitting your response</DeadEnd>
    </MdxLayout>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;