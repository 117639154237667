import {AnalyticsIdentifyProps, ThirdParty} from './Analytics';

// eslint-disable-next-line @typescript-eslint/no-unused-vars

type Options = {
    pageViewOnLoad?: boolean;
};

export default class Google implements ThirdParty {
    tag: Function;
    constructor(gaMeasurementId: string, options: Options = {}) {
        if (typeof window === 'undefined') return;
        window.dataLayer = window.dataLayer || [];
        this.tag = function (...args) {
            window.dataLayer.push(args);
        };
        this.tag('js', new Date());
        this.tag('config', gaMeasurementId, {
            send_page_view: options.pageViewOnLoad ?? true
        });
    }

    identify(user: Pick<AnalyticsIdentifyProps, 'experimentId'>) {
        const payload = {
            bd_experiment_id: user.experimentId
        };

        // As google does it's own identifcation
        // Only send user_properties if there is data to send
        if (Object.values(payload).filter((ii) => ii != null).length > 0) {
            this.tag('set', 'user_properties', payload);
        }
    }

    trackEvent(name: string, props: Record<string, any>, trackingOptions: Record<string, boolean>) {
        this.tag('event', name, {
            ...props,
            ...trackingOptions
        });
    }

    trackError() {}
    page() {}
    chat() {}
}
