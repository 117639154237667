import React from 'react';
import {Box, StyledProps} from 'bigdatr-style/layout';

type ResponsiveLayoutProps = {
    before: React.ReactNode;
    after: React.ReactNode;
    breakpoint: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'; // hardcoded untill moved into bigdatr-style
    width?: StyledProps['width'];
};

const ResponsiveLayout = ({before, after, breakpoint, width}: ResponsiveLayoutProps) => {
    return (
        <>
            <Box display={{_: 'block', [breakpoint]: 'none'}} width={width}>
                {before}
            </Box>
            <Box display={{_: 'none', [breakpoint]: 'block'}} width={width}>
                {after}
            </Box>
        </>
    );
};

export default ResponsiveLayout;
