import React from 'react';
import Tooltip from './Tooltip';
import {Box} from '../../layout';
import {IconQuestionCircle} from '../../icon';
import styled from 'styled-components';

const Sup = styled.sup`
    align-self: flex-start;
`;

type Props = Omit<React.ComponentProps<typeof Tooltip>, 'children'> & {
    superscript?: boolean;
    floaterMaxWidth?: string;
    /** Adds an `aria-label` prop to the Icon wrapper, and will prepend `What is ` to this prop.
     * For example, if you pass in  accessibleLabel = 'comparison period', the rendered aria-label
     * will be `What is comparison period?`
     */
    accessibleLabel?: string;
    testid?: string;
};
export default function HelpTooltip(props: Props) {
    const {superscript, floaterMaxWidth = '14rem', accessibleLabel} = props;
    const Wrapper = superscript ? Sup : React.Fragment;
    let label: string | undefined = undefined;
    if (accessibleLabel) label = `What is ${accessibleLabel}?`;
    return (
        <Wrapper>
            <Tooltip
                {...props}
                tooltipDisplay="inline-flex"
                maxWidth={floaterMaxWidth}
                children={
                    <Box display="inline-block" aria-label={label} data-testid={props.testid}>
                        <IconQuestionCircle size={superscript ? '.75em' : ''} aria-hidden="true" />
                    </Box>
                }
            />
        </Wrapper>
    );
}
