import React from 'react';
import {Flex} from 'bigdatr-style/layout';
import ResponsiveLayout from '../affordance/ResponsiveLayout';
import BrandCreativeListItem from './BrandCreativeListItem';
import ClientWebCreative from '../ClientWebCreative';

const ListItemSkeletons = (props: {limit: number}) => (
    <Flex flexWrap="wrap" mr="-1rem">
        {[...new Array(props.limit)].map((_, key) => (
            <BrandCreativeListItem key={key} skeleton />
        ))}
    </Flex>
);

const ListItems = (props: {creatives: Array<ClientWebCreative>; limit?: number}) => (
    <Flex flexWrap="wrap" mr="-1rem">
        {props.creatives.slice(0, props.limit).map((creative) => (
            <BrandCreativeListItem key={creative.id} creative={creative} />
        ))}
    </Flex>
);

type Props =
    | {creatives: Array<ClientWebCreative>; skeleton?: false; limit?: number}
    | {skeleton: true; limit?: number};
const BrandCreativeList = (props: Props) => {
    const mobileLimit = 4;

    if ('skeleton' in props && props.skeleton) {
        return (
            <ResponsiveLayout
                breakpoint="md"
                before={<ListItemSkeletons limit={mobileLimit} />}
                after={<ListItemSkeletons limit={props.limit || 12} />}
            />
        );
    }

    return (
        <ResponsiveLayout
            breakpoint="md"
            before={<ListItems creatives={props.creatives} limit={mobileLimit} />}
            after={<ListItems creatives={props.creatives} limit={props.limit} />}
        />
    );
};

export default BrandCreativeList;
