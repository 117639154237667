import React, {MouseEventHandler, MutableRefObject} from 'react';
import {Link, LinkProps, NavLink} from 'react-router-dom';
import Styled from 'styled-components';
import {space, SpaceProps} from 'styled-system';
import {layout, LayoutProps} from 'styled-system';
import usePreload, {PreloadConfig} from '../util/usePreload';
import {bool} from '../core/themes/themeGetters';

export type ClickableProps = LayoutProps &
    SpaceProps & {
        to?: LinkProps<any>['to'];
        href?: string;
        onClick?: MouseEventHandler;
        children?: any;
        rel?: string;
        className?: string;
        disabled?: boolean;
        navLink?: boolean;
        tabIndex?: number;
        target?: string;
        preload?: PreloadConfig;
        'data-testid'?: string;
        'aria-label'?: string;
        width?: string;
        height?: string;
        exact?: boolean;
        style?: Record<string, any>;

        hasHover?: boolean;
        hasFocus?: boolean;
        active?: boolean;
    };

type AnchorRef = MutableRefObject<HTMLAnchorElement>;
type ButtonRef = MutableRefObject<HTMLButtonElement>;

export default Styled(
    React.forwardRef((props: ClickableProps, ref) => {
        const {children, className, onClick, navLink, target, rel, width, height, style} = props;
        let {href, to} = props;
        const onMouseOver = usePreload(props.preload);
        const onMouseOut = onMouseOver;
        const childProps = {
            children,
            className,
            onClick,
            onMouseOver,
            onMouseOut,
            rel,
            width,
            height,
            tabIndex: props.tabIndex,
            'data-testid': props['data-testid'],
            style,
            'aria-label': props['aria-label'],
            'aria-checked': props['aria-checked']
        };

        if (props.disabled) {
            href = undefined;
            to = undefined;
        }

        if (to) {
            if (navLink) {
                return (
                    <span ref={ref as AnchorRef}>
                        <NavLink to={to} exact={props.exact} {...childProps} />
                    </span>
                );
            }
            return <Link to={to} {...childProps} ref={ref as AnchorRef} />;
        }
        if (props.href) {
            return (
                <a
                    href={href}
                    {...childProps}
                    ref={ref as AnchorRef}
                    target={target}
                    rel={target?.includes('blank') ? 'noopener noreferrer' : childProps.rel}
                />
            );
        }

        const {width: buttonWidth, height: buttonHeight, ...buttonProps} = childProps;
        return <button {...buttonProps} disabled={props.disabled} ref={ref as ButtonRef} />;
    })
)`
    ${space}
    ${layout}
    cursor: pointer;
    color: inherit;
    background-color: ${(p) => (p.active ? p.theme.colors.active : 'inherit')};

    &:disabled {
        cursor: not-allowed;
    }

    &:hover {
        ${bool('hasHover')((p) => `background-color: ${p.theme.colors.background1};`)}
    }

    &:active,
    &:focus {
        ${bool('hasFocus')(
            (p) => `
            outline: none;
            background-color: ${p.theme.colors.active};
            `
        )}
    }

    &:focus {
        outline: none;
    }

    &:focus-visible {
        text-decoration: underline;
    }
`;
