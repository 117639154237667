
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import MdxLayout from '../affordance/MdxLayout';
import SeoTemplate from '../affordance/SeoTemplate';
export const frontmatter = {
  "title": "Privacy Policy",
  "description": null
};

const layoutProps = {
  frontmatter
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <MdxLayout mdxType="MdxLayout">
      <SeoTemplate title={frontmatter.title} description={frontmatter.description} slug={frontmatter.slug} mdxType="SeoTemplate" />
      <h1>{`Privacy Policy`}</h1>
      <p><strong parentName="p">{`1 We are committed to protecting Your personal information`}</strong></p>
      <p>{`Big Datr Pty Ltd (ACN 165 443 470) and its related bodies corporate (collectively, "Big Datr", "Us", "Our" or "We") are committed to protecting your ("You", "Your") privacy. This Privacy Policy applies to registered users of the BigDatr platform ("Platform") and unregistered visitors to Our website at `}<a parentName="p" {...{
          "href": "http://www.bigdatr.com"
        }}>{`www.bigdatr.com`}</a>{` ("Website"), sets out Our commitment in respect of any personal information We collect or hold about You, and what We do with that information.`}</p>
      <p>{`Any personal information that We collect or hold about You will only be used for the purposes for which We have collected it, or as allowed by law. We will abide by the Australian Privacy Principles for the protection of personal information, as set out in the Privacy Act 1988 (Cth) (“Privacy Act”) and any other relevant law, in respect of Your personal information.`}</p>
      <p><strong parentName="p">{`2 What is personal information and why do We collect it?`}</strong></p>
      <p>{`When We refer to “personal information” We mean information from which Your identity is reasonably apparent. This information may include information or an opinion about You. We will only collect personal information which is reasonably necessary for, or directly related to, Our functions and activities. We may use Your personal information for direct marketing where We have Your express or inferred consent to do so.`}</p>
      <p>{`The kinds of personal information We may collect about You include Your name, Your address, cookies, Your IP address, and any other information We made need to contact You. We may also hold sensitive information or credit information.`}</p>
      <p>{`We collect personal information for the purposes of conducting business with You, providing any services You have requested and managing Our relationship with You. This can occur when You visit Our Website, when You post a query through the portal accessible through Our Website, or otherwise when You contact Us or sign up to receive services from Us. Our server may also log details about any computer used to access the Platform, including the IP address, operating system and browser type, the date and time of access and details of any information downloaded.`}</p>
      <p><strong parentName="p">{`2.1 Credit information`}</strong></p>
      <p>{`In the course of Our business with You, We may collect and hold credit information. Credit information is information which is usually exchanged between credit and finance providers, credit reporting bodies and other entities that are involved in managing credit. The type of credit information that We hold may include details of Your liabilities in respect of consumer credit, default information, and information about court proceedings. If We collect or hold credit information We do so to assist in the management of any credit We extend.`}</p>
      <p>{`You may access any credit information that We hold about You, seek correction of this information, or enquire about how We have used Your credit information by using Our contact details below. We will acknowledge Your enquiry within 7 days, and will provide You with an outcome to your query within 30 days.`}</p>
      <p><strong parentName="p">{`2.2 Sensitive information`}</strong></p>
      <p>{`Sensitive information refers to any information about a person’s racial or ethnic origin, political opinion, membership of a political association, religious beliefs or affiliations, philosophical beliefs, membership of a professional, or trade association, membership of a trade union, sexual preferences or practices, criminal record or health information. `}</p>
      <p>{`We will not ask You to disclose sensitive information unless it is necessary for Us to conduct Our business with You. However, if You elect to provide Us with unsolicited sensitive information, it may be stored.`}</p>
      <p><strong parentName="p">{`2.3 Cookies`}</strong></p>
      <p>{`We use cookies when You visit Our Website for the purpose of providing You with a better and more customised service.`}</p>
      <p>{`A cookie is a small piece of data placed on Your web browser by Our Website. A cookie can be retrieved when You next visit Our Website to remember Your previous activity. Cookies are frequently used on websites. You can choose if and how a cookie will be accepted by configuring Your preferences and options in Your web browser.`}</p>
      <p>{`We use cookies for different purposes such as: `}</p>
      <p>{`(a) allocating a unique number to Your internet browser;`}</p>
      <p>{`(b) to customise Our Website for You;`}</p>
      <p>{`(c) for statistical purposes;`}</p>
      <p>{`(d) for security purposes; and/or`}</p>
      <p>{`(e) to authenticate or identify whether You are registered on Our Website, without requiring You to re-enter details each time You log on to Our Website.`}</p>
      <p>{`If at any time You do not wish to accept cookies or wish to delete existing cookies, You may manually configure Your browser to refuse cookies or may manually delete existing cookies from Your hard drive. However, by deleting or refusing to accept cookies You may obstruct or hinder Your ability to experience the full functionality of Our Website.`}</p>
      <p><strong parentName="p">{`2.4 IP addresses`}</strong></p>
      <p>{`Your IP address is the identifier for Your web browsing device when You are using the internet. It may be necessary for Us to collect Your IP address for Your interaction with parts of Our Website.`}</p>
      <p><strong parentName="p">{`3 Do We disclose Your personal information?`}</strong></p>
      <p>{`We may disclose Your personal information the following parties:`}</p>
      <p>{`(a) external service providers and/or contractors, so they can provide the service We have contracted out to them;`}</p>
      <p>{`(b) companies that provide information and infrastructure systems to Us;`}</p>
      <p>{`(c) anybody who represents You;`}</p>
      <p>{`(d) anyone, where You have provided Us consent;`}</p>
      <p>{`(e) investors, agents, advisers, or any entity that has an interest in Our business;`}</p>
      <p>{`(f) third parties who have instructed Us to provide services; or`}</p>
      <p>{`(g) organisations that provide products or services used or marketed by Us. `}</p>
      <p>{`Prior to disclosing any of Your personal information to another person or organisation, We will take reasonable steps to ensure that the person or organisation has a commitment to protecting Your personal information at least equal to Our commitment, or otherwise that You have consented to Us making the disclosure. `}</p>
      <p><strong parentName="p">{`4 Storage of personal information `}</strong></p>
      <p>{`We store personal information in a combination of secure computer facilities, cloud storage and paper-based files and other records. The cloud storage and the IT servers may be located outside Australia.`}</p>
      <p><strong parentName="p">{`4 Access to and correction of Your personal information`}</strong></p>
      <p>{`We will provide You with access to the personal information We hold about You. You may request access to any of the personal information that We hold about You at any time. Depending on the type of request that You make, We may respond to Your request immediately. Otherwise, We usually respond to You within 7 days of receiving Your request. We may need to contact other entities to properly investigate Your request.`}</p>
      <p>{`We will generally rely on You to ensure the information We hold about You is accurate or complete. If any of the personal information We hold about You is incorrect, inaccurate or out of date, You may request that We correct the information. If appropriate, We will correct the personal information at the time of the request. Otherwise, We will provide an initial response to You within 7 days of receiving Your request. Where reasonable, and after any investigation we deem necessary to undertake, We will provide You with details about whether We have corrected the personal information within 30 days. We may need to consult with other entities as part of Our investigation.`}</p>
      <p>{`If We refuse to correct Your personal information, We will provide You with Our reasons for not correcting the information.`}</p>
      <p><strong parentName="p">{`5 How safe is Your personal information with Us?`}</strong></p>
      <p>{`We will take reasonable steps to protect Your personal information by storing it in a secure environment. We may store Your personal information in paper and/or electronic form. We will also take reasonable steps to protect any personal information from misuse, loss and unauthorised access, modification or disclosure.`}</p>
      <p><strong parentName="p">{`6 What if you do not like how We handle Your personal information?`}</strong></p>
      <p>{`If You are not satisfied with how We have handled Your personal information, or You have a complaint about Our compliance with the Privacy Act, please contact Us using the details below. We will acknowledge Your complaint within 7 days, and provide You with a decision regarding Your complaint within 30 days. If You are dissatisfied with the response of Our Privacy Officer, You may make a complaint to the Privacy Commissioner which can be contacted via the Office of the Australian Information Commissioner website `}<a parentName="p" {...{
          "href": "http://www.oaic.gov.au"
        }}>{`www.oaic.gov.au`}</a>{` or on 1300 363 992.`}</p>
      <p><strong parentName="p">{`7 Changes to Our privacy policy`}</strong></p>
      <p>{`We constantly review Our policies to keep up to date with the law and market expectations. Consequently, We may change this Privacy Policy from time to time. We are not required to notify You of any such change, but We encourage You to revisit and review Our Privacy Policy from time to time.`}</p>
      <p><strong parentName="p">{`8 Further Information `}</strong></p>
      <p>{`You may request further information about the way We manage Your personal information by contacting Us on the details below.`}</p>
      <p><strong parentName="p">{`9 Our Contact Details`}</strong></p>
      <p>{`Should you wish to contact Us in relation to any aspect of Our Privacy Policy, please contact Us via the `}<a parentName="p" {...{
          "href": "https://bigdatr.com/contact-us"
        }}>{`Contact Us`}</a>{` page on Our Website, or write to Us at Big Datr Pty Ltd | Level 1 / 254 Dorcas St, South Melbourne VIC 3205`}</p>
      <p>{`ABN: 89 165 443 470 | Phone: (03) 9427 1230`}</p>
    </MdxLayout>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;